import React from "react";
import PageIndex from "../../../PageIndex";
import Index from "../../../../component/Index";
import {
  deleteNutrationPlan,
  getNutritionTabPlans,
} from "../../../../redux/features/slices/Admin/AdminService";

const NutritionTabPlan = ({ trainerId }) => {
  const [currentRows, setCurrentRows] = PageIndex.useState([]);
  const [searchedData, setSearchedData] = PageIndex.useState([]);
  const [nutritionPlansData, setNutritionPlansData] = PageIndex.useState([]);
  const [apiData, setApiData] = PageIndex.useState(false);
  const [singleNutritionPlanData, setSingleNutritionPlanData] = PageIndex.useState({});
  const [anchorEl, setAnchorEl] = PageIndex.useState(null);
  const [deleteOpen, setDeleteOpen] = PageIndex.useState(false);
  const open = Boolean(anchorEl);
  const dispatch = PageIndex.useDispatch();
  const navigate = PageIndex.useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getNutritionPlanList = async () => {
    const urlEncodedData = new URLSearchParams();
    urlEncodedData.append("id", trainerId);
    dispatch(getNutritionTabPlans(urlEncodedData)).then((res) => {
      const { status, data } = res?.payload || {};
      if (status === 200) {
        const dataWithCount = data?.map((item, index) => ({
          ...item,
          count: index + 1,
          key: index,
        }));

        setNutritionPlansData(dataWithCount);
        setSearchedData(dataWithCount);
        setCurrentRows(dataWithCount);
        setApiData(true);
      } else {
        setApiData(true);
      }
    });
  };

  PageIndex.useEffect(() => {
    getNutritionPlanList();
  }, []);

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleDeleteNutrationPlan = () => {
    dispatch(deleteNutrationPlan(singleNutritionPlanData._id)).then((res) => {
      const { status, data } = res?.payload || {};
      if (status === 200) {
        getNutritionPlanList();
        handleDeleteClose();
      }
    });
  };

  const requestSearch = (searched) => {
    if (!searched)
      return setSearchedData(
        nutritionPlansData?.map((item, index) => {
          return {
            ...item,
            count: index + 1,
            key: index,
          };
        })
      );

    let filterData = nutritionPlansData?.filter(
      (item) =>
        item?.userName?.toLowerCase().includes(searched.toLowerCase()) ||
        item?.fullName
          ?.toString()
          .toLowerCase()
          .includes(searched.toLowerCase()) ||
        item?.nutritionPlanName
          .toString()
          .toLowerCase()
          .includes(searched.toLowerCase()) ||
        item?.price
          .toString()
          .toLowerCase()
          .includes(searched.toLowerCase()) ||
        item?.amount
          .toString()
          .toLowerCase()
          .includes(searched.toLowerCase())
    );

    const datawithCount = filterData?.map((item, index) => {
      return {
        ...item,
        count: index + 1,
        key: index,
      };
    });
    setSearchedData(datawithCount);
  };

  if (!apiData) {
    return <Index.Loader />;
  }

  return (
    <>
      <PageIndex.DynamicTitle title="Fitrizer - Nutrition plans" />

      <Index.Box className="dashboard-content add-user-containt">
        <Index.Box className="add-user-data-main goal-setup-css">
          <Index.Box sx={{ width: "100%" }} className="add-user-height">
            <Index.Box className="dashboard-content">
              <Index.Box className="user-list-flex">
                <Index.Typography
                  className="admin-page-title user-list-page-title"
                  component="h2"
                  variant="h2"
                >
                  {/* {headingTitle} */}
                </Index.Typography>
                <Index.Box className="challanges-heading-right-side-data">
                  <Index.Box className="userlist-btn-flex">
                    <Index.Box className="user-search-main">
                      <Index.Box className="user-search-box">
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            onChange={(e) =>
                              requestSearch(e.target.value.trim())
                            }
                            placeholder="Search"
                          />
                          <img
                            src={Index.Svg.greysearch}
                            className="search-grey-img"
                            alt="search grey img"
                          ></img>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>

                {/* Table Content */}
              </Index.Box>
            </Index.Box>
            <Index.Box className="admin-dashboard-list-row admin-setup-goal-row">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dash-box">
                      <Index.Box className="contactrequest-table-main page-table-main">
                        <Index.TableContainer
                          component={Index.Paper}
                          className="table-container table-head-sticky"
                          sx={{ maxHeight: 405 }}
                        >
                          <Index.Table
                            aria-label="simple table"
                            className="table-user-maindata"
                          >
                            <Index.TableHead className="table-head">
                              <Index.TableRow className="table-row">
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  No.
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Username
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Name
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Plan Name
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Created Date
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Price
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Amount
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Action
                                </Index.TableCell>

                              </Index.TableRow>
                            </Index.TableHead>
                            <Index.TableBody className="table-body">
                              {currentRows?.length && apiData ? (
                                currentRows?.map((row, index) => {
                                  { console.log(row, 'row') }
                                  const {
                                    count,
                                    userName,
                                    fullName,
                                    nutritionPlanName,
                                    createdAt,
                                    price,
                                    amount,
                                    _id,
                                  } = row;
                                  return (
                                    <Index.TableRow key={_id}>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                      >
                                        <Index.Box className="userlist-data anchor-link">
                                          {count}
                                        </Index.Box>
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {row?.trainerId?.userName ? row?.trainerId?.userName : "-"}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                        align="left"
                                      >
                                        {row?.trainerId?.fullName ? row?.trainerId?.fullName : "-"}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td token-tab"
                                      >
                                        {nutritionPlanName ? nutritionPlanName : "-"}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td token-tab"
                                      >
                                        {row?.createdAt
                                          ? PageIndex.moment(
                                            row?.createdAt
                                          ).format("MM/DD/YYYY")
                                          : "-"}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td token-tab"
                                      >
                                        {price ? price : "-"}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td token-tab"
                                      >
                                        {amount ? amount : "-"}
                                      </Index.TableCell>

                                      <Index.TableCell component="td" variant="td" className="table-td">
                                        <Index.Box className="action-btn-icon" sx={{ display: "flex" }}>
                                          <Index.Tooltip title="View details">
                                            <Index.Button
                                              className="admin-menu-btn"
                                              variant="contained"
                                              onClick={() => {
                                                navigate(
                                                  `/admin/receipe-plans/${row?._id}`, {state: row}
                                                );
                                              }}
                                            >
                                              <Index.Visibility />
                                            </Index.Button>
                                          </Index.Tooltip>
                                        </Index.Box>
                                      </Index.TableCell>
                                    </Index.TableRow>
                                  );
                                })
                              ) : (
                                <Index.TableRow>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="no-data-in-list"
                                    colSpan={10}
                                    align="center"
                                  >
                                    No Data Found
                                  </Index.TableCell>
                                </Index.TableRow>
                              )}
                            </Index.TableBody>
                          </Index.Table>
                        </Index.TableContainer>
                        {/* <Index.Box className="admin-menu-box">
                          <Index.Menu
                            className="admin-menu-main"
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            <Index.MenuItem
                              key={`edit_`}
                              onClick={handleClose}
                              className="menu-option-li"
                            >
                              <Index.Button
                                variant="contained"
                                onClick={() => {
                                  navigate(
                                    `/admin/edit-receipe-plans/${singleNutritionPlanData._id}`, {
                                    state: singleNutritionPlanData
                                  }
                                  );
                                }}
                              >
                                Edit
                              </Index.Button>
                            </Index.MenuItem>

                            <Index.MenuItem
                              key={`delete_`}
                              onClick={handleClose}
                              className="menu-option-li"
                            >
                              <Index.Button
                                variant="contained"
                                onClick={handleDeleteOpen}
                              >
                                Delete
                              </Index.Button>
                            </Index.MenuItem>
                          </Index.Menu>
                        </Index.Box> */}
                      </Index.Box>
                      <Index.Pagination
                        setCurrentRows={setCurrentRows}
                        allData={searchedData}
                        pageData={currentRows}
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Box>
        <PageIndex.DeleteModals
          modalOpen={deleteOpen}
          onOpenModal={handleDeleteNutrationPlan}
          onCloseModal={handleDeleteClose}
          title="Are you sure you want to delete this Nutrition plan?"
        />
      </Index.Box>

    </>
  );
};

export default NutritionTabPlan;
