import React from "react";
import Index from "../../../../../../component/Index";
import PageIndex from "../../../../../PageIndex";

const OrderDetails = ({ orderDetails }) => {
  console.log(orderDetails, "Order Details");

  const [currentRows, setCurrentRows] = PageIndex.useState([]);
  const [apiData, setApiData] = PageIndex.useState(false);
  const navigate = PageIndex.useNavigate();

  PageIndex.useEffect(() => {
    if (orderDetails?.length) {
      const timeOut = setTimeout(() => {
        setApiData(true);
      }, 500);
      return () => clearTimeout(timeOut);
    } else {
      const timeOut = setTimeout(() => {
        setApiData(true);
      }, 500);
      return () => clearTimeout(timeOut);
    }
  }, [orderDetails]);

  if (!apiData) {
    return <Index.Loader />;
  }

  return (
    <>
      <PageIndex.DynamicTitle title="Fitrizer - Order List" />
      <Index.Box className="dashboard-content add-user-containt">
        {/* <Index.Box className="add-user-data-main goal-setup-css list-cont"> */}
        <Index.Box className="goal-setup-css list-cont">
          <Index.Box sx={{ width: "100%" }} className="add-user-height">
            <Index.Box className="dashboard-content">
              <Index.Box className="admin-dashboard-list-row admin-setup-goal-row">
                <Index.Box sx={{ width: 1 }} className="grid-main">
                  <Index.Box
                    display="grid"
                    className="display-row"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-dash-box">
                        <Index.Box className="trainer-table-main page-table-main">
                          <Index.TableContainer
                            component={Index.Paper}
                            className="table-container table-head-sticky"
                            sx={{ maxHeight: 405 }}
                          >
                            <Index.Table
                              aria-label="simple table"
                              className="table-user-maindata"
                            >
                              <Index.TableHead className="table-head">
                                <Index.TableRow className="table-row">
                                  <Index.TableCell
                                    component="th"
                                    variant="th"
                                    className="table-th"
                                    align="center"
                                  >
                                    No.
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="th"
                                    variant="th"
                                    className="table-th"
                                  >
                                    Customer Name
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="th"
                                    variant="th"
                                    className="table-th"
                                  >
                                    Phone Number
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="th"
                                    variant="th"
                                    className="table-th"
                                    align="center"
                                  >
                                    Date
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="th"
                                    variant="th"
                                    className="table-th"
                                    align="center"
                                  >
                                    Total Products
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="th"
                                    variant="th"
                                    className="table-th"
                                    align="center"
                                  >
                                    Total Amount
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="th"
                                    variant="th"
                                    className="table-th"
                                    align="center"
                                  >
                                    Action
                                  </Index.TableCell>
                                </Index.TableRow>
                              </Index.TableHead>

                              <Index.TableBody className="table-body">
                                {currentRows?.length ? (
                                  currentRows?.map((row, index) => {
                                    const {
                                      count,
                                      userId,
                                      products,
                                      address,
                                      date,
                                      totalAmount,
                                      _id,
                                    } = row;
                                    return (
                                      <Index.TableRow key={_id}>
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          scope="row"
                                          className="table-td"
                                          align="center"
                                        >
                                          <Index.Box className="userlist-data anchor-link">
                                            {index + 1}
                                          </Index.Box>
                                        </Index.TableCell>

                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          className="table-td"
                                        >
                                          {userId.fullName
                                            ? userId.fullName.slice(0, 16)
                                                .length > 15
                                              ? userId.fullName.slice(0, 16) +
                                                "..."
                                              : userId.fullName.slice(0, 16)
                                            : "-"}
                                        </Index.TableCell>
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          className="table-td"
                                          align="left"
                                        >
                                          {address?.contactNumber
                                            ? address?.contactNumber
                                            : "-"}
                                        </Index.TableCell>

                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          className="table-td"
                                          align="center"
                                        >
                                          {date
                                            ? PageIndex.moment(date).format(
                                                "MM/DD/YYYY"
                                              )
                                            : "-"}
                                        </Index.TableCell>

                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          className="table-td token-tab"
                                          align="center"
                                        >
                                          {products.length
                                            ? products.length
                                            : "-"}
                                        </Index.TableCell>

                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          className="table-td token-tab"
                                          align="center"
                                        >
                                          {totalAmount ? totalAmount : "-"}
                                        </Index.TableCell>

                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          className="table-td"
                                        >
                                          <Index.Box
                                            className="action-btn-icon"
                                            sx={{ display: "flex" }}
                                          >
                                            <Index.Tooltip title="view details">
                                              <Index.Button
                                                className="admin-menu-btn"
                                                variant="contained"
                                                onClick={() => {
                                                  navigate(
                                                    `/admin/orders/view-order-details/${_id}`
                                                  );
                                                }}
                                              >
                                                <Index.Visibility />
                                              </Index.Button>
                                            </Index.Tooltip>
                                          </Index.Box>
                                        </Index.TableCell>
                                      </Index.TableRow>
                                    );
                                  })
                                ) : (
                                  <Index.TableRow>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="no-data-in-list"
                                      colSpan={6}
                                      align="center"
                                    >
                                      {!orderDetails ? (
                                        <PageIndex.Loader />
                                      ) : (
                                        " No data found"
                                      )}
                                    </Index.TableCell>
                                  </Index.TableRow>
                                )}
                              </Index.TableBody>
                            </Index.Table>
                          </Index.TableContainer>

                          {/* <Index.Box className="admin-menu-box">
                              <Index.Menu
                                className="admin-menu-main"
                                id="long-menu"
                                MenuListProps={{
                                  "aria-labelledby": "long-button",
                                }}
                                anchorEl={anchorEl}
                                open={open1}
                                onClose={handleActionClose}
                                PaperProps={{
                                  style: {
                                    maxHeight: ITEM_HEIGHT * 4.5,
                                    width: "112px",
                                    boxShadow: "none",
                                    border: "1px solid rgba(224, 224, 224, 1)",
                                    marginTop: "6px",
                                  },
                                }}
                              >
                                <Index.MenuItem
                                  key={`edit_`}
                                  onClick={handleActionClose}
                                  className="menu-option-li"
                                >
                                  <Index.Button
                                    className="admin-menu-btn"
                                    variant="contained"
                                    onClick={() => handleView()}
                                  >
                                    view
                                  </Index.Button>
                                </Index.MenuItem>
                              </Index.Menu>
                            </Index.Box> */}
                        </Index.Box>
                        {/* <Index.Pagination
                            setCurrentRows={setCurrentRows}
                            allData={currentRows}
                            pageData={currentRows}
                          /> */}
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default OrderDetails;
