import React, { useState, useEffect } from "react";
import Index from "../../Index";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Badge } from "@mui/material";
import { adminLogout } from "../../../redux/features/slices/Admin/AdminSlice";
import { getAdminNotificationList } from "../../../redux/features/slices/Admin/AdminService";
import { getMessaging, deleteToken } from "firebase/messaging";

export default function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [notificationList, setNotificationList] = useState([]);
  const [notificationCounts, setNotificationCounts] = useState("0");
  const open = Boolean(anchorEl);
  const notifications = useSelector((store) => store.admin.notificationList);

  useEffect(() => {
    if (notifications) {
      const countVal = notifications.filter(
        (item) => item.isRead === false
      ).length;
      setNotificationCounts(countVal);
    }
  }, [notifications]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const adminLogoutAction = () => {
    const messaging = getMessaging();
    deleteToken(messaging)
      .then((res) => {
        console.log("fcm token remove", res);
      })
      .catch((err) => {
        console.log("fcm token err", err);
      });
    dispatch(adminLogout());
    navigate("/admin/login");
  };

  // function declaration
  const getAdminNotificationListData = async () => {
    dispatch(getAdminNotificationList())
      .then((response) => {
        if (response?.payload?.data) {
          setNotificationList(response?.payload?.data);
        } else {
          setNotificationList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAdminNotificationListData();
  }, []);

  const openBurgerMenu = () => {
    var element = document.getElementById("admin-sidebar");
    element.classList.add("active");
    var body = document.getElementsByTagName("body");
    body[0].classList.add("body-overflow");
  };

  // console.log(notificationList, "notificationList", notificationCounts);
  return (
    <>
      <Index.Box className="admin-header-main">
        <Index.Box className="admin-header-row">
          <Index.Button className="mobile-menu-btn" onClick={openBurgerMenu}>
            <img
              src={Index.Png.menuburger}
              className="admin-mobile-menu-icon"
              alt="logo"
            />
          </Index.Button>

          <Badge
            badgeContent={notificationCounts ? `${notificationCounts}` : "0"}
            color={notificationList?.length ? "secondary" : "primary"}
            // variant={notificationList?.length ? "standard" : "dot"}
          >
            <Index.Link
              to={"/admin/notifications"}
              className="btn icon-box notify"
            >
              <Index.CircleNotificationsIcon />
            </Index.Link>
          </Badge>

          <Index.Box className="wallet-btn">
            <w3m-button />
          </Index.Box>
          <Index.Box className="admin-header-profile-box">
            <Index.Button
              className="drop-header-btn"
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              disableRipple
            >
              <Index.Box className="flex-drop-main">
                <img
                  src={Index.Png.usericon}
                  className="admin-header-profile-icon"
                  alt="dashboard bell icon"
                ></img>
                <Index.Box className="title-admin-drop">
                  <Index.Typography
                    variant="h5"
                    component="h5"
                    className="admin-header-drop"
                  >
                    Admin
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Button>
            <Index.Menu
              className="drop-header-menu"
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <Index.Link to="/admin/edit-profile" className="text-decoration">
                <Index.MenuItem
                  onClick={handleClose}
                  className="drop-header-menuitem"
                >
                  {" "}
                  <img src={Index.Png.profile} className="drop-header" alt="" />
                  Edit Profile{" "}
                </Index.MenuItem>
              </Index.Link>
              <Index.MenuItem
                onClick={adminLogoutAction}
                className="drop-header-menuitem"
              >
                {" "}
                <img src={Index.Png.logout} className="drop-header" alt="" />
                Log Out{" "}
              </Index.MenuItem>
            </Index.Menu>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
