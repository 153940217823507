import React from "react";
import Index from "../../container/Index";

const style = {
  position: "absolute",
  // pending:50,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const DeleteModals = (props) => {
  return (
    <Index.Modal
      open={props.modalOpen}
      onClose={props.onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      //   className="modal-delete modal"
    >
      <Index.Box sx={style} className="delete-modal-inner-main modal-inner">
        <Index.Box className="delete-modal-cont">
          <Index.Typography
            className="delete-modal-title"
            component="h2"
            variant="h2"
            style={{
              fontFamily: "Poppins-Medium",
              fontSize: 17,
              padding: 20,
              maxWidth: "436px",
            }}
          >
            {props.title}
          </Index.Typography>
          <Index.Box className="update-status-modal-btn-flex">
            <Index.Button
              className="modal-cancel-btn"
              style={{
                color: "white",
                backgroundColor: "#00235f",
                fontFamily: "system-ui",
                fontSize: 14,
                lineHeight: "18px",
                borderRadius: "40px",
                minWidth: "95px",
                minHeight: "36px",
                textTransform: "capitalize",
                cursor: "pointer",
              }}
              onClick={props.onOpenModal}
            >
              Delete
            </Index.Button>
            <Index.Button
              className="modal-delete-btn"
              style={{
                color: "white",
                backgroundColor: "#f13535",
                fontFamily: "system-ui",
                fontSize: 14,
                lineHeight: "18px",
                borderRadius: "40px",
                minWidth: "95px",
                minHeight: "36px",
                textTransform: "capitalize",
                cursor: "pointer",
              }}
              onClick={props.onCloseModal}
            >
              Cancel
            </Index.Button>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Modal>
  );
};

export default DeleteModals;
