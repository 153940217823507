import React from 'react'
import Index from '../../container/Index'
import PageIndex from '../../container/PageIndex'

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    minWidth: "500px",
    maxWidth: "500px",
    borderRadius:"10px",
    padding:"20px",
};

export default function SingleImageModal({ imageData, handleClose }) {

    const handleModalCancel = () => {
        handleClose();
    };

    return (
        <Index.Box sx={style} className="common-modal-inner add-edit-goal-inner">
            <div className="add-edit-goal-form">
                <Index.Box className="comman-modal-body fix-height-modal">
                    <Index.Box className="input-box add-user-input modal-img-view">
                        <img
                            src={`${PageIndex.ImageURL}${imageData?.imageUrl}`}
                            className="user-prof-img nft-image"
                            alt=""
                        />
                    </Index.Box>
                </Index.Box>
                <Index.Box className="comman-modal-footer comman-btn-main">
                    <button onClick={handleModalCancel} className="primary-btn">
                        Cancel
                    </button>
                </Index.Box>
            </div>
    </Index.Box>
    )
}
