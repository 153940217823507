import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { useDispatch } from "react-redux";
import { getAllUserHomePage } from "../../../../redux/features/slices/Admin/AdminService";

export default function Home() {
  const [userCount, setUserCount] = useState(null);
  const dispatch = useDispatch();
  const getAllUser = async () => {
    dispatch(getAllUserHomePage()).then((res) => {
      setUserCount(res?.payload?.data);
    });
  };
  useEffect(() => {
    getAllUser();
  }, [dispatch, getAllUser]);
  return (
    <>
      <Index.Box className="top-home-main" id="section-home">
        <Index.Box className="hero-pos-relative">
          <Index.Box className="home-bg-main">
            <img
              src={Index.Png.herobg}
              className="home-inner-main"
              alt="hero-bg"
            ></img>
          </Index.Box>
          <Index.Box className="home-text-content-top">
            <Index.Box className="container">
              <Index.Box className="home-pd-contnet">
                <Index.Box className="home-content-details">
                  <Index.Typography
                    component="h5"
                    variant="h5"
                    className="home-choose-life-title"
                  >
                    BURN & EARN
                  </Index.Typography>
                  <Index.Typography
                    component="h2"
                    variant="h2"
                    className="home-start-your-fitness"
                  >
                    WALK, JOGG, RUN OR BURN CALORIES TO EARN FTZR TOKEN'S
                  </Index.Typography>

                  <Index.Box className="step-home-list">
                    <Index.List className="step-home-register">
                      <Index.ListItem className="step-listitem-main">
                        <Index.Box className="main-step-list-box">
                          <Index.Typography className="title-step-home-list green-user-text">
                            REGISTERED
                          </Index.Typography>
                          <Index.Typography className="title-step-home-coming">
                            {/* Upcoming */}
                            <span className="user-register-icon">
                              {" "}
                              <img
                                src={Index.Png.users}
                                alt="icon"
                                className="icon-user"
                              />{" "}
                              {userCount ? userCount : "0"}{" "}
                            </span>
                          </Index.Typography>
                        </Index.Box>
                      </Index.ListItem>
                      <Index.ListItem className="step-listitem-main">
                        <Index.Box className="main-step-list-box">
                          <Index.Typography className="title-step-home-list">
                            STEPS WALKED
                          </Index.Typography>
                          <Index.Typography className="title-step-home-coming">
                            Coming Soon
                          </Index.Typography>
                        </Index.Box>
                      </Index.ListItem>
                      <Index.ListItem className="step-listitem-main">
                        <Index.Box className="main-step-list-box">
                          <Index.Typography className="title-step-home-list">
                            CALORIES BURNED
                          </Index.Typography>
                          <Index.Typography className="title-step-home-coming">
                            Coming Soon
                          </Index.Typography>
                        </Index.Box>
                      </Index.ListItem>
                    </Index.List>
                  </Index.Box>

                  <Index.Box
                    component="p"
                    variant="p"
                    className="home-distracted-long"
                  >
                    <Index.Box className="home-register-btn primary-btn-main-link-user">
                      <Index.Link
                        className="comman-primary-btn"
                        to="/user/signup"
                      >
                        {" "}
                        Pre Register
                      </Index.Link>
                    </Index.Box>
                    <span className="home-join-text">
                      {" "}
                      To join Global Health & Fitness Movement.
                    </span>
                    {/* It is a long establishment fact that a reader will be distracted. */}
                  </Index.Box>
                  <Index.Box className="store-main-btn-list">
                    <Index.Box className="btn-list-store">
                      <Index.OutlineButton
                        btnLabel={
                          <>
                            <div className="flex-btn-innner-outline">
                              <div>Google Play</div>
                              <div>Coming Soon</div>
                            </div>
                          </>
                        }
                        imgSrc={Index.Svg.playstore}
                        className="outline-btn-list"
                      />
                    </Index.Box>
                    <Index.Box className="btn-list-store">
                      <Index.OutlineButton
                        btnLabel={
                          <>
                            <div className="flex-btn-innner-outline">
                              <div>App Store</div>
                              <div>Coming Soon</div>
                            </div>
                          </>
                        }
                        imgSrc={Index.Svg.appstore}
                        className="outline-btn-list"
                      />
                    </Index.Box>
                  </Index.Box>
                  {/* <Index.Box className="home-register-btn">
                                    <Index.PrimaryButton btnLabel="Register Now" className="comman-primary-btn" />
                                    </Index.Box> */}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
