import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { getMyReferredUser } from "../../../../redux/features/slices/User/UserService";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import copyTextToClipboard from "copy-text-to-clipboard";
import moment from "moment";
import { Pagination } from "@mui/material";
import { setCountryList } from "../../../../redux/features/slices/Admin/AdminSlice";
import { getCountryList } from "../../../../redux/features/slices/User/UserService";
import { InlineShareButtons } from "sharethis-reactjs";

export default function RefferalProgram() {
  const [showSocialButton, setShowSocialButton] = useState(false);

  const [referredUsers, setReferredUsers] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState();
  const dispatch = useDispatch();

  const userData = useSelector((store) => store.user.userData);

  useEffect(() => {
    const data = { refferalcode: userData?.referCode };
    dispatch(getMyReferredUser(data))
      .then((res) => {
        setReferredUsers(res?.payload?.data);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }, []);

  useEffect(() => {
    setFilteredData(referredUsers);
  }, [referredUsers]);

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [currentRows, setCurrentRows] = React.useState([]);

  // Pagination calculation start

  const PER_PAGE = 10;
  useEffect(() => {
    //Page count
    const count = Math.ceil(filteredData?.length / PER_PAGE);
    setCount(count);
    const indexOfLastRow = currentPage * PER_PAGE;
    const indexOfFirstRow = indexOfLastRow - PER_PAGE;
    const modifiedRows = filteredData?.slice(indexOfFirstRow, indexOfLastRow);
    setCurrentRows(modifiedRows);
  }, [currentPage, filteredData]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Pagination calculation end

  const handleCopyClick = () => {
    copyTextToClipboard(
      `${process.env.REACT_APP_FRONTEND_URL}/user/signup?referCode=${userData?.referCode}`
    );
  };

  useEffect(() => {
    try {
      dispatch(getCountryList()).then((response) => {
        if (response.payload) {
          dispatch(setCountryList(response?.payload?.data));
        } else {
          // toast.error("Something went wrong");
        }
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }, []);

  useEffect(() => {
    if (selectedCountry !== "") {
      const modifiedData = referredUsers?.filter(
        (row) => row?.country?._id === selectedCountry
      );
      setFilteredData(modifiedData);
    } else {
      setFilteredData(referredUsers);
    }
    setCurrentPage(1);
  }, [selectedCountry]);

  const countryList = useSelector((store) => store.admin.countryList);

  return (
    <>
      <Index.Box className="refferal-main-table">
        <Index.Box className="box-card-refferal">
          <Index.Box className="box-pd-refferal">
            <Index.Box className="flex-title-earn">
              <Index.Box className="refferal-title-details">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="refferal-title-content"
                >
                  Referral <span>Program</span>
                </Index.Typography>
              </Index.Box>
              <Index.Box className="referral-drop-down-main">
                <Index.Typography className="country-title">
                  Country
                </Index.Typography>
                <Index.Box className="dropdown-box">
                  <Index.FormControl className="form-control">
                    <Index.Select
                      fullWidth
                      //  renderValue={value !== "" ? undefined : () => "placeholder text"}
                      className="dropdown-select country-dropdown"
                      name="country"
                      defaultValue={selectedCountry ? selectedCountry : ""}
                      value={selectedCountry ? selectedCountry : ""}
                      onChange={(e) => {
                        setSelectedCountry(e.target.value);
                      }}
                      displayEmpty
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                    >
                      <Index.MenuItem value="">All Country</Index.MenuItem>;
                      {countryList?.map((item, index) => (
                        <Index.MenuItem value={item?._id} key={item?._id}>
                          {item?.name}
                        </Index.MenuItem>
                      ))}
                    </Index.Select>
                  </Index.FormControl>
                </Index.Box>
                <Index.Box className="refresh-btn-main">
                  <Index.Button
                    className="refresh-btn"
                    disableRipple
                    onClick={() => setSelectedCountry("")}
                  >
                    <img src={Index.Png.refresh} alt="" />
                  </Index.Button>
                </Index.Box>
              </Index.Box>
              {/* <Index.Box className="refferal-rarn-details">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="earn-title-refferal"
                >
                  Earn 1,000 NCXT for each successful Referral
                </Index.Typography>
              </Index.Box> */}
            </Index.Box>

            {showSocialButton && (
              <Index.Box className="share-btn-list">
                <Index.Box className="flex-title-earn share-link-referral">
                  <InlineShareButtons
                    config={{
                      alignment: "center", // alignment of buttons (left, center, right)
                      color: "social", // set the color of buttons (social, white)
                      enabled: true, // show/hide buttons (true, false)
                      font_size: 16, // font size for the buttons
                      labels: "cta", // button labels (cta, counts, null)
                      language: "en", // which language to use (see LANGUAGES)
                      networks: [
                        // which networks to include (see SHARING NETWORKS)
                        "whatsapp",
                        "facebook",
                        "telegram",
                      ],

                      // OPTIONAL PARAMETERS
                      url: `${process.env.REACT_APP_FRONTEND_URL}/user/signup?referCode=${userData?.referCode}`, // (defaults to og:image or twitter:image)
                    }}
                  />
                </Index.Box>

                <Index.Box className="refresh-btn-main close-share-btn">
                  <Index.Button
                    className="refresh-btn"
                    disableRipple
                    onClick={() => setShowSocialButton(false)}
                  >
                    <img src={Index.Png.close} className="close-img" alt="" />
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            )}

            <Index.Box className="link-main-content">
              <Index.Box className="input-link-main form-group">
                <Index.TextField
                  className="form-control history-link-control"
                  value={`${process.env.REACT_APP_FRONTEND_URL}/user/signup?referCode=${userData?.referCode}`}
                />
              </Index.Box>
              <Index.Box className="btn-history-list">
                <Index.PrimaryButton
                  type="submit"
                  className="comman-primary-btn"
                  btnLabel="Copy"
                  onClick={handleCopyClick}
                />
              </Index.Box>

              {/* <Index.Box className="btn-history-outline">
                <Index.BlueBorderButton
                  type="submit"
                  className="blue-outline-btn"
                  btnLabel="History"
                />
              </Index.Box> */}
              <Index.Box className="btn-history-outline">
                <Index.BlueBorderButton
                  // type="submit"
                  className="blue-outline-btn"
                  btnLabel="Share"
                  onClick={() => setShowSocialButton(true)}
                />
                {/* <div class="sharethis-inline-share-buttons custom-share-width"></div> */}
              </Index.Box>
            </Index.Box>
            <Index.Box className="table-refferal-main">
              <Index.TableContainer
                component={Index.Paper}
                className="refferal-main-paper-table"
              >
                <Index.Table
                  aria-label="simple table"
                  className="refferal-main-table"
                >
                  <Index.TableHead className="refferal-head-table">
                    <Index.TableRow className="refferal-row-table">
                      <Index.TableCell className="refferal-cell-table">
                        USERNAME
                      </Index.TableCell>
                      <Index.TableCell className="refferal-cell-table">
                        EMAIL ID
                      </Index.TableCell>
                      <Index.TableCell className="refferal-cell-table">
                        TOKENS EARNED
                      </Index.TableCell>
                      <Index.TableCell className="refferal-cell-table">
                        COUNTRY
                      </Index.TableCell>
                      <Index.TableCell className="refferal-cell-table">
                        STATUS
                      </Index.TableCell>
                      <Index.TableCell className="refferal-cell-table">
                        DATE
                      </Index.TableCell>
                      {/* <Index.TableCell className="refferal-cell-table"></Index.TableCell> */}
                    </Index.TableRow>
                  </Index.TableHead>
                  <Index.TableBody className="refferal-body-table">
                    {currentRows?.length ? (
                      currentRows?.map(
                        (row, index) => (
                          // row?.refferalBy?._id &&
                          // row?.UserId?._id != userData?._id ? (
                          <Index.TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            className="refferal-datarow-table"
                            key={row?._id}
                          >
                            <Index.TableCell
                              component="th"
                              scope="row"
                              className="refferal-datacell-table"
                            >
                              <Index.Box className="refferal-namelist">
                                <Index.Typography
                                  className="table-refferal-name"
                                  component="p"
                                  variant="p"
                                >
                                  {row?.firstName}
                                </Index.Typography>
                                <Index.Typography
                                  className="name-list-time"
                                  component="p"
                                  variant="p"
                                >
                                  {row?.userName}
                                </Index.Typography>
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="refferal-datacell-table">
                              <Index.Box className="email-refferal">
                                {row?.email}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="refferal-datacell-table">
                              <Index.Box className="prices-refferal">
                                {row?.refReward}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="refferal-datacell-table">
                              <Index.Box className="prices-refferal">
                                {row?.country?.name}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="refferal-datacell-table">
                              <Index.Box className="prices-refferal">
                                {/* {row?.isProfileUpdated == 0
                                  ? "Registered"
                                  : row?.isKyc == true
                                  ? "KYC Approved"
                                  : "Profile Completed"} */}
                                {row?.isProfileUpdated === 0
                                  ? row?.stepCompleted
                                    ? `Step ${row?.stepCompleted} Completed`
                                    : "Registered"
                                  : row?.isKyc === true
                                  ? "KYC Approved"
                                  : "Profile Completed"}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className="refferal-datacell-table">
                              <Index.Box className="main-pending">
                                {moment(row?.createdAt).format("LLL")}
                              </Index.Box>
                            </Index.TableCell>
                          </Index.TableRow>
                        )
                        // ) : (
                        //   <>
                        //     {currentRows?.length == 1 ? (
                        //       <Index.TableRow>
                        //         <Index.TableCell
                        //           component="td"
                        //           variant="td"
                        //           scope="row"
                        //           className="no-data-in-list"
                        //           colSpan={6}
                        //           align="center"
                        //         >
                        //           No data found
                        //         </Index.TableCell>
                        //       </Index.TableRow>
                        //     ) : (
                        //       <></>
                        //     )}
                        //   </>
                        // )
                      )
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list"
                          colSpan={6}
                          align="center"
                        >
                          No data found
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                </Index.Table>
              </Index.TableContainer>
              {currentRows?.length > 0 ? (
                <Index.Box className="pagination-main">
                  <Pagination
                    count={count}
                    page={currentPage}
                    onChange={handlePageChange}
                    variant="outlined"
                    shape="rounded"
                    className="pagination"
                  />
                </Index.Box>
              ) : (
                <></>
              )}
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
