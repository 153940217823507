import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik,FieldArray,getIn } from "formik";
import { MuiOtpInput } from "mui-one-time-password-input";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  Navigate,
  Outlet,
} from "react-router-dom";
import Loader from "../component/common/Loader";
import {
  adminGoalSetupAddSchema,
  updateUserValidationSchema,
  aboutUsValidationSchema,
  privacyPolicyValidationSchema,
  termConditionValidationSchema,
  changePasswordValidationSchema,
  profileValidationSchema,
  addEmployeeValidationSchema,
  settingValidationSchema,
  categoryValidationSchema,
  productValidationSchema,
  subCategoryValidationSchema,
  addSupplierValidationSchema,
  roleValidationSchema,
  storeValidationSchema,
  walletValidationSchema,
  forgotPasswordValidationSchema,
  warehouseValidationSchema,
  adminLoginValidationSchema,
  adminResetPasswordValidationSchema,
  adminUserNFTSetupAddSchema,
  NFTRepairAddEditSchema,
  adminNFTSetupAddSchemaWithImage,
  nftStackValidationSchema,
  adminAddUserValidationSchema,
  AddChallengeValidationSchema,
  addCouponValidationSchema,
  validateMobileNo,
  addEditNutritionPlanSchema,
  adminFaqListAddSchema,
  addProductStockSchema,
  transferProductStockSchema,
  AddWinnerDetailsSchema,
  addSubAdminValidationSchema,
  adminCenterTypeAddSchema,
  addEditAmenitiySchema,
  addEditEquipmentSchema,
  addEditServiceSchema,
  addSponsoredByValidationSchema,
  addEditFitnessChallengeSchema,
  addEditHealthChallengeSchema,
  addOrderCancelReasonSchema,
  addShippedOrderReasonSchema,
} from "../validation/AdminValidation";
import {
  userForgotPasswordValidationSchema,
  userLoginValidationSchema,
  resetPasswordValidationSchema,
  userRegisterValidationSchema,
} from "../validation/UserValidation";
import { ImageURL } from "../config/DataService";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import DeleteModals from "../component/modal/DeleteModal";
import OrderStatusModal from "../component/modal/OrderStatusModal";
import AlertMessage from "../component/modal/AlertMessage";
import { toast } from "react-toastify";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import CustomModal from "../component/modal/CustomModal";
import DynamicTitle from "../component/common/DynamicTitle";
import ReactSpeedometer from "react-d3-speedometer";
import SingleImageModal from "../component/modal/SingleImageModal";



const PageIndex = {
  Formik,
  useDispatch,
  useSelector,
  useState,
  useEffect,
  useNavigate,
  Navigate,
  useLocation,
  adminResetPasswordValidationSchema,
  adminUserNFTSetupAddSchema,
  NFTRepairAddEditSchema,
  Outlet,
  CustomModal,
  Link,
  Loader,
  ReactHtmlParser,
  DialogActions,
  DialogTitle,
  adminLoginValidationSchema,
  ReactSpeedometer,
  userRegisterValidationSchema,
  userLoginValidationSchema,
  adminGoalSetupAddSchema,
  aboutUsValidationSchema,
  privacyPolicyValidationSchema,
  termConditionValidationSchema,
  changePasswordValidationSchema,
  walletValidationSchema,
  profileValidationSchema,
  addEmployeeValidationSchema,
  userForgotPasswordValidationSchema,
  adminNFTSetupAddSchemaWithImage,
  settingValidationSchema,
  nftStackValidationSchema,
  categoryValidationSchema,
  productValidationSchema,
  subCategoryValidationSchema,
  addSupplierValidationSchema,
  roleValidationSchema,
  storeValidationSchema,
  warehouseValidationSchema,
  resetPasswordValidationSchema,
  forgotPasswordValidationSchema,
  MuiOtpInput,
  ImageURL,
  moment,
  DeleteModals,
  OrderStatusModal,
  AlertMessage,
  toast,
  useParams,
  DynamicTitle,
  updateUserValidationSchema,
  adminAddUserValidationSchema,
  AddChallengeValidationSchema,
  addCouponValidationSchema,
  validateMobileNo,
  addEditNutritionPlanSchema,
  adminFaqListAddSchema,  
  addProductStockSchema,
  transferProductStockSchema,
  FieldArray,
  AddWinnerDetailsSchema,
  getIn,
  SingleImageModal,
  addSubAdminValidationSchema,
  adminCenterTypeAddSchema,
  addEditAmenitiySchema,
  addEditEquipmentSchema,
  addEditServiceSchema,
  addSponsoredByValidationSchema,
  addEditFitnessChallengeSchema,
  addEditHealthChallengeSchema,
  addOrderCancelReasonSchema,
  addShippedOrderReasonSchema
};

export default PageIndex;
