import React from "react";
import Index from "../../../../../../component/Index";
import PageIndex from "../../../../../PageIndex";
import PropTypes from "prop-types";

const BootstrapDialog = Index.styled(Index.Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <Index.DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <Index.IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Index.CloseIcon />
        </Index.IconButton>
      ) : null}
    </Index.DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const OtherDetails = ({ userInfo }) => {
  const [open, setOpen] = PageIndex.useState(false);
  const [apiData, setApiData] = PageIndex.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // Kyc
  const kycDocument = userInfo?.kyc?.kycDocument
    ? userInfo?.kyc?.kycDocument
    : "-";

  PageIndex.useEffect(() => {
    if (userInfo?.uniqueIdNo) {
      const timeOut = setTimeout(() => {
        setApiData(true);
      }, 500);
      return () => clearTimeout(timeOut);
    } else {
      const timeOut = setTimeout(() => {
        setApiData(true);
      }, 500);
      return () => clearTimeout(timeOut);
    }
  }, [userInfo]);

  if (!apiData) {
    return <Index.Loader />;
  }

  return (
    <Index.Box className="user-details-main-content">
      <Index.Box sx={{ width: 1 }} className="grid-main">
        <Index.Box
          display="grid"
          className="  user-row user-row-details"
          gridTemplateColumns="repeat(12, 1fr)"
          gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
        >
          <Index.Box
            gridColumn={{
              xs: "span 12",
              sm: "span 12",
              md: "span 12",
              lg: "span 12",
            }}
            className="grid-column user-grid-column"
          >
            <Index.Box className="card-main-details-user">
              <Index.Box className="title-user-details-content">
                <Index.Typography component="p" variant="p">
                  Other Details
                </Index.Typography>
              </Index.Box>
              <Index.Box className="card-inner-details">
                <Index.Box
                  display="grid"
                  className="  user-row"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 6",
                    }}
                    className="grid-column user-grid-column"
                  >
                    <Index.Box className="details-flex-main-user align-items-center">
                      <Index.Typography
                        className="col1-user-main-content"
                        component="p"
                        variant="p"
                      >
                        kyc
                      </Index.Typography>
                      {userInfo?.kyc?.kycType ? (
                        <Index.Typography
                          className="col2-user-main-content"
                          component="p"
                          variant="p"
                        >
                          {userInfo?.kyc?.kycType}
                          <Index.Button
                            className="userDetail-btn-lowercase"
                            onClick={handleClickOpen}
                          >
                            View
                          </Index.Button>
                        </Index.Typography>
                      ) : (
                        ""
                      )}

                      {/* Dialog */}
                      <BootstrapDialog
                        onClose={handleClose}
                        aria-labelledby="customized-dialog-title"
                        open={open}
                      >
                        <BootstrapDialogTitle
                          id="customized-dialog-title"
                          onClose={handleClose}
                        >
                          Document
                        </BootstrapDialogTitle>
                        <Index.DialogContent dividers>
                          <Index.Typography gutterBottom>
                            <img
                              style={{ width: "100%" }}
                              src={PageIndex.ImageURL + kycDocument}
                              alt=""
                            />
                          </Index.Typography>
                        </Index.DialogContent>
                      </BootstrapDialog>
                      {/* Dialog */}
                    </Index.Box>
                  </Index.Box>

                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 6",
                    }}
                    className="grid-column user-grid-column"
                  >
                    <Index.Box className="details-flex-main-user align-items-center">
                      <Index.Typography
                        className="col1-user-main-content"
                        component="p"
                        variant="p"
                      >
                        Unique Id No.
                      </Index.Typography>
                      <Index.Typography
                        className="col2-user-main-content"
                        component="p"
                        variant="p"
                      >
                        {userInfo?.uniqueIdNo ? userInfo?.uniqueIdNo : "-"}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>

                  {/* <Index.Box
        gridColumn={{
          xs: "span 12",
          sm: "span 6",
          md: "span 6",
          lg: "span 6",
        }}
        className="grid-column user-grid-column"
      >
        <Index.Box className="details-flex-main-user">
          <Index.Typography
            className="col1-user-main-content"
            component="p"
            variant="p"
          >
            Unique Id No.
          </Index.Typography>
          <Index.Typography
            className="col2-user-main-content"
            component="p"
            variant="p"
          >
            {userInfo?.uniqueIdNo
              ? userInfo?.uniqueIdNo
              : "-"}
          </Index.Typography>

        
              
        </Index.Box>
      </Index.Box> */}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
};

export default OtherDetails;
