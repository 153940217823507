import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import AdminReducer from "../features/slices/Admin/AdminSlice";
import UserReducer from "../features/slices/User/UserSlice";

const persistConfig = {
  key: "root",
  storage,
};
const rootReducer = combineReducers({
  user: UserReducer,
  admin: AdminReducer,
  //  if we need to use more reducers
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  // reducer: {
  //   persistedReducer,
  //   devTools: process.env.NODE_ENV !== 'production',
  //   middleware: [thunk]

  // },
  reducer: persistedReducer,
});
export const persistor = persistStore(store);
