import React from "react";
import Index from "../../../../component/Index";
import PageIndex from "../../../PageIndex";
import { getSingleSession } from "../../../../redux/features/slices/Admin/AdminService";

const ViewSubSessionDetails = () => {
  const [sessionDetails, setSessionDetails] = PageIndex.useState({});
  const { sessionId } = PageIndex.useParams();
  const navigate = PageIndex.useNavigate();
  const {state} = PageIndex.useLocation();
  console.log("Session ID state", sessionDetails)

  const goBack = () => {
    navigate(-1);
  };

  PageIndex.useEffect(() => {
    setSessionDetails(state);
  }, [state]);

  if (!sessionDetails) {
    return <Index.Loader />;
  }

  return (
    <Index.Box className="dashboard-containt-main">
      <Index.Box className="dashboard-content add-user-containt">
        <Index.Box className="title-list-search">
          <Index.Box className="user-head-title user-title-row">
            <Index.Typography
              className="admin-page-title admin-sub-titles"
              component="h2"
              variant="h2"
            >
              Sub Session Details
            </Index.Typography>
          </Index.Box>

          <Index.Box className="save-btn-main singleNFt-back-btn-box">
            <Index.Button
              onClick={() => goBack()}
              className="save-user-btn primary-btn goal-btn"
              variant="contained"
            >
              <Index.KeyboardBackspaceIcon className="singleNFT-back-btn-icon" />
              Back
            </Index.Button>
          </Index.Box>
        </Index.Box>

        <Index.Box className="tabpanel-main">
          <Index.Box className="add-user-data-main goal-setup-css">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box className="single-group-container">
                <Index.Box className="siggle-group-flex">
                  <Index.Box className="single-group-leftbox">
                    <Index.Box className="leftbox-image-box session-box">
                      <img
                        src={
                          sessionDetails?.subSessionImage
                            ? PageIndex.ImageURL + sessionDetails?.subSessionImage
                            : Index.Png.herobg
                        }
                        alt="loading..."
                        className="singlegroup-leftbox-img"
                      />
                    </Index.Box>
                    <Index.Box className="signgle-data-main">
                      <Index.Box className="singlegroup-leftbox-groupdetail">
                        <ul className="singlegroup-leftbox-ul-list">
                          <li>
                            <h5 className="singlegroup-tag-name">Name</h5>
                            <p className="singlegroup-tag-name-colon">:</p>
                            <p className="singlegroup-tag-name-detail">
                              {sessionDetails?.subSessionName || "-"}
                            </p>
                          </li>
                          <li>
                            <h5 className="singlegroup-tag-name">Status</h5>
                            <p className="singlegroup-tag-name-colon">:</p>
                            <p className="singlegroup-tag-name-detail">
                              {sessionDetails?.isApproved || "-"}
                            </p>
                          </li>
                          <li>
                            <h5 className="singlegroup-tag-name">Created At</h5>
                            <p className="singlegroup-tag-name-colon">:</p>
                            <p className="singlegroup-tag-name-detail">
                              {PageIndex.moment(sessionDetails?.createdAt).format(
                                "MM/DD/YYYY HH:mm"
                              )}
                            </p>
                          </li>
                          <li>
                            <h5 className="singlegroup-tag-name">Description</h5>
                            <p className="singlegroup-tag-name-colon">:</p>
                            <p className="singlegroup-tag-name-detail w-400">
                              {sessionDetails?.subSessionDescription || "-"}
                            </p>
                          </li>
                        </ul>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>

                <Index.Box className="siggle-group-flex">
                  <video width="100%" height="360" controls>
                    <source src={PageIndex.ImageURL + sessionDetails?.subSessionVideo} type="video/mp4" />
                  </video>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
};

export default ViewSubSessionDetails;
