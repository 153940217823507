import React from "react";
import PageIndex from "../../../PageIndex";
import Index from "../../../../component/Index";
import { getAllAppointment } from "../../../../redux/features/slices/Admin/AdminService";

const Appointment = ({ trainerId }) => {
  const [appointmentData, setAppointmentData] = PageIndex.useState([]);
  const [currentRows, setCurrentRows] = PageIndex.useState([]);
  const [searchedData, setSearchedData] = PageIndex.useState([]);
  const [apiData, setApiData] = PageIndex.useState(false);

  const dispatch = PageIndex.useDispatch();

  const getAppointment = () => {
    const urlEncodedData = new URLSearchParams();
    urlEncodedData.append("id", trainerId);
    dispatch(getAllAppointment(urlEncodedData)).then((res) => {
      const { status, data } = res?.payload || {};

      if (status === 200) {
        const datawithCount = data?.map((item, index) => {
          return {
            ...item,
            count: index + 1,
            key: index,
          };
        });

        setAppointmentData(datawithCount);
        setCurrentRows(datawithCount);
        setSearchedData(datawithCount);
        setApiData(true);
      } else {
        setAppointmentData([]);
        setCurrentRows([]);
        setSearchedData([]);
        setApiData(true);
      }
    });
  };

  const requestSearch = (searched) => {
    if (!searched)
      return setSearchedData(
        appointmentData?.map((item, index) => {
          return {
            ...item,
            count: index + 1,
            key: index,
          };
        })
      );

    let filterData = appointmentData?.filter(
      (item) =>
        item?.trainerId?.fullName
          ?.toLowerCase()
          .includes(searched.toLowerCase()) ||
        item?.userId?.fullName
          ?.toString()
          .toLowerCase()
          .includes(searched.toLowerCase()) ||
        PageIndex.moment(item?.date)
          .format("MM/DD/YYYY")
          .toString()
          .toLowerCase()
          .includes(searched.toLowerCase()) ||
        item?.time?.toString().toLowerCase().includes(searched.toLowerCase())
    );

    const datawithCount = filterData?.map((item, index) => {
      return {
        ...item,
        count: index + 1,
        key: index,
      };
    });
    setSearchedData(datawithCount);
  };

  PageIndex.useEffect(() => {
    getAppointment();
  }, []);

  if (!apiData) {
    return <Index.Loader />;
  }

  return (
    <>
      <PageIndex.DynamicTitle title="Fitrizer - Appointment List" />

      <Index.Box className="add-user-data-main dashboard-content add-user-containt">
        <Index.Box className="admin-dashboard-list-row admin-setup-goal-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box>
                  <Index.Box className="user-post-table page-table-main">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container table-head-sticky"
                      sx={{ maxHeight: 405 }}
                    >
                      <Index.Table
                        aria-label="simple table"
                        className="table-user-maindata"
                      >
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="center"
                            >
                              No.
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="center"
                            >
                              Trainer name
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="center"
                            >
                              User name
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="center"
                            >
                              Date
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="center"
                            >
                              Time
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="center"
                            >
                              Description
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {currentRows && currentRows?.length > 0 ? (
                            currentRows?.map((data, index) => (
                              <Index.TableRow key={index}>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                  align="center"
                                >
                                  {data?.count}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                  align="center"
                                >
                                  {data?.trainerId?.fullName
                                    ? data?.trainerId?.fullName
                                    : "-"}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                  align="center"
                                >
                                  {data?.userId?.fullName
                                    ? data?.userId?.fullName
                                    : "-"}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td token-tab"
                                  align="center"
                                >
                                  {data?.date
                                    ? PageIndex.moment(data?.date).format(
                                      "MM/DD/YYYY"
                                    )
                                    : "-"}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                  align="center"
                                >
                                  {data?.time ? data?.time : "-"}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                  align="center"
                                >
                                  {data?.description
                                    ? data?.description
                                    : "-"}
                                </Index.TableCell>
                              </Index.TableRow>
                            ))
                          ) : (
                            <Index.TableRow>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="no-data-in-list"
                                colSpan={6}
                                align="center"
                              >
                                No Data found
                                {/* {!apiData ? (
                                    <Index.Loader />
                                  ) : (
                                    "No Data found"
                                  )} */}
                              </Index.TableCell>
                            </Index.TableRow>
                          )}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                </Index.Box>
                <Index.Pagination
                  setCurrentRows={setCurrentRows}
                  allData={searchedData}
                  pageData={currentRows}
                />
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default Appointment;
