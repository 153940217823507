import Routers from "./routes/Routes";
import "./assets/style/style.css";
import "./assets/style/global.css";
import "./assets/style/responsive.css";
import "./assets/style/admin.css";
import "./container/auth.css";
import "./container/auth.responsive.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";

const projectId = "2d7ded4b2b1becfe9064b344d8c4ba63";

const mainnet = {
  chainId: 137,
  name: "Polygon",
  currency: "MATIC",
  explorerUrl: "https://etherscan.io",
  rpcUrl: "https://polygon-pokt.nodies.app",
};
const mumbai = {
  chainId: 80001,
  name: "Polygon Mumbai",
  currency: "Matic",
  explorerUrl: "https://rpc-mumbai.maticvigil.com",
  rpcUrl: "https://polygon-mumbai.infura.io/v3/42d35890c95b4808ad1e5550c0f2c399",
};

const metadata = {
  name: "My Website",
  description: "My Website description",
  url: "https://mywebsite.com",
  icons: ["https://avatars.mywebsite.com/"],
};

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [mainnet,mumbai],
  projectId,
});

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <Routers />
    </div>
  );
}

export default App;
