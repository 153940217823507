import React from "react";
import Index from "../../../../../../component/Index";
import PageIndex from "../../../../../PageIndex";
import PropTypes from "prop-types";

const BootstrapDialog = Index.styled(Index.Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <Index.DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <Index.IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Index.CloseIcon />
        </Index.IconButton>
      ) : null}
    </Index.DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const BasicDetails = ({ userInfo }) => {
  const [open, setOpen] = PageIndex.useState(false);
  const [apiData, setApiData] = PageIndex.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // countryCode && Mobile
  const countryCode = userInfo?.country?.dial_code
    ? userInfo?.country?.dial_code
    : "0";
  const countryMobile = userInfo?.phoneNumber ? userInfo?.phoneNumber : "0";
  const countryCodeMobile = countryCode + "   " + countryMobile;
  // height value && unit
  const heightFt = userInfo?.height?.fit ? userInfo?.height?.fit : "";
  const heightInch = userInfo?.height?.inch ? userInfo?.height?.inch : "";
  const heightCm = userInfo?.height?.cm ? userInfo?.height?.cm : "";
  const heightMm = userInfo?.height?.mm ? userInfo?.height?.mm : "";
  const heightUnit = userInfo?.height?.unit ? userInfo?.height?.unit : "";

  const heightValueCm = heightCm ? heightCm + "." + heightMm + " " + heightUnit : "-";
  const heightValueFt = heightFt ? heightFt + "." + heightInch + " " + heightUnit : "-";

  // console.log(heightFt,heightInch,heightUnit,heightValueFt,"uves45")
  // weight value && unit
  const weightKilo = userInfo?.weight?.kilo ? userInfo?.weight?.kilo : "";
  const weightGram = userInfo?.weight?.gram ? userInfo?.weight?.gram : "";
  const weightLbs = userInfo?.weight?.lbs ? userInfo?.weight?.lbs : "";
  const weightOz = userInfo?.weight?.oz ? userInfo?.weight?.oz : "";
  const weightUnit = userInfo?.weight?.unit ? userInfo?.weight?.unit : "";
  const weightValueLbs = weightLbs ? weightLbs + "." + weightOz + " " + weightUnit : "-";
  const weightValueKg = weightKilo ? weightKilo + "." + weightGram + " " + weightUnit : "-";

  // date of birth
  const dateOfBirth = userInfo?.dateOfBirth
    ? PageIndex.moment(userInfo?.dateOfBirth).format("MM/DD/YYYY")
    : "-";
  // Kyc
  const kycDocument = userInfo?.kyc?.kycDocument
    ? userInfo?.kyc?.kycDocument
    : "-";


  PageIndex.useEffect(()=>{
    if(userInfo?.userName) {
      const timeOut = setTimeout(() => {
        setApiData(true);
      }, 500);
      return () => clearTimeout(timeOut);
    } else {
      const timeOut = setTimeout(() => {
        setApiData(true);
      }, 500);
      return () => clearTimeout(timeOut);
    }
  },[userInfo])

  if (!apiData) {
    return <Index.Loader />;
  }

  return (
    <Index.Box className="user-details-main-content">
      <Index.Box sx={{ width: 1 }} className="grid-main">
        <Index.Box
          display="grid"
          className="  user-row user-row-details"
          gridTemplateColumns="repeat(12, 1fr)"
          gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
        >
          <Index.Box
            gridColumn={{
              xs: "span 12",
              sm: "span 12",
              md: "span 12",
              lg: "span 12",
            }}
            className="grid-column user-grid-column"
          >
            <Index.Box className="card-main-details-user">
              <Index.Box className="title-user-details-content">
                <Index.Typography component="p" variant="p">
                  Personal Details
                </Index.Typography>
              </Index.Box>
              <Index.Box className="card-inner-details">
                <Index.Box
                  display="grid"
                  className="  user-row"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 6",
                      lg: "span 6",
                    }}
                    className="grid-column user-grid-column"
                  >
                    <Index.Box className="details-flex-main-user">
                      <Index.Typography
                        className="col1-user-main-content"
                        component="p"
                        variant="p"
                      >
                        Full Name
                      </Index.Typography>
                      <Index.Typography
                        className="col2-user-main-content"
                        component="p"
                        variant="p"
                      >
                        {userInfo?.fullName ? userInfo?.fullName : "-"}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="details-flex-main-user">
                      <Index.Typography
                        className="col1-user-main-content"
                        component="p"
                        variant="p"
                      >
                        Username
                      </Index.Typography>
                      <Index.Typography
                        className="col2-user-main-content"
                        component="p"
                        variant="p"
                      >
                        {userInfo?.userName ? userInfo?.userName : "-"}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="details-flex-main-user">
                      <Index.Typography
                        className="col1-user-main-content"
                        component="p"
                        variant="p"
                      >
                        Email
                      </Index.Typography>
                      <Index.Typography
                        className="col2-user-main-content"
                        component="p"
                        variant="p"
                      >
                        {userInfo?.email ? userInfo?.email : "-"}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="details-flex-main-user">
                      <Index.Typography
                        className="col1-user-main-content"
                        component="p"
                        variant="p"
                      >
                        Mobile Number
                      </Index.Typography>
                      <Index.Typography
                        className="col2-user-main-content"
                        component="p"
                        variant="p"
                      >
                        {countryCodeMobile}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="details-flex-main-user">
                      <Index.Typography
                        className="col1-user-main-content"
                        component="p"
                        variant="p"
                      >
                        Date of Birth
                      </Index.Typography>
                      <Index.Typography
                        className="col2-user-main-content"
                        component="p"
                        variant="p"
                      >
                        {dateOfBirth}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="details-flex-main-user">
                      <Index.Typography
                        className="col1-user-main-content"
                        component="p"
                        variant="p"
                      >
                        Weight
                      </Index.Typography>
                      <Index.Typography
                        className="col2-user-main-content"
                        component="p"
                        variant="p"
                      >
                        {userInfo?.weight
                          ? weightUnit === "kg"
                            ? weightValueKg
                            : weightValueLbs
                          : "-"}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 6",
                      lg: "span 6",
                    }}
                    className="grid-column user-grid-column"
                  >
                    <Index.Box className="details-flex-main-user">
                      <Index.Typography
                        className="col1-user-main-content"
                        component="p"
                        variant="p"
                      >
                        Gender
                      </Index.Typography>
                      <Index.Typography
                        className="col2-user-main-content"
                        component="p"
                        variant="p"
                      >
                        {userInfo?.gender ? userInfo?.gender : "-"}
                      </Index.Typography>
                    </Index.Box>

                    <Index.Box className="details-flex-main-user">
                      <Index.Typography
                        className="col1-user-main-content"
                        component="p"
                        variant="p"
                      >
                        Country Name
                      </Index.Typography>
                      <Index.Typography
                        className="col2-user-main-content"
                        component="p"
                        variant="p"
                      >
                        {userInfo?.country?.name
                          ? userInfo?.country?.name
                          : "-"}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="details-flex-main-user">
                      <Index.Typography
                        className="col1-user-main-content"
                        component="p"
                        variant="p"
                      >
                        Age
                      </Index.Typography>
                      <Index.Typography
                        className="col2-user-main-content"
                        component="p"
                        variant="p"
                      >
                        {userInfo?.age ? userInfo?.age : "-"}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="details-flex-main-user">
                      <Index.Typography
                        className="col1-user-main-content"
                        component="p"
                        variant="p"
                      >
                        Height
                      </Index.Typography>
                      <Index.Typography
                        className="col2-user-main-content"
                        component="p"
                        variant="p"
                      >
                        {userInfo?.height
                          ? heightUnit === "fit"
                            ? heightValueFt
                            : heightValueCm
                          : "-"}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="details-flex-main-user align-items-center">
                      <Index.Typography
                        className="col1-user-main-content"
                        component="p"
                        variant="p"
                      >
                        Profile Image
                      </Index.Typography>
                      <Index.Typography
                        className="col2-user-main-content"
                        component="p"
                        variant="p"
                      >
                        <Index.Button
                          className="userDetail-btn-lowercase view-link"
                          onClick={handleClickOpen}
                        >
                          View
                        </Index.Button>
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      {/* Image view modal */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Profile Image
        </BootstrapDialogTitle>
        <Index.DialogContent dividers>
          <Index.Typography gutterBottom>
            <img
              style={{ width: "100%" }}
              src={PageIndex.ImageURL + kycDocument}
              alt=""
            />
          </Index.Typography>
        </Index.DialogContent>
      </BootstrapDialog>
    </Index.Box>
  );
};

export default BasicDetails;
