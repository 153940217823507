import * as Yup from "yup";

// User Register ValidationSchema
const textAndSpaceOnly = (value) =>
  /^[a-zA-Z]+(\s[a-zA-Z]*){0,2}$/.test(value) || value.length === 0;
const charSpaceNumber = (value) => /^[a-zA-Z0-9_.-]*$/.test(value);
export const userRegisterValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("Full Name is required")
    .test("firstName", "Full Name allows only characters", textAndSpaceOnly)
    .max(20, "Full Name allows maximum length 20"),
  userName: Yup.string()
    .required("Username is required")
    .test(
      "userName",
      "Username allows only characters and number",
      charSpaceNumber
    )
    .max(20, "Username allows maximum length 20"),
  email: Yup.string()
    .email("Email is not valid")
    .matches(
      /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/,
      "Email is not valid"
    )
    .required("Email is required"),
  phoneNumber: Yup.string()
    .nullable()
    .min(10, "Mobile Number should be 10 digits")
    .max(10, "Mobile Number should be 10 digits"),
  country: Yup.string().required("Country is required"),
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Password length should be minimum 8 character including one capital letter, one small letter, one number, and one special character, ex. 99@Inchroad"
    ),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf(
      [Yup.ref("password"), null],
      "Confirm password must match with password"
    ),
});

//   User Login ValidationSchema
export const userLoginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email is not valid")
    .matches(
      /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/,
      "Email is not valid"
    )
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

//    User Forgot Password ValidationSchema
export const userForgotPasswordValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email is not valid")
    .matches(
      /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/,
      "Email is not valid"
    )
    .required("Email is required"),
});
//   user reset validationSchema
const digitsOnly = (value) =>
  /^\d*[\.{1}\d*]\d*$/.test(value) || value.length === 0;
export const resetPasswordValidationSchema = Yup.object().shape({
  otp: Yup.string()
    .required("OTP is required")
    .test("inputEntry", "Please enter numberic OTP", digitsOnly)
    .min(4, "Please enter 4 digits OTP")
    .max(4, "Please enter 4 digits OTP"),
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Password length should be minimum 8 character including one capital letter, one small letter, one number, and one special character, ex. 99@Inchroad"
    ),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf(
      [Yup.ref("password"), null],
      "Confirm password must match with password"
    ),
});
//    contactUs validationSchema
export const contactUsValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Email is not valid")
    .matches(
      /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/,
      "Email is not valid"
    )
    .required("Email is required"),
  message: Yup.string().required("Message is required"),
});
// user profile validation with step
