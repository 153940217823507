import React from "react";
import Index from "../Index";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const ListTab = (props) => {
  const location = useLocation();
  return (
    <div>
      <Index.Tabs
        value={props.value}
        onChange={props.onChange}
        aria-label="basic tabs example"
        className="admin-tabs-main"
      >
        {props.label1 && (
          <Index.Tab
            label={props.label1}
            {...a11yProps(0)}
            className="admin-tab"
            disableRipple
          />
        )}

        {props.label2 && (
          <Index.Tab
            label={props.label2}
            {...a11yProps(1)}
            className="admin-tab"
            disableRipple
          />
        )}

        {props.label3 && (
          <Index.Tab
            label={props.label3}
            {...a11yProps(2)}
            className="admin-tab"
            disableRipple
          />
        )}

        {props.label4 && (
          <Index.Tab
            label={props.label4}
            {...a11yProps(3)}
            className="admin-tab"
            disableRipple
          />
        )}

        {props.label5 && (
          <Index.Tab
            label={props.label5}
            {...a11yProps(4)}
            className="admin-tab"
            disableRipple
          />
        )}

        {props.label6 && (
          <Index.Tab
            label={props.label6}
            {...a11yProps(5)}
            className="admin-tab"
            disableRipple
          />
        )}

        {props.label7 && (
          <Index.Tab
            label={props.label7}
            {...a11yProps(6)}
            className="admin-tab"
            disableRipple
          />
        )}

        {props.label8 && (
          <Index.Tab
            label={props.label8}
            {...a11yProps(7)}
            className="admin-tab"
            disableRipple
          />
        )}
      </Index.Tabs>

      {props.children1 && (
        <TabPanel value={props.tabVal} index={0} className="admin-tabpanel">
          {props.children1}
        </TabPanel>
      )}

      {props.children2 && (
        <TabPanel value={props.tabVal} index={1} className="admin-tabpanel">
          {props.children2}
        </TabPanel>
      )}

      {props.children3 && (
        <TabPanel value={props.tabVal} index={2} className="admin-tabpanel">
          {props.children3}
        </TabPanel>
      )}

      {props.children4 && (
        <TabPanel value={props.tabVal} index={3} className="admin-tabpanel">
          {props.children4}
        </TabPanel>
      )}

      {props.children5 && (
        <TabPanel value={props.tabVal} index={4} className="admin-tabpanel">
          {props.children5}
        </TabPanel>
      )}

      {props.children6 && (
        <TabPanel value={props.tabVal} index={5} className="admin-tabpanel">
          {props.children6}
        </TabPanel>
      )}

      {props.children7 && (
        <TabPanel value={props.tabVal} index={6} className="admin-tabpanel">
          {props.children7}
        </TabPanel>
      )}

      {props.children8 && (
        <TabPanel value={props.tabVal} index={7} className="admin-tabpanel">
          {props.children8}
        </TabPanel>
      )}
    </div>
  );
};

export default ListTab;
