import React from "react";
import Index from "../../component/Index";

const AdminTableHeading = (props) => {
  const {
    requestSearch,
    headingTitle,
    fetchData,
    chargesType,
    openChallangeType,
    handleClickChallangeType,
    anchorElChallangeType,
    handleCloseChallangeType,
    handleIndividualData,
    optionalMenuClassName,
  } = props;
  return (
    <Index.Box className="user-list-flex">
      <Index.Typography
        className="admin-page-title user-list-page-title"
        component="h2"
        variant="h2"
      >
        {headingTitle}
      </Index.Typography>
      <Index.Box className="challanges-heading-right-side-data">
        <Index.Box className={optionalMenuClassName}>
          <Index.Button
            id="basic-button"
            aria-controls={openChallangeType ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openChallangeType ? "true" : undefined}
            onClick={handleClickChallangeType}
            variant="outlined"
            size="small"
            className="goal-setup-type-btn"
          >
            {chargesType === "Group" ? "Group" : "Individual"}
            <span>
              <Index.ArrowDropDownIcon />
            </span>
          </Index.Button>
          <Index.Menu
            id="basic-menu"
            anchorEl={anchorElChallangeType}
            open={openChallangeType}
            onClose={handleCloseChallangeType}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <Index.MenuItem onClick={()=>fetchData("Group")}>Group</Index.MenuItem>
            <Index.MenuItem onClick={()=>fetchData("Individual")}>
              Individual
            </Index.MenuItem>
          </Index.Menu>
        </Index.Box>

        {/* MUI MENU  */}
        <Index.Box className="userlist-btn-flex">
          <Index.Box className="user-search-main">
            <Index.Box className="user-search-box">
              <Index.Box className="form-group">
                <Index.TextField
                  fullWidth
                  id="fullWidth"
                  className="form-control"
                  onChange={(e) => requestSearch(e.target.value.trim())}
                  placeholder="Search"
                />
                <img
                  src={Index.Svg.greysearch}
                  className="search-grey-img"
                  alt="search grey img"
                ></img>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
};

export default AdminTableHeading;
