import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Index from "../component/Index";
import moment from "moment";

export const downloadReport = (formdata, fileName) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const ws = XLSX.utils.json_to_sheet(formdata);
  // delete ws["H1"];
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};
export const displayValue = (value) => {
  return value ? value : "-";
};

export const displayNumberValueWithToFixed = (value) => {
  if (value === undefined || isNaN(value)) {
    return "-";
  } else if (value == 0) {
    return "0";
  } else {
    return value.toFixed(2);
  }
};

export const displayNumberValue = (value) => {
  if (value === undefined || isNaN(value)) {
    return "-";
  } else if (value == 0) {
    return "0";
  } else {
    return value;
  }
};

export const displayDateValue = (value) => {
  return moment(value).format("DD-MM-YYYY");
};
export const displayDateWithTime = (value) => {
  return moment(value).format("DD-MM-YYYY h:mm A");
};

export const getStartAndEndDateByType = (type) => {
  let startDate, endDate;

  switch (type) {
    case "day":
      startDate =
        moment()
          .utcOffset("+5:30")
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z"; //By this format it will set startTime 12 AM and end time 11:59 PM because in createdAt we are also storing time that's why we have to add time also
      endDate =
        moment()
          .utcOffset("+5:30")
          .endOf("day")
          .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
      break;

    case "weekly":
      startDate =
        moment()
          .utcOffset("+5:30")
          .startOf("isoWeek")
          .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
      endDate =
        moment()
          .utcOffset("+5:30")
          .endOf("isoWeek")
          .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
      break;

    case "monthly":
      startDate =
        moment()
          .utcOffset("+5:30")
          .startOf("month")
          .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
      endDate =
        moment()
          .utcOffset("+5:30")
          .endOf("month")
          .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
      break;
    default:
      startDate = null;
      endDate = null;
  }
  return {
    startDate: startDate ? new Date(startDate) : null, //Because if we pass null in new Date() then it will give date of 1970
    endDate: endDate ? new Date(endDate) : null,
  };
};

export const formatedDateAndTime = (date, isStartOfDay) => {
  if (!date) return null;

  let formattedDate = moment(date, "YYYY-MM-DD").utcOffset("+5:30");

  if (isStartOfDay) {
    formattedDate.startOf("day");
  } else {
    formattedDate.endOf("day");
  }

  return formattedDate.format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
};

export const hasActionPermission = (role, permissions, tag, action) => {
  // If role is 'admin', allow all actions
  if (role === 'admin') {
    return true;
  }

  // Otherwise, check specific permissions for the action
  return permissions?.[tag]?.[action] || false;
};