import React from 'react'
import Index from '../Index'


export default function Loader() {
  return (
    <>
    
    <Index.Box className="loader-bg-main">
        <Index.Box className="loader-inner-bg-main">
            <Index.Box className="loader-inner-border">
            </Index.Box>
                <img src={Index.Png.loaderlogo} className='logo-small-loader' alt=''></img>
        </Index.Box>
    </Index.Box>
    
    
    </>
  )
}
