import React, { useEffect } from "react";
import Index from "../../Index";
import { GoogleLogin } from "react-google-login";
// import { gapi } from "gapi-script";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userSocialLogin } from "../../../redux/features/slices/User/UserService";

export default function AuthFooter() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // 86071211309-pi0h5mu5e2f8b0booqpug9u0s8r4h3dh.apps.googleusercontent.com - VH key
  // 73794039651-lnga66lm5d59pc35j3f2n7a3r4r533li.apps.googleusercontent.com - client key
  // 755619460499-0qfrhs441pt2biol9h45gjneph42t88a.apps.googleusercontent.com - client active key- fitrizer.health@gmail.com
  // useEffect(() => {
  //   function start() {
  //     gapi.client.init({
  //       clientId:
  //         "755619460499-0qfrhs441pt2biol9h45gjneph42t88a.apps.googleusercontent.com",
  //       scope: "",
  //     });
  //   }
  //   gapi.load("client:auth2", start);
  // });

  const responseGoogle = async (response) => {
    if (response?.accessToken) {
      const socialSignInData = {
        googleId: response?.googleId,
        accessToken: response?.accessToken,
        email: response?.profileObj?.email,
        firstName: response?.profileObj?.name,
        socialType: "Google",
      };
      dispatch(userSocialLogin(socialSignInData)).then((response) => {
        if (response?.payload?.status === 200) {
          navigate("/user/dashboard");
        }
      });
      // try {
      //   await DataService.post(API.User.SOCIAL_LOGIN, socialSignInData)
      //     .then((response) => {
      //       dispatch(getUserToken(response?.data?.data?.token));
      //       dispatch(getUserData(response?.data?.data));
      //       navigate("/user/dashboard");
      //     })
      //     .catch((error) => {
      //       toast.error(error?.data?.message);
      //     });
      // } catch (error) {
      //   toast.error(error?.response?.data?.message);
      // }
    }
  };

  return (
    <>
      <Index.Box className="auth-social-icon-main">
        <Index.List className="auth-footer-social-icon-ul">
          <Index.ListItem className="auth-footer-list">
            <Index.Box className="auth-footer-social-icon-li">
              <GoogleLogin
                clientId="755619460499-0qfrhs441pt2biol9h45gjneph42t88a.apps.googleusercontent.com"
                render={(renderProps) => (
                  <Index.Button
                    className="auth-social-redirect-link"
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                  >
                    <img
                      src={Index.Svg.google}
                      className="auth-social-icon-img"
                      alt=""
                    ></img>

                    <span className="signin-auth-text">
                      Continue with Google
                    </span>
                  </Index.Button>
                )}
                buttonText="Login"
                redirectUri="https://fitrizer.com/user/dashboard"
                // redirectUri="https://fitrizer.appworkdemo.com/user/dashboard"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={"single_host_origin"}
                scope="profile"
              />
            </Index.Box>
          </Index.ListItem>
        </Index.List>
      </Index.Box>
    </>
  );
}
