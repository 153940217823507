import { createSlice } from "@reduxjs/toolkit";
import { loginUserData, registerUserData, userSocialLogin, verifyEmail } from "./UserService";

const UserSlice = createSlice({
  name: "counter",
  initialState: {
    userRegister: [],
    isLoading: false,
    userData: {},
    userToken: "",
    isAuthenticated: false,
    registerUser:{},
    countryList: [],
  },
  reducers: {
    getUserData: (state, action) => {
      state.userData = action.payload;
    },
    getUserToken: (state, action) => {
      state.userToken = action.payload;
      state.isAuthenticated = true;
    },
    userLogout: (state, action) => {
      state.userToken = "";
      state.isAuthenticated = false;
      state.userData = {};
    },
  
    setCountryList: (state, action) => {
      state.countryList = action.payload;
    },
  },
  extraReducers: (builder) => {
    // user register code start
    builder.addCase(registerUserData.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(registerUserData.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(registerUserData.rejected, (state, action) => {
      state.isLoading = false;
    });
    // user register code end

  // user login
    builder.addCase(loginUserData.pending, (state, action) => {
      state.isLoading = true;
    }); 
    builder.addCase(loginUserData.fulfilled, (state, action) => {
      state.userToken=action?.payload?.data?.token
      state.userData=action?.payload?.data
      state.isLoading = false;
    }); 
    builder.addCase(loginUserData.rejected, (state, action) => {
      state.isLoading = false;
    }); 
  // user login end
  
  // user verifyEmail 
  builder.addCase(verifyEmail.pending, (state, action) => {
    state.isLoading = true;
  }); 
  builder.addCase(verifyEmail.fulfilled, (state, action) => {
    state.userToken=action?.payload?.data?.accessToken
    state.userData=action?.payload?.data
    state.isLoading = false;
  }); 
  builder.addCase(verifyEmail.rejected, (state, action) => {
    state.isLoading = false;
  }); 
   // user verifyEmail end 
   builder.addCase(userSocialLogin.pending, (state, action) => {
    state.isLoading = true;
  }); 
   builder.addCase(userSocialLogin.fulfilled, (state, action) => {
    state.userToken=action?.payload?.data?.accessToken
    state.userData=action?.payload?.data
    state.isLoading = false;
  }); 
  builder.addCase(userSocialLogin.rejected, (state, action) => {
    state.isLoading = false;
  }); 
  },
});

export const {
  getUserData,
  getUserToken,
  setCountryList,
  userLogout,
} = UserSlice.actions;

export default UserSlice.reducer;
