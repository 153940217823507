import axios from "axios";

// const API_ENDPOINT = "https://api.fitrizer.appworkdemo.com/api";
// const API_ENDPOINT = "https://api.staging-fitrizer.appworkdemo.com/api";
const API_ENDPOINT = "https://dev-fitrizer.appworkdemo.com/api";
export const ImageURL = "https://dev-fitrizer.appworkdemo.com/api/images/";


const DataService = axios.create({
  baseURL: API_ENDPOINT,
});

export { DataService, API_ENDPOINT };
