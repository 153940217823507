import React, { lazy } from "react";
import { BrowserRouter, Route, Routes as Routess } from "react-router-dom";
import Loadable from "../component/common/Loadable";
import { createBrowserHistory } from "history";

import ViewSingleUserTab from "../container/admin/pages/user/viewSingleUserDetails/ViewSingleUserTab";
import ViewSingleNutritionDetail from "../container/admin/pages/nutrition/ViewSingleNutritionDetail";
import ViewWorkoutPlan from "../container/admin/pages/sessionSubscription/ViewWorkoutPlan";
import NutritionPlansList from "../container/admin/pages/nutrition/NutritionPlansList";
import WorkoutPlanList from "../container/admin/pages/workoutplans/WorkoutPlanList";
import ViewSubSessionDetails from "../container/admin/pages/sessions/ViewSubSessionDetails";


const GoalSetup = Loadable(
  lazy(() => import("../container/admin/pages/goalSetup/GoalSetup"))
);
const Posts = Loadable(
  lazy(() => import("../container/admin/pages/posts/Posts"))
);
const UserGoalSetup = Loadable(
  lazy(() => import("../container/admin/pages/userGoalSetup/UserGoalSetup"))
);
const AddUser = Loadable(
  lazy(() => import("../container/admin/pages/user/EditUser"))
);
const GeneralsettingTabs = Loadable(
  lazy(() => import("../container/admin/pages/settings/SettingTabs"))
);
const Dashboard = Loadable(
  lazy(() => import("../container/admin/pages/dashboard/Dashboard"))
);
const Login = Loadable(lazy(() => import("../container/admin/auth/Login")));

const EditProfile = Loadable(
  lazy(() => import("../container/admin/pages/editProfile/EditProfile"))
);
const ForgotPassword = Loadable(
  lazy(() => import("../container/admin/auth/ForgotPassword"))
);
const ResetPassword = Loadable(
  lazy(() => import("../container/admin/auth/ResetPassword"))
);
const UserList = Loadable(
  lazy(() => import("../container/admin/pages/user/UserList"))
);
const UserLogin = Loadable(lazy(() => import("../container/user/auth/Login")));
const Home = Loadable(lazy(() => import("../container/user/pages/home/Home")));
const SignUp = Loadable(lazy(() => import("../container/user/auth/SignUp")));
const UserForgotPassword = Loadable(
  lazy(() => import("../container/user/auth/ForgotPassword"))
);
const UserResetPassword = Loadable(
  lazy(() => import("../container/user/auth/ResetPassword"))
);
const UserPrivateRoutes = Loadable(lazy(() => import("./UserPrivateRoutes")));
const AdminPrivaRoutes = Loadable(lazy(() => import("./AdminPrivateRoutes")));
const UserDashboard = Loadable(
  lazy(() => import("../container/user/pages/userDashboard/UserDashboard"))
);
const Otp = Loadable(lazy(() => import("../container/user/auth/Otp")));
const Profile = Loadable(
  lazy(() => import("../container/user/pages/userDashboard/Profile"))
);
const RoadMap = Loadable(
  lazy(() => import("../container/admin/pages/roadMap/RoadMap"))
);
const AddRoadMap = Loadable(
  lazy(() => import("../container/admin/pages/roadMap/AddRoadMap"))
);
const BMIReport = Loadable(
  lazy(() => import("../container/user/pages/bmireport/BMIReport"))
);
const Wallet = Loadable(
  lazy(() => import("../container/admin/pages/wallet/Wallet"))
);
const WalletHistory = Loadable(
  lazy(() => import("../component/user/pages/userDashboard/WalletHistory"))
);
const ContactRequest = Loadable(
  lazy(() => import("../container/admin/pages/contactRequest/ContactRequest"))
);
const Warehouse = Loadable(
  lazy(() => import("../container/admin/pages/warehouse/Warehouse"))
);
const AddWarehouse = Loadable(
  lazy(() => import("../container/admin/pages/warehouse/AddWarehouse"))
);
const ViewStockList = Loadable(
  lazy(() => import("../container/admin/pages/warehouse/ViewStockList"))
);
const Store = Loadable(
  lazy(() => import("../container/admin/pages/store/Store"))
);
const AddStore = Loadable(
  lazy(() => import("../container/admin/pages/store/AddStore"))
);
const AddEmployee = Loadable(
  lazy(() => import("../container/admin/pages/employee/AddEmployee"))
);
const Employee = Loadable(
  lazy(() => import("../container/admin/pages/employee/Employee"))
);
const ViewStore = Loadable(
  lazy(() => import("../container/admin/pages/store/ViewStore"))
);
const ViewWarehouse = Loadable(
  lazy(() => import("../container/admin/pages/warehouse/ViewWarehouse"))
);
const AddRole = Loadable(
  lazy(() => import("../container/admin/pages/roles/AddRole"))
);
const Roles = Loadable(
  lazy(() => import("../container/admin/pages/roles/Role"))
);
const ViewEmployee = Loadable(
  lazy(() => import("../container/admin/pages/employee/ViewEmployee"))
);
const AddCategory = Loadable(
  lazy(() => import("../container/admin/pages/inventory/category/AddCategory"))
);
const Category = Loadable(
  lazy(() => import("../container/admin/pages/inventory/category/Category"))
);
const AddBrand = Loadable(
  lazy(() => import("../container/admin/pages/inventory/brand/AddBrand"))
);
const Brand = Loadable(
  lazy(() => import("../container/admin/pages/inventory/brand/Brand"))
);
const SubCategory = Loadable(
  lazy(() =>
    import("../container/admin/pages/inventory/subCategory/SubCategory")
  )
);
const AddSubCategory = Loadable(
  lazy(() =>
    import("../container/admin/pages/inventory/subCategory/AddSubCategory")
  )
);
const ViewSubCategory = Loadable(
  lazy(() =>
    import("../container/admin/pages/inventory/subCategory/ViewSubCategory")
  )
);
const AddProduct = Loadable(
  lazy(() => import("../container/admin/pages/inventory/product/AddProduct"))
);
const ViewProduct = Loadable(
  lazy(() => import("../container/admin/pages/inventory/product/ViewProduct"))
);
const Product = Loadable(
  lazy(() => import("../container/admin/pages/inventory/product/Product"))
);
const AddSupplier = Loadable(
  lazy(() => import("../container/admin/pages/inventory/supplier/AddSupplier"))
);
const Supplier = Loadable(
  lazy(() => import("../container/admin/pages/inventory/supplier/Supplier"))
);
const ViewSupplier = Loadable(
  lazy(() => import("../container/admin/pages/inventory/supplier/ViewSupplier"))
);
const AboutUs = Loadable(
  lazy(() => import("../container/admin/pages/cms/aboutUs/AboutUs"))
);
const TermCondition = Loadable(
  lazy(() => import("../container/admin/pages/cms/termCondition/TermCondition"))
);
const PrivacyPolicy = Loadable(
  lazy(() => import("../container/admin/pages/cms/privacyPolicy/PrivacyPolicy"))
);
const ViewPost = Loadable(
  lazy(() => import("../container/admin/pages/viewPost/ViewPost"))
);
const ReportOnSpecificPost = Loadable(
  lazy(() =>
    import("../container/admin/pages/reportOnSpecificPost/ReportOnSpecificPost")
  )
);
const SingalUserPost = Loadable(
  lazy(() => import("../container/admin/pages/posts/SingalUserPost"))
);
const ReportPost = Loadable(
  lazy(() => import("../container/admin/pages/posts/ReportPost"))
);
const SponsoredPost = Loadable(
  lazy(() => import("../container/admin/pages/posts/SponsoredPost"))
);
const ReportUser = Loadable(
  lazy(() => import("../container/admin/pages/posts/ReportUser"))
);
const Challengs = Loadable(
  lazy(() => import("../container/admin/pages/challengs/Challengs"))
);
const AddChallenge = Loadable(
  lazy(() => import("../container/admin/pages/challengs/AddChallenge"))
);
const CommunityGroup = Loadable(
  lazy(() => import("../container/admin/pages/communityGroup/CommunityGroup"))
);
const SingleGroup = Loadable(
  lazy(() => import("../container/admin/pages/singleGroup/SingleGroup"))
);
const SingalReportUser = Loadable(
  lazy(() =>
    import("../container/admin/pages/singalReportUser/SingalReportUser")
  )
);
const TrainerList = Loadable(
  lazy(() => import("../container/admin/pages/trainer-list/TrainerList"))
);
const TrainerDetail = Loadable(
  lazy(() => import("../container/admin/pages/trainer-list/TrainerDetail"))
);
const CenterDetail = Loadable(
  lazy(() => import("../container/admin/pages/trainer-list/CenterDetail"))
);
const NftListing = Loadable(
  lazy(() => import("../container/admin/pages/nftListing/NftListing"))
);
const NftSetup = Loadable(
  lazy(() => import("../container/admin/pages/nftSetup/NftSetup"))
);
const CenterTypes = Loadable(
  lazy(() => import("../container/admin/pages/centerType/CenterTypeList"))
);
const NftRepair = Loadable(lazy(() => import("../container/admin/pages/nftRepair/NftRepair")));
const RepairNFTRequestList = Loadable(lazy(() => import("../container/admin/pages/nftRepair/RepairNFTRequestList")));
const NftStack = Loadable(
  lazy(() => import("../container/admin/pages/nftStack/NftStack"))
);
const NftView = Loadable(
  lazy(() => import("../container/admin/pages/nftListing/SingleNft"))
);
const OrderList = Loadable(
  lazy(() => import("../container/admin/pages/orders/OrderList"))
);
const ViewSingleOrder = Loadable(
  lazy(() => import("../container/admin/pages/orders/ViewSingleOrder"))
);
const TicketList = Loadable(
  lazy(() => import("../container/admin/pages/tickets/TicketList"))
);
const TicketDetail = Loadable(
  lazy(() => import("../container/admin/pages/tickets/TicketDetail"))
);
const Coupons = Loadable(
  lazy(() => import("../container/admin/pages/coupon/Coupons"))
);
const AddCoupon = Loadable(
  lazy(() => import("../container/admin/pages/coupon/AddCoupon"))
);
const NftTransactionLists = Loadable(
  lazy(() =>
    import("../container/admin/pages/nftTransactions/NftTransactionList")
  )
);
const ViewSingleChallengeDetails = Loadable(
  lazy(() =>
    import(
      "../container/admin/pages/challengs/singleChallengeDetails/SingleChallengeDetails"
    )
  )
);
const OrderRatingList = Loadable(
  lazy(() => import("../container/admin/pages/ratings/OrderRatingList"))
);
const AppointmentList = Loadable(
  lazy(() => import("../container/admin/pages/appointments/AppointmentList"))
);
const CouponOrders = Loadable(
  lazy(() => import("../container/admin/pages/coupon/CouponOrders"))
);
const SessionList = Loadable(
  lazy(() => import("../container/admin/pages/sessions/SessionList"))
);
const SubSessionList = Loadable(
  lazy(() => import("../container/admin/pages/sessions/SubSessionList"))
);
const ViewSingleSessionDetails = Loadable(
  lazy(() =>
    import("../container/admin/pages/sessions/ViewSingleSessionDetails")
  )
);

const SessionSubscriptionList = Loadable(
  lazy(() =>
    import("../container/admin/pages/sessionSubscription/SessionSubscription")
  )
);

const AddEditNutritionPlan = Loadable(
  lazy(() => import("../container/admin/pages/nutrition/AddEditNutrition"))
);

const NutritionPlans = Loadable(
  lazy(() => import("../container/admin/pages/nutrition/NutritionPlans"))
);

const Faq = Loadable(lazy(() => import("../container/admin/pages/faq/Faq")));
const HomeLayout = Loadable(
  lazy(() => import("../container/user/pages/home/HomeLayout"))
);
const AboutUsPage = Loadable(
  lazy(() => import("../container/user/pages/home/AboutUs"))
);
const PrivacyPolicyPage = Loadable(
  lazy(() => import("../container/user/pages/home/PrivacyPolicy"))
);
const TermConditionPage = Loadable(
  lazy(() => import("../container/user/pages/home/TermsCondition"))
);

const OrderTicketList = Loadable(
  lazy(() => import("../container/admin/pages/orderTickets/OrderTicketList"))
);

const Notifications = Loadable(lazy(() => import("../container/admin/pages/notifications/Notification")));

// const TrainerProducts = Loadable(
//   lazy(() => import("../container/admin/pages/inventory/trainerProduct/TrainerProduct"))
// );

const ViewProductStockHistory = Loadable(
  lazy(() => import("../container/admin/pages/warehouse/ViewProductStockHistory"))
);

const AddWinnerDetails = Loadable (
  lazy(() => import("../container/admin/pages/challengs/AddWinnerDetails"))
)

const DashboardReport = Loadable (
  lazy(() => import("../container/admin/pages/dashboard/DashboardReport"))
)

const LeaseNFTList = Loadable (
  lazy(() => import("../container/admin/pages/leaseNFT/LeaseNFT"))
)

const WarehouseStockReport = Loadable (
  lazy(() => import("../container/admin/pages/inventory/warehouseStockReport/WarehouseStockReport"))
)

const OrderSalesReport = Loadable (
  lazy(() => import("../container/admin/pages/inventory/orderSalesReport/OrderSalesReport"))
)

const SubAdminList = Loadable (
  lazy(() => import("../container/admin/pages/subAdmin/SubAdminList"))
)

const AddSubAdmin = Loadable (
  lazy(() => import("../container/admin/pages/subAdmin/AddSubAdmin"))
)

const Amenities = Loadable(
  lazy(() => import("../container/admin/pages/amenity/Amenities"))
);
const AddEditAmenity = Loadable(
  lazy(() => import("../container/admin/pages/amenity/AddEditAmenity"))
);

const Equipments = Loadable(
  lazy(() => import("../container/admin/pages/equipments/Equipments"))
);
const AddEditEquipment = Loadable(
  lazy(() => import("../container/admin/pages/equipments/AddEditEquipment"))
);

const Services = Loadable(
  lazy(() => import("../container/admin/pages/services/Services"))
);
const AddEditService = Loadable(
  lazy(() => import("../container/admin/pages/services/AddEditService"))
);

const OrderReview = Loadable(
  lazy(() => import("../container/admin/pages/reviews/OrderReview"))
);

const OrderProductReviewDetail = Loadable(
  lazy(() => import("../container/admin/pages/reviews/OrderProductReviewDetail"))
);

const CenterReview = Loadable(
  lazy(() => import("../container/admin/pages/reviews/CenterReview"))
);

const TrainerReview = Loadable(
  lazy(() => import("../container/admin/pages/reviews/TrainerReview"))
);

const HealthChallenges = Loadable(
  lazy(() => import("../container/admin/pages/healthChallenges/HealthChallenges"))
);
const AddEditHealthChallenge = Loadable(
  lazy(() => import("../container/admin/pages/healthChallenges/AddEditHealthChallenge"))
);

const FitnessChallenges = Loadable(
  lazy(() => import("../container/admin/pages/fitnessChallenges/FitnessChallenges"))
);
const AddEditFitnessChallenge = Loadable(
  lazy(() => import("../container/admin/pages/fitnessChallenges/AddEditFitnessChallenge"))
);

const history = createBrowserHistory();
export default function Routes() {
  return (
    <BrowserRouter history={history}>
      <Routess>

        {/* wallet */}

        {/* <Route path="/presale-buy/:userId" element={<BuyToken/>} />
        <Route path="/stake-token/:userId" element={<StakeTokenTabs/>} />
        <Route path="/investor-buy" element={<BuyInvestorToken/>}/> */}
        {/* <Route path="/lock-stake/:userId" element={<LockStakeToken/>} />
        <Route path="/soft-stake" element={<SoftStakeToken/>}/> */}

        <Route path="" element={<Home />} />
        <Route path="" element={<HomeLayout />}>
          <Route path="about-us" element={<AboutUsPage />} />
          <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="terms-condition" element={<TermConditionPage />} />
        </Route>

        <Route path="/admin/login" element={<Login />} />
        <Route path="admin/forgot-password" element={<ForgotPassword />} />
        <Route
          path="admin/reset-password/:userId"
          element={<ResetPassword />}
        />

        <Route element={<AdminPrivaRoutes />}>
          {/* Admin Panel Routes */}
          <Route path="admin" element={<Dashboard />}>
            <Route path="dashboard" element={<DashboardReport />} />
            <Route path="road-map" element={<RoadMap />} />
            <Route path="add-roadmap" element={<AddRoadMap />} />
            <Route path="general-setting" element={<GeneralsettingTabs />} />
            <Route path="goal-setup" element={<GoalSetup />} />
            <Route path="usergoalsetup" element={<UserGoalSetup />} />
            <Route path="posts" element={<Posts />} />
            <Route path="trainer-list" element={<TrainerList />} />
            <Route path="trainer-list/:trainerId" element={<TrainerDetail />} />
            <Route path="center-detail/:centerId" element={<CenterDetail />} />
            {/* <Route path="reportedpost" element={<ReportePost />} /> */}
            <Route path="communitygroup" element={<CommunityGroup />} />
            <Route path="singleGroup" element={<SingleGroup />} />
            <Route path="viewpost" element={<ViewPost />} />
            <Route path="nft-listing" element={<NftListing />} />
            <Route path="lease-nft-listing" element={<LeaseNFTList />} />
            <Route path="nft-setup" element={<NftSetup />} />
            <Route path="center-types" element={<CenterTypes />} />
            <Route path="nft-repair-setup" element={<NftRepair />} />
            <Route path="repair-nft-request" element={<RepairNFTRequestList />} />
            <Route path="nft-stack" element={<NftStack />} />
            <Route path="nft-listing/:nftId" element={<NftView />} />
            <Route path="sponsored-posts" element={<SponsoredPost />} />

            <Route
              path="nft-transaction-list"
              element={<NftTransactionLists />}
            />
            {/* <Route path="nftform" element={<NftForm />} /> */}
            <Route
              path="reportonspecificpost"
              element={<ReportOnSpecificPost />}
            />
            <Route path="singaluserpost" element={<SingalUserPost />} />
            <Route path="edit-roadmap/:editId" element={<AddRoadMap />} />
            <Route path="edit-profile" element={<EditProfile />} />
            <Route path="wallet" element={<Wallet />} />
            
            <Route path="amenities">
              <Route path="" element={<Amenities />} />
              <Route path="add-amenity" element={<AddEditAmenity />} />
              <Route path="edit-amenity/:editId" element={<AddEditAmenity />} />
            </Route>

            <Route path="equipments">
              <Route path="" element={<Equipments />} />
              <Route path="add-equipment" element={<AddEditEquipment />} />
              <Route path="edit-equipment/:editId" element={<AddEditEquipment />} />
            </Route>

            <Route path="services">
              <Route path="" element={<Services />} />
              <Route path="add-service" element={<AddEditService />} />
              <Route path="edit-service/:editId" element={<AddEditService />} />
            </Route>

            <Route path="health-challenges">
              <Route path="" element={<HealthChallenges />} />
              <Route path="add" element={<AddEditHealthChallenge />} />
              <Route path="edit/:editId" element={<AddEditHealthChallenge />} />
            </Route>

            <Route path="fitness-challenges">
              <Route path="" element={<FitnessChallenges />} />
              <Route path="add" element={<AddEditFitnessChallenge />} />
              <Route path="edit/:editId" element={<AddEditFitnessChallenge />} />
            </Route>

            <Route path="reviews">
              <Route path="order-review" element={<OrderReview />} />
              <Route path="order-product-review-detail/:orderId" element={<OrderProductReviewDetail />} />
              <Route path="center-review" element={<CenterReview />} />
              <Route path="trainer-review" element={<TrainerReview />} />
            </Route>

            {/* report list Routes */}
            <Route path="report">
              <Route path="reportpost" element={<ReportPost />} />
              <Route path="reportuser" element={<ReportUser />} />
              <Route
                path="reportuser/:reportId"
                element={<SingalReportUser />}
              />
            </Route>
           
            <Route path="user">
              <Route path="" element={<UserList />} />
              <Route path="add-user" element={<AddUser />} />
              <Route path="edit-user/:editId" element={<AddUser />} />
              {/* <Route path="view/:editId" element={<ViewUser />} /> */}
              <Route path="view/:editId" element={<ViewSingleUserTab />} />
            </Route>

            {/* Role Routes */}
            <Route path="role">
              <Route path="role" element={<Roles />} />
              <Route path="add-role" element={<AddRole />} />
              <Route path="edit-role/:editId" element={<AddRole />} />
            </Route>

            {/* Inventory Routes */}
            <Route path="inventory">
              {/* Category Routes */}
              <Route path="category">
                <Route path="category" element={<Category />} />
                <Route path="add-category" element={<AddCategory />} />
                <Route path="edit-category/:editId" element={<AddCategory />} />
              </Route>
              {/* Brand Routes */}
              <Route path="brand">
                <Route path="brand" element={<Brand />} />
                <Route path="add-brand" element={<AddBrand />} />
                <Route path="edit-brand/:editId" element={<AddBrand />} />
              </Route>
              {/* Product Routes */}
              <Route path="product">
                <Route path="add-product" element={<AddProduct />} />
                <Route path="product" element={<Product />} />
                <Route path="view-product/:editId" element={<ViewProduct />} />
                <Route path="edit-product/:editId" element={<AddProduct />} />
              </Route>
              {/* Trainer product routes */}
              {/* <Route path="trainer-products" element={<TrainerProducts />} /> */}
              {/* Subcategory Routes */}
              <Route path="sub-category">
                <Route path="sub-category" element={<SubCategory />} />
                <Route path="add-sub-category" element={<AddSubCategory />} />
                <Route
                  path="add-sub-category/:editId"
                  element={<AddSubCategory />}
                />
                <Route
                  path="view-sub-category/:editId"
                  element={<ViewSubCategory />}
                />
              </Route>
              {/* Supplier Routes */}
              <Route path="supplier">
                <Route path="add-supplier" element={<AddSupplier />} />
                <Route path="supplier" element={<Supplier />} />
                <Route path="edit-supplier/:editId" element={<AddSupplier />} />
                <Route
                  path="view-supplier/:editId"
                  element={<ViewSupplier />}
                />
              </Route>

              {/* Warehouse Routes */}
              <Route path="warehouse">
                <Route path="warehouse" element={<Warehouse />} />
                <Route path="add-warehouse" element={<AddWarehouse />} />
                <Route path="edit-warehouse/:editId" element={<AddWarehouse />} />
                <Route
                  path="view-warehouse/:editId"
                  element={<ViewWarehouse />}
                />
                <Route path="view-stock-list/:warehouseId" element={<ViewStockList />} />
                <Route path="view-product-stock-history/:warehouseId/:productId" element={<ViewProductStockHistory />} />
              </Route>

              {/* Store Routes */}
              <Route path="store">
                <Route path="store" element={<Store />} />
                <Route path="add-store" element={<AddStore />} />
                <Route path="edit-store/:editId" element={<AddStore />} />
                <Route path="view-store/:editId" element={<ViewStore />} />

                <Route path="employee" element={<Employee />} />
                <Route path="add-employee" element={<AddEmployee />} />
                <Route path="edit-employee/:editId" element={<AddEmployee />} />
                <Route path="view-employee/:editId" element={<ViewEmployee />} />
              </Route>

              {/* Coupons Routes */}
            <Route path="coupons">
              <Route path="add-coupon" element={<AddCoupon />} />
              <Route path="view-coupons" element={<Coupons />} />
              <Route
                path="view-coupon-orders/:couponId"
                element={<CouponOrders />}
              />
            </Route>

            {/* Report and analysis */}
            <Route path="report">
              <Route path="stock-report" element={<WarehouseStockReport/>}/>
              <Route path="sales-report" element={<OrderSalesReport/>}/>
            </Route>
            </Route>

            {/* CMS Routes */}
            <Route path="cms">
              <Route path="about-us" element={<AboutUs />} />
              <Route path="term-condition" element={<TermCondition />} />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route path="faq-list" element={<Faq />} />
              {/* Ticket Routes */}
              <Route path="tickets">
                <Route path="list" element={<TicketList />} />
                <Route path="ticket-details/:id" element={<TicketDetail />} />
              </Route>
              <Route path="contact-request" element={<ContactRequest />} />
            </Route>

            {/* Orders Routes */}
            <Route path="orders">
              <Route path="list" element={<OrderList />} />
              <Route
                path="view-order-details/:orderId"
                element={<ViewSingleOrder />}
              />
              <Route path="rating-list" element={<OrderRatingList />} />
            </Route>



            {/* Challenge Routes */}
            <Route path="challenges">
              <Route path="" element={<Challengs />} />
              <Route path="add-challenge" element={<AddChallenge />} />
              <Route path="add-winner-details/:challengeId" element={<AddWinnerDetails />} />
              <Route path="edit-challenge/:editId" element={<AddChallenge />} />
              <Route
                path="view-challenge-details/:challengeId"
                element={<ViewSingleChallengeDetails />}
              />
            </Route>

            {/* Appointment routes */}
            <Route path="appointment-list" element={<AppointmentList />} />

            {/* Session routes */}
            <Route path="session-list" element={<SessionList />} />
            <Route
              path="sub-session-list/:sessionId"
              element={<SubSessionList />}
            />
            <Route
              path="session-details/:sessionId"
              element={<ViewSingleSessionDetails />}
            />

            <Route
              path="sub-session-details/:sessionId"
              element={<ViewSubSessionDetails />}
            />

            <Route
              path="session-subscription-list"
              element={<SessionSubscriptionList />}
            />

            {/* Receipe plans */}
            <Route path="receipe-plans" element={<NutritionPlans />} />
            <Route
              path="add-receipe-plans"
              element={<AddEditNutritionPlan />}
            />
            <Route
              path="edit-receipe-plans/:planId"
              element={<AddEditNutritionPlan />}
            />
            <Route
              path="receipe-plans/:planId"
              element={<ViewSingleNutritionDetail />}
            />
            <Route path="nutrition-plans" element={<NutritionPlansList />} />

            <Route path="workout-plans" element={<WorkoutPlanList />} />
            <Route
              path="view-workout-plans/:id"
              element={<ViewWorkoutPlan />}
            />

            {/* Order tickets */}
            <Route path="order-tickets" element={<OrderTicketList />} />
            <Route path="notifications" element={<Notifications />} />

            {/* Sub admin routes */}
            <Route path="sub-admins" element={<SubAdminList />} />
            <Route path="add-sub-admins" element={<AddSubAdmin />} />
            <Route path="edit-sub-admins" element={<AddSubAdmin />} />
          </Route>
        </Route>

        {/* User Panel Routes */}
        <Route path="/user">
          <Route path="login" element={<UserLogin />} />
          <Route path="signup" element={<SignUp />} />
          <Route path="forgot-password" element={<UserForgotPassword />} />
          <Route
            path="reset-password/:userId"
            element={<UserResetPassword />}
          />
          <Route path="signup/:referCode=referCode" element={<SignUp />} />
          <Route path="otp-verify/:userId" element={<Otp />} />
          <Route element={<UserPrivateRoutes />}>
            <Route path="" element={<Home />} />
            <Route path="home" element={<Home />} />
            <Route path="dashboard" element={<UserDashboard />} />
            <Route path="wallet-history" element={<WalletHistory />} />
            <Route path="profile" element={<Profile />} />
            <Route path="bmi-report" element={<BMIReport />} />
          </Route>
        </Route>
      </Routess>
    </BrowserRouter>
  );
}
