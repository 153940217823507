import React from "react";
import Index from "../../../../../../component/Index";
import PageIndex from "../../../../../PageIndex";

const FitnessDetails = ({userInfo}) => {
  const [apiData, setApiData] = PageIndex.useState(false);

  PageIndex.useEffect(()=>{
    if(userInfo?.routine) {
      const timeOut = setTimeout(() => {
        setApiData(true);
      }, 500);
      return () => clearTimeout(timeOut);
    }else {
      const timeOut = setTimeout(() => {
        setApiData(true);
      }, 500);
      return () => clearTimeout(timeOut);
    }
  },[userInfo])

  if (!apiData) {
    return <Index.Loader />;
  }

  return (
    <Index.Box className="user-details-main-content">
      <Index.Box sx={{ width: 1 }} className="grid-main">
        <Index.Box
          display="grid"
          className="  user-row user-row-details"
          gridTemplateColumns="repeat(12, 1fr)"
          gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
        >
          <Index.Box
            gridColumn={{
              xs: "span 12",
              sm: "span 12",
              md: "span 12",
              lg: "span 12",
            }}
            className="grid-column user-grid-column"
          >
            <Index.Box className="card-main-details-user">
              <Index.Box className="title-user-details-content">
                <Index.Typography component="p" variant="p">
                  Fitness Details
                </Index.Typography>
              </Index.Box>
              <Index.Box className="card-inner-details">
                <Index.Box
                  display="grid"
                  className="  user-row"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 6",
                      lg: "span 6",
                    }}
                    className="grid-column user-grid-column"
                  >
                    <Index.Box className="details-flex-main-user">
                      <Index.Typography
                        className="col1-user-main-content"
                        component="p"
                        variant="p"
                      >
                        Routine
                      </Index.Typography>
                      <Index.Typography
                        className="col2-user-main-content"
                        component="p"
                        variant="p"
                      >
                        {userInfo?.routine ? userInfo?.routine : "-"}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="details-flex-main-user">
                      <Index.Typography
                        className="col1-user-main-content"
                        component="p"
                        variant="p"
                      >
                        Health Challanges
                      </Index.Typography>
                      <Index.Typography
                        className="col2-user-main-content"
                        component="p"
                        variant="p"
                      >
                        {userInfo?.healthChallanges?.length
                          ? userInfo?.healthChallanges?.join(",")
                          : "-"}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="details-flex-main-user">
                      <Index.Typography
                        className="col1-user-main-content"
                        component="p"
                        variant="p"
                      >
                        Food Preference
                      </Index.Typography>
                      <Index.Typography
                        className="col2-user-main-content"
                        component="p"
                        variant="p"
                      >
                        {userInfo?.foodPreference
                          ? userInfo?.foodPreference
                          : "-"}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 6",
                      lg: "span 6",
                    }}
                    className="grid-column user-grid-column"
                  >
                    <Index.Box className="details-flex-main-user">
                      <Index.Typography
                        className="col1-user-main-content"
                        component="p"
                        variant="p"
                      >
                        Diet
                      </Index.Typography>
                      <Index.Typography
                        className="col2-user-main-content"
                        component="p"
                        variant="p"
                      >
                        {userInfo?.diet ? userInfo?.diet : "-"}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="details-flex-main-user">
                      <Index.Typography
                        className="col1-user-main-content"
                        component="p"
                        variant="p"
                      >
                        Fitness Goal
                      </Index.Typography>
                      <Index.Typography
                        className="col2-user-main-content"
                        component="p"
                        variant="p"
                      >
                        {userInfo?.goal?.length ? userInfo?.goal?.join(",") : "-"}
                      </Index.Typography>
                    </Index.Box>

                    <Index.Box className="details-flex-main-user">
                      <Index.Typography
                        className="col1-user-main-content"
                        component="p"
                        variant="p"
                      >
                        Referral Code
                      </Index.Typography>
                      <Index.Typography
                        className="col2-user-main-content"
                        component="p"
                        variant="p"
                      >
                        {userInfo?.referCode ? userInfo?.referCode : "-"}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
};

export default FitnessDetails;
