import { createSlice } from "@reduxjs/toolkit";
import {
  registerAdmin,
  getRoleList,
  getAllEmployee,
  getAllCategory,
  getAllSupplier,
  getAllCMS,
  updateAboutUs,
  updateTermCondition,
  updatePrivacyPolicy,
  getSingalUserPost,
  LoginAdmin,
  getAllStoreList,
  postSingleReport,
  getAdminNotificationList,
} from "./AdminService";
import { getUser } from "./AdminService";

export const AdminSlice = createSlice({
  name: "counter",
  initialState: {
    token: "",
    isAuthenticated: false,
    isLoading: false,
    faType: "disabled",
    adminData: {},
    permissions: [],
    roundData: [],
    isClaimAirDrop: false,
    upcomingCurrentRoundData: [],
    userBalanceData: [],
    countryList: [],
    roleList: [],
    storeList: [],
    notificationList: [],
    singalUserData: [],
    singalReportData: [],
  },
  reducers: {
    getAdminData: (state, action) => {
      state.adminData = action.payload;
    },
    getAdminToken: (state, action) => {
      state.token = action.payload;
    },
    getAdminType: (state, action) => {
      state.adminType = action.payload;
    },
    getPermissions: (state, action) => {
      state.permissions = action.payload;
    },
    adminLogout: (state, action) => {
      state.token = "";
      state.isAuthenticated = false;
      state.adminData = {};
    },
    enabledFa: (state, action) => {
      state.faType = "enabled";
    },
    disabledFa: (state, action) => {
      state.faType = "disabled";
    },
    getRoundData: (state, action) => {
      state.roundData = action.payload;
    },
    claimAirDrop: (state, action) => {
      state.isClaimAirDrop = true;
    },
    SimpleRegister: (state, action) => {
      state.isClaimAirDrop = false;
    },
    upcomingCurrentRoundData: (state, action) => {
      state.upcomingCurrentRoundData = action.payload;
    },
    userBalanceData: (state, action) => {
      state.userBalanceData = action.payload;
    },
    setCountryList: (state, action) => {
      state.countryList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(LoginAdmin.fulfilled, (state, action) => {
      state.isAuthenticated = true;
    });
    builder.addCase(registerAdmin.fulfilled, (state, action) => {});
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.usersList.push(action.payload);
    });

    builder.addCase(getRoleList.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getRoleList.fulfilled, (state, action) => {
      state.roleList = action?.payload?.data;
      state.isLoading = false;
    });
    builder.addCase(getRoleList.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getAllStoreList.pending, (state, action) => {});
    builder.addCase(getAllStoreList.fulfilled, (state, action) => {
      state.storeList = action?.payload?.data;
    });
    builder.addCase(getAllStoreList.rejected, (state, action) => {});

    // get notification list
    builder.addCase(getAdminNotificationList.pending, (state, action) => {});
    builder.addCase(getAdminNotificationList.fulfilled, (state, action) => {
      state.notificationList = action?.payload?.data;
    });
    builder.addCase(getAdminNotificationList.rejected, (state, action) => {});

    //get all employee
    builder.addCase(getAllEmployee.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getAllEmployee.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(getAllEmployee.rejected, (state, action) => {
      state.isLoading = false;
    });

    //singal user post
    builder.addCase(getSingalUserPost.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getSingalUserPost.fulfilled, (state, action) => {
      state.isLoading = false;
      state.singalUserData = action?.payload?.data;
    });
    builder.addCase(getSingalUserPost.rejected, (state, action) => {});

    //singal user post
    //singal Report
    builder.addCase(postSingleReport.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(postSingleReport.fulfilled, (state, action) => {
      state.isLoading = false;
      state.singalReportData = action?.payload?.data;
    });
    builder.addCase(postSingleReport.rejected, (state, action) => {});

    //singal Report
    builder.addCase(getAllCategory.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getAllCategory.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(getAllCategory.rejected, (state, action) => {
      state.isLoading = false;
    });

    // Get all Supplier
    builder.addCase(getAllSupplier.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getAllSupplier.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(getAllSupplier.rejected, (state, action) => {
      state.isLoading = false;
    });

    // Get all CMS
    builder.addCase(getAllCMS.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getAllCMS.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(getAllCMS.rejected, (state, action) => {
      state.isLoading = false;
    });

    // Update about us
    builder.addCase(updateAboutUs.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateAboutUs.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(updateAboutUs.rejected, (state, action) => {
      state.isLoading = false;
    });

    // Update Term Condition
    builder.addCase(updateTermCondition.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateTermCondition.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(updateTermCondition.rejected, (state, action) => {
      state.isLoading = false;
    });

    // Update Privacy Policy
    builder.addCase(updatePrivacyPolicy.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updatePrivacyPolicy.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(updatePrivacyPolicy.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export const {
  getAdminData,
  getAdminToken,
  getAdminType,
  getPermissions,
  enabledFa,
  disabledFa,
  adminLogout,
  getRoundData,
  claimAirDrop,
  SimpleRegister,
  upcomingCurrentRoundData,
  userBalanceData,
  setCountryList,
} = AdminSlice.actions;

export default AdminSlice.reducer;
