import React from 'react'
import Index from "../../../../component/Index";
import PageIndex from "../../../PageIndex";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function AccordionNutrition({ data }) {
    const { breakfast, lunch, snacks, dinner } = data;
    console.log(breakfast, 'breakfast abc');

    const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Index.Box className="contdetail-box">
            <Index.Box className="contdetail-title" sx={{ mt: 3 }}>
                <Index.Typography
                    className="admin-page-title admin-sub-titles"
                    component="h6"
                    variant="h6"
                >
                    Nutrition Plan
                </Index.Typography>
            </Index.Box>
            <Index.Box className="cont-detail">
                <Index.Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <Index.AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        className="accordion-title"
                    >
                        <Index.Typography sx={{ width: '100%', flexShrink: 0, margin: 0 }}>
                            Breakfast
                        </Index.Typography>
                    </Index.AccordionSummary>
                    <Index.AccordionDetails>
                        <Index.Grid container spacing={3}>
                            {
                                breakfast.map((item) => (
                                    <Index.Grid item xs={12} md={6} lg={6} xl={6}>
                                        <Index.Box className="contdetail-box bg-border-box">
                                            <Index.Box className="cont-detail">
                                                <ul className="cont-detail-list">
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">Receipe Image</h5>
                                                        <p>:</p>
                                                        <img
                                                            src={
                                                                item?.recipeImage
                                                                    ? `${PageIndex.ImageURL}${item?.recipeImage}`
                                                                    : Index.Png.herobg
                                                            }
                                                            alt="loading..."
                                                            className="user-post-user-img"
                                                        />
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">Receipe Type</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.recipeType || "-"}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">Receipe Name</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.recipeName || "-"}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">Ingredients</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.ingredients || "-"}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">KCAL</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.kcalPerMeal || "-"}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">FATS</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.fatsPerMeal || "-"}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">Carbs</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.carbsPerMeal || "-"}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">Proteins</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.proteinsPerMeal || "-"}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">Diet Type</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.dietType || "-"}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">Goal for Recipe</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.goalForRecipe || "-"}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">Allergens</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.allergens || "-"}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">Diseases</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.diseases || "-"}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">Created Data</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.createdAt ? PageIndex.moment(item?.createdAt).format("MM/DD/YYYY") : "-"}
                                                        </p>
                                                    </li>
                                                </ul>
                                            </Index.Box>
                                        </Index.Box>
                                    </Index.Grid>
                                ))
                            }
                        </Index.Grid>
                    </Index.AccordionDetails>
                </Index.Accordion>
                <Index.Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <Index.AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                        className="accordion-title"
                    >
                        <Index.Typography sx={{ width: '100%', flexShrink: 0 }}>Lunch</Index.Typography>
                    </Index.AccordionSummary>
                    <Index.AccordionDetails>
                        <Index.Grid container spacing={3}>
                            {
                                lunch.map((item) => (
                                    <Index.Grid item xs={12} md={6} lg={6} xl={6}>
                                        <Index.Box className="contdetail-box bg-border-box">
                                            <Index.Box className="cont-detail">
                                                <ul className="cont-detail-list">
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">Receipe Image</h5>
                                                        <p>:</p>
                                                        <img
                                                            src={
                                                                item?.recipeImage
                                                                    ? `${PageIndex.ImageURL}${item?.recipeImage}`
                                                                    : Index.Png.herobg
                                                            }
                                                            alt="loading..."
                                                            className="user-post-user-img"
                                                        />
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">Receipe Type</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.recipeType || "-"}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">Receipe Name</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.recipeName || "-"}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">Ingredients</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.ingredients || "-"}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">KCAL</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.kcalPerMeal || "-"}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">FATS</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.fatsPerMeal || "-"}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">Carbs</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.carbsPerMeal || "-"}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">Proteins</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.proteinsPerMeal || "-"}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">Diet Type</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.dietType || "-"}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">Goal for Recipe</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.goalForRecipe || "-"}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">Allergens</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.allergens || "-"}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">Diseases</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.diseases || "-"}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">Created Data</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.createdAt ? PageIndex.moment(item?.createdAt).format("MM/DD/YYYY") : "-"}
                                                        </p>
                                                    </li>
                                                </ul>
                                            </Index.Box>
                                        </Index.Box>
                                    </Index.Grid>
                                ))
                            }
                        </Index.Grid>
                    </Index.AccordionDetails>
                </Index.Accordion>
                <Index.Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <Index.AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3bh-content"
                        id="panel3bh-header"
                        className="accordion-title"
                    >
                        <Index.Typography sx={{ width: '100%', flexShrink: 0 }}>
                            Snacks
                        </Index.Typography>
                    </Index.AccordionSummary>
                    <Index.AccordionDetails>
                        <Index.Grid container spacing={3}>
                            {
                                snacks.map((item) => (
                                    <Index.Grid item xs={12} md={6} lg={6} xl={6}>
                                        <Index.Box className="contdetail-box bg-border-box">
                                            <Index.Box className="cont-detail">
                                                <ul className="cont-detail-list">
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">Receipe Image</h5>
                                                        <p>:</p>
                                                        <img
                                                            src={
                                                                item?.recipeImage
                                                                    ? `${PageIndex.ImageURL}${item?.recipeImage}`
                                                                    : Index.Png.herobg
                                                            }
                                                            alt="loading..."
                                                            className="user-post-user-img"
                                                        />
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">Receipe Type</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.recipeType || "-"}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">Receipe Name</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.recipeName || "-"}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">Ingredients</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.ingredients || "-"}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">KCAL</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.kcalPerMeal || "-"}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">FATS</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.fatsPerMeal || "-"}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">Carbs</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.carbsPerMeal || "-"}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">Proteins</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.proteinsPerMeal || "-"}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">Diet Type</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.dietType || "-"}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">Goal for Recipe</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.goalForRecipe || "-"}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">Allergens</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.allergens || "-"}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">Diseases</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.diseases || "-"}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">Created Data</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.createdAt ? PageIndex.moment(item?.createdAt).format("MM/DD/YYYY") : "-"}
                                                        </p>
                                                    </li>
                                                </ul>
                                            </Index.Box>
                                        </Index.Box>
                                    </Index.Grid>
                                ))
                            }
                        </Index.Grid>
                    </Index.AccordionDetails>
                </Index.Accordion>
                <Index.Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <Index.AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                        className="accordion-title"
                    >
                        <Index.Typography sx={{ width: '100%', flexShrink: 0 }}>Dinner</Index.Typography>
                    </Index.AccordionSummary>
                    <Index.AccordionDetails>
                        <Index.Grid container spacing={3}>
                            {
                                dinner.map((item) => (
                                    <Index.Grid item xs={12} md={6} lg={6} xl={6}>
                                        <Index.Box className="contdetail-box bg-border-box">
                                            <Index.Box className="cont-detail">
                                                <ul className="cont-detail-list">
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">Receipe Image</h5>
                                                        <p>:</p>
                                                        <img
                                                            src={
                                                                item?.recipeImage
                                                                    ? `${PageIndex.ImageURL}${item?.recipeImage}`
                                                                    : Index.Png.herobg
                                                            }
                                                            alt="loading..."
                                                            className="user-post-user-img"
                                                        />
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">Receipe Type</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.recipeType || "-"}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">Receipe Name</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.recipeName || "-"}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">Ingredients</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.ingredients || "-"}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">KCAL</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.kcalPerMeal || "-"}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">FATS</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.fatsPerMeal || "-"}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">Carbs</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.carbsPerMeal || "-"}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">Proteins</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.proteinsPerMeal || "-"}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">Diet Type</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.dietType || "-"}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">Goal for Recipe</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.goalForRecipe || "-"}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">Allergens</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.allergens || "-"}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">Diseases</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.diseases || "-"}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h5 className="cont-detail-tag-name">Created Data</h5>
                                                        <p>:</p>
                                                        <p className="cont-detail-tag-name-detail">
                                                            {item?.createdAt ? PageIndex.moment(item?.createdAt).format("MM/DD/YYYY") : "-"}
                                                        </p>
                                                    </li>
                                                </ul>
                                            </Index.Box>
                                        </Index.Box>
                                    </Index.Grid>
                                ))
                            }
                        </Index.Grid>
                    </Index.AccordionDetails>
                </Index.Accordion>
            </Index.Box>
        </Index.Box>
    )
}
