import React from 'react'
import Index from "../../../../../../component/Index";
import ChallengsTable from '../../../challengs/ChallengsTable';
import PageIndex from '../../../../../PageIndex';

const ChallengeDetails = ({challengeDetails}) => {
  const navigate = PageIndex.useNavigate();
  const [currentRows, setCurrentRows] = PageIndex.useState([]);
  const [apiData, setApiData] = PageIndex.useState(false);
  const [singleChallangeData,setSingleChallangeData] = PageIndex.useState({})
  const [anchorEl, setAnchorEl] = PageIndex.useState(null);
  const open1 = Boolean(anchorEl);
  const handleActionOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };

  PageIndex.useEffect(()=>{
    if(challengeDetails?.length) {
      const timeOut = setTimeout(() => {
        setApiData(true);
      }, 500);
      return () => clearTimeout(timeOut);
    } else {
      const timeOut = setTimeout(() => {
        setApiData(true);
      }, 500);
      return () => clearTimeout(timeOut);
    }
  },[challengeDetails])

  if (!apiData) {
    return <Index.Loader />;
  }

  return (
    <ChallengsTable
    currentRows={currentRows}
    setCurrentRows={setCurrentRows}
    searchedData={challengeDetails}
    open1={open1}
    apiData={challengeDetails}
    viewSingleChallengeDetails={true}
    handleActionOpen={handleActionOpen}
    anchorEl={anchorEl}
    handleActionClose={handleActionClose}
    singleChallangeData={singleChallangeData}
    setSingleChallangeData={setSingleChallangeData}
    navigate={navigate}
  />
  )
}

export default ChallengeDetails