import React from "react";
import { postClientDetail } from "../../../../redux/features/slices/Admin/AdminService";
import ClientComponent from "./ClientComponent";
import Index from "../../../../component/Index";
import PageIndex from "../../../PageIndex";

const ClentListData = ({ trainerId }) => {
  const [users, setUsers] = PageIndex.useState([]);
  const [searchedData, setSearchedData] = PageIndex.useState([]);
  const [currentRows, setCurrentRows] = PageIndex.useState([]);
  const [apiData, setApiData] = PageIndex.useState(false);
  const dispatch = PageIndex.useDispatch();
  const location = PageIndex.useLocation();

  const requestSearch = (searched) => {
    if (!searched) return setSearchedData(users);
    setSearchedData(
      users.filter(
        (item) =>
          item?.clientId?.userName
            ?.toLowerCase()
            .includes(searched.toString().toLowerCase()) ||
          item?.clientId?.fullName
            ?.toLowerCase()
            .includes(searched.toString().toLowerCase()) ||
          item?.clientId?.phoneNumber
            ?.toString()
            .toLowerCase()
            .includes(searched.toString().toLowerCase())
      )
    );
  };
  const fetchData = async () => {
    const urlEncodedData = new URLSearchParams();
    urlEncodedData.append("id", trainerId ? trainerId : location?.state?._id);
    dispatch(postClientDetail(urlEncodedData)).then((response) => {
      const { status, data } = response?.payload || {};
      if (status === 200) {
        const dataWithCount = data?.map((item, index) => ({
          ...item,
          count: index + 1,
          key: index,
        }));
        setUsers(dataWithCount);
        setSearchedData(dataWithCount);
        setCurrentRows(dataWithCount);
        setApiData(true);
      } else {
        setApiData(true);
      }
    });
  };

  PageIndex.useEffect(() => {
    fetchData();
  }, []);
  
  if (!apiData) {
    return <Index.Loader />;
  }

  return (
    <div>
      <ClientComponent
        headingData=""
        userListReffelerTableClassName="user-list-referral-table page-table-main"
        requestSearch={requestSearch}
        currentRows={currentRows}
        setCurrentRows={setCurrentRows}
        searchedData={searchedData}
        setSearchedData={setSearchedData}
        apiData={apiData}
      />
    </div>
  );
};

export default ClentListData;
