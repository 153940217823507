import React from "react";
import Index from "../../../Index";

export default function Referral() {
  return (
    <>
      <Index.Box className="referral-details-main" id="refferal-program">
        <Index.Box className="title-referral-main">
          <Index.Box className="container">
            <Index.Box className="referral-main-title-box">
              <Index.Typography className="shadow-referral-title">
                referral program
              </Index.Typography>
              <Index.Typography
                className="small-referral-title"
                component="p"
                variant="p"
              >
                referral program
              </Index.Typography>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box className="referral-friend-content">
          <Index.Box className="container">
            <Index.Box className="grid-row joyful-row">
              <Index.Box sx={{ width: 1 }} className="grid-main referral-main">
                <Index.Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                  className="referral-grid"
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 7",
                    }}
                    className="grid-column referral-column"
                  >
                    <Index.Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                      className="referral-grid-inner"
                    >
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column referral-column-inner"
                      >
                        <Index.Box
                          display="grid"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                          className="referral-grid-inner"
                        >
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 12",
                              md: "span 12",
                              lg: "span 12",
                            }}
                            className="grid-column referral-column-inner"
                          >
                            <Index.Box className="referral-card-list-details">
                              <Index.List className="referral-box-ul">
                                <Index.ListItem className="referral-box-listitem">
                                  <Index.Box className="referral-content-details min-height-255px">
                                    <Index.Box className="flex-referral">
                                      <img
                                        src={Index.Svg.refrrelone}
                                        className="refrrel-icon"
                                        alt=""
                                      ></img>
                                      <Index.Box className="title-reffrel-program">
                                        <Index.Typography
                                          component="h6"
                                          variant="h6"
                                          className="title-pro-refreel"
                                        >
                                          Sign Up{" "}
                                        </Index.Typography>
                                        <Index.Typography
                                          component="h6"
                                          variant="h6"
                                          className="prices-pro-refreel"
                                        >
                                          10 Tokens{" "}
                                        </Index.Typography>
                                      </Index.Box>
                                    </Index.Box>
                                    <Index.List className="referral-details-list">
                                      <Index.ListItem className="referral-details-card">
                                        1- APP REGISTRATION & PROFILE COMPLETE
                                        10 TOKEN
                                      </Index.ListItem>
                                      <Index.ListItem className="referral-details-card">
                                        2- PER USER REGISTRATION REFERRAL BONUS
                                        10 TOKEN
                                      </Index.ListItem>
                                    </Index.List>
                                  </Index.Box>
                                </Index.ListItem>
                              </Index.List>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 12",
                              md: "span 12",
                              lg: "span 12",
                            }}
                            className="grid-column referral-column-inner"
                          >
                            <Index.Box className="referral-card-list-details">
                              <Index.List className="referral-box-ul">
                                <Index.ListItem className="referral-box-listitem ">
                                  <Index.Box className="referral-content-details min-height-255px">
                                    <Index.Box className="flex-referral">
                                      <img
                                        src={Index.Svg.refrrelthree}
                                        className="refrrel-icon"
                                        alt=""
                                      ></img>
                                      <Index.Box className="title-reffrel-program">
                                        <Index.Typography
                                          component="h6"
                                          variant="h6"
                                          className="title-pro-refreel"
                                        >
                                          Commission{" "}
                                        </Index.Typography>
                                        <Index.Typography
                                          component="h6"
                                          variant="h6"
                                          className="prices-pro-refreel"
                                        >
                                          5%{" "}
                                        </Index.Typography>
                                      </Index.Box>
                                    </Index.Box>
                                    <Index.List className="referral-details-list">
                                      <Index.ListItem className="referral-details-card">
                                        Coming soon...
                                      </Index.ListItem>

                                      {/* <Index.ListItem className='referral-details-card'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut</Index.ListItem> */}
                                    </Index.List>
                                  </Index.Box>
                                </Index.ListItem>
                              </Index.List>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column referral-column-inner"
                      >
                        <Index.Box className="referral-card-list-details">
                          <Index.List className="referral-box-ul">
                            <Index.ListItem className="referral-box-listitem">
                              <Index.Box className="referral-content-details min-height-625px">
                                <Index.Box className="flex-referral">
                                  <img
                                    src={Index.Svg.refrreltwo}
                                    className="refrrel-icon"
                                    alt=""
                                  ></img>
                                  <Index.Box className="title-reffrel-program">
                                    <Index.Typography
                                      component="h6"
                                      variant="h6"
                                      className="title-pro-refreel"
                                    >
                                      Referral
                                    </Index.Typography>
                                    <Index.Typography
                                      component="h6"
                                      variant="h6"
                                      className="prices-pro-refreel"
                                    >
                                      10 Tokens
                                    </Index.Typography>
                                  </Index.Box>
                                </Index.Box>
                                <Index.List className="referral-details-list">
                                  <Index.ListItem className="referral-details-card">
                                    1- 5000 OUTDOOR WALKING/RUNNING/JOGGING
                                    STEPS - 1 TOKEN = FOR STEPS WE WILL MONITOR
                                    STEPS + GEO LOCATION
                                  </Index.ListItem>
                                  <Index.ListItem className="referral-details-card">
                                    2- 500 CALORIES - 1 TOKEN = BUY ANY PHYSICAL
                                    ACTIVITY GYM/CARDIO/DANCE/ FREESTYLE SMART
                                    WATCHES OR HEALTH BANDS MANDATORY FOR DATA
                                    ANALYSIS
                                  </Index.ListItem>
                                  <Index.ListItem className="referral-details-card">
                                    3- DAILY MAX CAP LIMIT - 10000 STEPS FOR
                                    REGULAR ACCOUNT (EARNING LIMIT CAN BE
                                    INCREASED BY HOLDING TOKEN / BUYING PREMIUM
                                    MEMBERSHIP / BUYING NFTS)
                                  </Index.ListItem>
                                  <Index.ListItem className="referral-details-card">
                                    4- 1 DEVICE 1 ACCOUNT
                                  </Index.ListItem>
                                  <Index.ListItem className="referral-details-card">
                                    5- REFERRAL BONUS - 1% OF TOKEN EARNED
                                    THROUGH BY WALKING FROM YOUR COMMUNITY UP TO
                                    7 LEVEL
                                  </Index.ListItem>
                                </Index.List>
                              </Index.Box>
                            </Index.ListItem>
                          </Index.List>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 5",
                    }}
                    className="grid-column joyful-column"
                  >
                    <Index.Box className="refrral-friend-bg">
                      <img
                        src={Index.Png.refrralmobile}
                        className="refrral-mobile-img"
                        alt=""
                      ></img>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
