import React from "react";
import Index from "../../../../component/Index";
import PageIndex from "../../../PageIndex";
import { getTrainerTimingDetails } from "../../../../redux/features/slices/Admin/AdminService";

const TimingDetails = ({ trainerId }) => {
  const [timingData, setTimingdata] = PageIndex.useState([]);
  const [apiData, setApiData] = PageIndex.useState(false);
  const dispatch = PageIndex.useDispatch();
  const location = PageIndex.useLocation();

  const getTimingData = () => {
    dispatch(getTrainerTimingDetails(trainerId)).then((res) => {
      const { status, data } = res?.payload || {};
      if (status === 200) {
        let filteredData = data?.daysTiming.filter((day)=>day.isAvailable === true)
        setTimingdata(data?.daysTiming);
        setApiData(true);
      } else {
        setApiData(true);
      }
    });
  };

  PageIndex.useEffect(() => {
    getTimingData()
  }, [])

  if (!apiData) {
    return <Index.Loader />;
  }

  console.log(timingData,"timingData")

  return (
    <div>
      <Index.Box>
        <Index.Box sx={{ width: "100%" }} className="add-user-height">
          <Index.Box className="dashboard-content">
            <Index.Box className="user-list-flex">
              <Index.Typography className="admin-page-title user-list-page-title"></Index.Typography>
              {/* <Index.Box className="userlist-btn-flex">
                <Index.Box className="user-search-main">
                  <Index.Box className="user-search-box">
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        // onChange={(e) => requestSearch(e.target.value)}
                        placeholder="Search"
                      />

                      <img
                        src={Index.Svg.greysearch}
                        className="search-grey-img"
                        alt="search grey img"
                      ></img>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box> */}
            </Index.Box>
            <Index.Box className="admin-dashboard-list-row">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dash-box">
                      <Index.Box className="gymList-table-main page-table-main cus-page-table-main">
                        {/* page-table-main */}
                        <Index.TableContainer
                          component={Index.Paper}
                          className="table-container"
                        >
                          <Index.Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                            className="table-user-maindata"
                          >
                            <Index.TableHead className="table-head">
                              <Index.TableRow className="table-row">
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Day
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Morning start time
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Morning end time
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Evening start time
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Evening end time
                                </Index.TableCell>
                              </Index.TableRow>
                            </Index.TableHead>
                            <Index.TableBody className="table-body">
                              {timingData?.length && apiData ? (
                                timingData?.map((row, index) => (
                                  <Index.TableRow
                                    key={row?._id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.day ? row?.day : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.morningStartTime
                                        ? row?.morningStartTime
                                        : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.morningEndTime
                                        ? row?.morningEndTime
                                        : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.eveningStartTime
                                        ? row?.eveningStartTime
                                        : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.eveningEndTime
                                        ? row?.eveningEndTime
                                        : "-"}
                                    </Index.TableCell>
                                  </Index.TableRow>
                                ))
                              ) : (
                                <Index.TableRow>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="no-data-in-list"
                                    colSpan={5}
                                    align="center"
                                  >
                                    No data found
                                  </Index.TableCell>
                                </Index.TableRow>
                              )}
                            </Index.TableBody>
                          </Index.Table>
                        </Index.TableContainer>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default TimingDetails;
