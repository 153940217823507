import usericon from './images/png/user.png';
// import logo from './images/png/logo.png';
import edit from './images/png/edit.png';
import logout from './images/png/logout.png'
import setting from './images/png/setting.png'
import profile from './images/png/profile.png'
import footerplaystorescanner from './images/png/footer-playstore-scanner.png'
import lightLogo from './images/png/logo.png'
import herobg from '../assets/images/png/hero-bg.png'

import joyfulbg from './images/png/joyful-bg.png';
import roadmapbg from './images/png/roadmapbg.png';
import refrralmobile from './images/png/refrralmobile.png';
import footerbg from './images/png/footerbg.png';
import loginbg from './images/png/loginbg.png';
import signupbg from './images/png/signupbg.png';
import menuburger from './images/png/menu-burger.png';
import cancelicon from './images/png/cancel.png'
import orangestepright from './images/png/orange-step-right.png'
import whitestepright from './images/png/white-step-right.png'
import engagement from './images/png/engagement.png'
import dotrefferal from './images/png/dot-refferal.png'
import smalllogo from './images/png/small-logo.png'
import weighscale from './images/png/weigh-scale.png'
import uploadfile from './images/png/upload-file.png'
import loaderlogo from './images/png/loaderlogo.png'
import refresh from './images/png/refresh.png'
import close from './images/png/close.png'
import users from './images/png/users.png'
import notifyDummy from './images/png/notifyDummy.png'


const Png = {
      smalllogo,
      usericon,
      lightLogo,
      // logo,
      herobg,
      joyfulbg,
      roadmapbg,
      refrralmobile,
      footerbg,
      loginbg,
      signupbg,
      menuburger,
      edit,
      setting,
      logout,
      profile,
      footerplaystorescanner,
      cancelicon,
      orangestepright,
      whitestepright,
      engagement,
      dotrefferal,
      weighscale,
      uploadfile,
      loaderlogo,
      refresh,
      close,
      users,
      notifyDummy,
}

export default Png;