import React from "react";
import Index from "../../../../component/Index";
import { postProductDetail } from "../../../../redux/features/slices/Admin/AdminService";
import PageIndex from "../../../PageIndex";

const ProductList = ({ trainerId }) => {
  const [currentRows, setCurrentRows] = PageIndex.useState([]);
  const [searchedData, setSearchedData] = PageIndex.useState([]);
  // const [isFetching, setIsFetching] = PageIndex.useState(false);
  const [users, setUsers] = PageIndex.useState([]);
  const [apiData, setApiData] = PageIndex.useState(false);
  const dispatch = PageIndex.useDispatch();
  const location = PageIndex.useLocation();
  // Search on table
  const requestSearch = (searched) => {
    if (!searched) return setSearchedData(users);
    setSearchedData(
      users.filter(
        (item) =>
          item?.productName?.toLowerCase().includes(searched.toLowerCase()) ||
          item?.description?.toLowerCase().includes(searched.toLowerCase()) ||
          item?.available
            ?.toString()
            .toLowerCase()
            .includes(searched.toString().toLowerCase())
      )
    );
  };
  const fetchData = async () => {
    // setIsFetching(true);
    const urlEncodedData = new URLSearchParams();
    urlEncodedData.append("id", trainerId ? trainerId : location?.state?._id);
    dispatch(postProductDetail(urlEncodedData)).then((response) => {
      // setIsFetching(false);
      const { status, data } = response?.payload || {};
      if (status === 200) {
        const dataWithCount = data?.map((item, index) => ({
          ...item,
          count: index + 1,
          key: index,
        }));
        setUsers(dataWithCount);
        setSearchedData(dataWithCount);
        setCurrentRows(dataWithCount);
        setApiData(true);
      } else {
        setApiData(true);
      }
    });
  };

  PageIndex.useEffect(() => {
    fetchData();
  }, []);
  if (!apiData) {
    return <Index.Loader />;
  }
  return (
    <div>
      <Index.Box>
        <Index.Box sx={{ width: "100%" }} className="add-user-height">
          <Index.Box className="dashboard-content">
            <Index.Box className="user-list-flex">
              <Index.Typography
                className="admin-page-title user-list-page-title"
              // component="h2"
              // variant="h2"
              ></Index.Typography>
              <Index.Box className="userlist-btn-flex">
                <Index.Box className="user-search-main">
                  <Index.Box className="user-search-box">
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        onChange={(e) => requestSearch(e.target.value)}
                        placeholder="Search"
                      />

                      <img
                        src={Index.Svg.greysearch}
                        className="search-grey-img"
                        alt="search grey img"
                      ></img>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="admin-dashboard-list-row">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dash-box">
                      {/* <Index.Box className="productList-table-main page-table-main"> */}
                      <Index.Box className="productList-table-main page-table-main cus-page-table-main">
                        <Index.TableContainer
                          component={Index.Paper}
                          className="table-container"
                        >
                          <Index.Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                            className="table-user-maindata"
                          >
                            <Index.TableHead className="table-head">
                              <Index.TableRow className="table-row">
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Sr. no
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Product Image
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Product Name
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Description
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Qunatity
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Price
                                </Index.TableCell>
                              </Index.TableRow>
                            </Index.TableHead>
                            <Index.TableBody className="table-body">
                              {currentRows?.length && apiData ? (
                                currentRows?.map((row, index) => (
                                  <Index.TableRow
                                    key={row?._id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      <Index.Box className="userlist-data anchor-link">
                                        {row?.count ? row?.count : "-"}
                                      </Index.Box>
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      <img
                                        src={
                                          row?.image
                                            ? row?.image instanceof Blob
                                              ? URL.createObjectURL(row?.image)
                                              : `${PageIndex.ImageURL}${row?.image}`
                                            : Index?.Png?.herobg
                                        }
                                        alt="loading..."
                                        className="user-post-user-img"
                                      />
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.productName
                                        ? row?.productName
                                        : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.description
                                        ? row?.description
                                        : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.available ? row?.available : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                      align="center"
                                    >
                                      <span>{row.price}</span>
                                      <img
                                        src={
                                          row?.priceIcon
                                            ? row?.priceIcon instanceof Blob
                                              ? URL.createObjectURL(
                                                row?.priceIcon
                                              )
                                              : `${PageIndex.ImageURL}${row?.priceIcon}`
                                            : Index?.Png?.herobg
                                        }
                                        alt="loading..."
                                        className="trainer-product-crypto-img"
                                      />
                                    </Index.TableCell>
                                  </Index.TableRow>
                                ))
                              ) : (
                                <Index.TableRow>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="no-data-in-list"
                                    colSpan={6}
                                    align="center"
                                  >
                                    {!apiData ? (
                                      <PageIndex.Loader />
                                    ) : (
                                      "No data found"
                                    )}
                                  </Index.TableCell>
                                </Index.TableRow>
                              )}
                            </Index.TableBody>
                          </Index.Table>
                        </Index.TableContainer>
                      </Index.Box>
                      <Index.Box className="pagination-main">
                        <Index.Pagination
                          setCurrentRows={setCurrentRows}
                          allData={searchedData}
                          pageData={currentRows}
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default ProductList;
