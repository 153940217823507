import React from "react";
import Index from "../../../../component/Index";
import PageIndex from "../../../PageIndex";
// import { getNutritionTabPlans } from "../../../../redux/features/slices/Admin/AdminService";
import AccordionNutrition from "./AccordionNutrition";

const ViewSingleNutritionDetail = () => {
  //   const [nutritionDetails, setnutritionDetails] = PageIndex.useState({});
  //   const [apiData, setApiData] = PageIndex.useState(false);
  const { planId } = PageIndex.useParams();
  //   const dispatch = PageIndex.useDispatch();
  const navigate = PageIndex.useNavigate();
  const { state } = PageIndex.useLocation();

  console.log(planId, "Session ID", state);

  //   const getSingleNutritionDetails = () => {
  //     const urlEncodedData = new URLSearchParams();
  //     urlEncodedData.append("id", planId);
  //     dispatch(getNutritionTabPlans(urlEncodedData)).then((res) => {
  //       const { status, data } = res?.payload || {};
  //       if (status === 200) {
  //         setnutritionDetails(data[0]);
  //         setApiData(true);
  //       } else {
  //         setApiData(true);
  //       }
  //     });
  //   };

  const goBack = () => {
    navigate(-1);
  };

  //   PageIndex.useEffect(() => {
  //     getSingleNutritionDetails();
  //   }, [planId]);

  if (!state) {
    return <Index.Loader />;
  }

  return (
    <Index.Box className="dashboard-containt-main">
      <Index.Box className="dashboard-content add-user-containt">
        <Index.Box className="title-list-search">
          <Index.Box className="user-head-title user-title-row">
            <Index.Typography
              className="admin-page-title admin-sub-titles"
              component="h2"
              variant="h2"
            >
              Nutrition Details
            </Index.Typography>
          </Index.Box>

          <Index.Box className="save-btn-main singleNFt-back-btn-box">
            <Index.Button
              onClick={() => goBack()}
              className="save-user-btn primary-btn goal-btn"
              variant="contained"
            >
              <Index.KeyboardBackspaceIcon className="singleNFT-back-btn-icon" />
              Back
            </Index.Button>
          </Index.Box>
        </Index.Box>

        <Index.Box className="tabpanel-main">
          <Index.Box className="add-user-data-main goal-setup-css resp-p-0">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box className="single-group-container">
                <Index.Box className="siggle-group-flex">
                  <Index.Box className="single-group-leftbox">
                    <Index.Box className="signgle-data-main">
                      <Index.Box className="singlegroup-leftbox-groupdetail">
                        <Index.Grid container spacing={3}>
                          <Index.Grid item xs={12} md={4} lg={4} xl={4}>
                            <Index.Box className="contdetail-box">
                              <Index.Box className="contdetail-title">
                                <Index.Typography
                                  className="admin-page-title admin-sub-titles"
                                  component="h6"
                                  variant="h6"
                                >
                                  User Information
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="cont-detail">
                                <ul className="cont-detail-list">
                                  <li>
                                    <h5 className="cont-detail-tag-name">Username</h5>
                                    <p>:</p>
                                    <p className="cont-detail-tag-name-detail">
                                      {state?.userId?.userName || "-"}
                                    </p>
                                  </li>
                                  <li>
                                    <h5 className="cont-detail-tag-name">Name</h5>
                                    <p>:</p>
                                    <p className="cont-detail-tag-name-detail">
                                      {state?.userId?.fullName || "-"}
                                    </p>
                                  </li>
                                  <li>
                                    <h5 className="cont-detail-tag-name">Email</h5>
                                    <p>:</p>
                                    <p className="cont-detail-tag-name-detail">
                                      {state?.userId?.email || "-"}
                                    </p>
                                  </li>
                                  <li>
                                    <h5 className="cont-detail-tag-name">Phone Number</h5>
                                    <p>:</p>
                                    <p className="cont-detail-tag-name-detail">
                                      {state?.userId?.phoneNumber || "-"}
                                    </p>
                                  </li>
                                </ul>
                              </Index.Box>
                            </Index.Box>
                          </Index.Grid>
                          <Index.Grid item xs={12} md={4} lg={4} xl={4}>
                            <Index.Box className="contdetail-box">
                              <Index.Box className="contdetail-title">
                                <Index.Typography
                                  className="admin-page-title admin-sub-titles"
                                  component="h6"
                                  variant="h6"
                                >
                                  Trainer Information
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="cont-detail">
                                <ul className="cont-detail-list">
                                  <li>
                                    <h5 className="cont-detail-tag-name">Username</h5>
                                    <p>:</p>
                                    <p className="cont-detail-tag-name-detail">
                                      {state?.trainerId?.userName || "-"}
                                    </p>
                                  </li>
                                  <li>
                                    <h5 className="cont-detail-tag-name">Name</h5>
                                    <p>:</p>
                                    <p className="cont-detail-tag-name-detail">
                                      {state?.trainerId?.fullName || "-"}
                                    </p>
                                  </li>
                                  <li>
                                    <h5 className="cont-detail-tag-name">Email</h5>
                                    <p>:</p>
                                    <p className="cont-detail-tag-name-detail">
                                      {state?.trainerId?.email || "-"}
                                    </p>
                                  </li>
                                  <li>
                                    <h5 className="cont-detail-tag-name">Phone Number</h5>
                                    <p>:</p>
                                    <p className="cont-detail-tag-name-detail">
                                      {state?.trainerId?.phoneNumber || "-"}
                                    </p>
                                  </li>
                                </ul>
                              </Index.Box>
                            </Index.Box>
                          </Index.Grid>
                          <Index.Grid item xs={12} md={4} lg={4} xl={4}>
                            <Index.Box className="contdetail-box">
                              <Index.Box className="contdetail-title">
                                <Index.Typography
                                  className="admin-page-title admin-sub-titles"
                                  component="h6"
                                  variant="h6"
                                >
                                  Nutrition Plan Information
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="cont-detail">
                                <ul className="cont-detail-list">
                                  <li>
                                    <h5 className="cont-detail-tag-name">Plan Name</h5>
                                    <p>:</p>
                                    <p className="cont-detail-tag-name-detail">
                                      {state?.nutritionPlanName || "-"}
                                    </p>
                                  </li>
                                  <li>
                                    <h5 className="cont-detail-tag-name">Created Date</h5>
                                    <p>:</p>
                                    <p className="cont-detail-tag-name-detail">
                                      {state?.createdAt ? PageIndex.moment(state?.createdAt).format("MM/DD/YYYY") : "-"}
                                    </p>
                                  </li>
                                  <li>
                                    <h5 className="cont-detail-tag-name">Price</h5>
                                    <p>:</p>
                                    <p className="cont-detail-tag-name-detail">
                                      {state?.price || "-"}
                                    </p>
                                  </li>
                                  <li>
                                    <h5 className="cont-detail-tag-name">Phone Number</h5>
                                    <p>:</p>
                                    <p className="cont-detail-tag-name-detail">
                                      {state?.amount || "-"}
                                    </p>
                                  </li>
                                </ul>
                              </Index.Box>
                            </Index.Box>
                          </Index.Grid>
                        </Index.Grid>

                        <Index.Grid container spacing={3}>
                          <Index.Grid item xs={12} md={12}>
                            <Index.Box>
                              <AccordionNutrition data={state} />
                            </Index.Box>
                          </Index.Grid>
                        </Index.Grid>

                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>

                {/* <Index.Box className="siggle-group-flex">
                  <video width="100%" height="360" controls>
                    <source src={PageIndex.ImageURL + nutritionDetails?.video} type="video/mp4" />
                  </video>
                </Index.Box> */}
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
};

export default ViewSingleNutritionDetail;
