import React from "react";
import Index from "../Index";

export default function PrimaryButton(props) {
  const { className, onClick, disabled, type, btnLabel } = props;
  return (
    <>
      <Index.Box className="primary-btn-main-user">
        <Index.Button
          className={className}
          onClick={onClick}
          disabled={disabled}
          type={type}
          disableRipple
        >
          {btnLabel}
        </Index.Button>
      </Index.Box>
    </>
  );
}
