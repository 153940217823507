import dashboard from "./images/svg/dashboard.svg";
import notification from "./images/svg/notification.svg";
import settings from "./images/svg/settings.svg";
import userlist from "./images/svg/user-list.svg";
import editpage from "./images/svg/edit-page.svg";
import changepassword from "./images/svg/change-password.svg";
import profile from "./images/svg/profile.svg";
import dashicon1 from "./images/svg/dash1.svg";
import dashicon2 from "./images/svg/dash2.svg";
import dashicon3 from "./images/svg/dash3.svg";
import dashicon4 from "./images/svg/dash4.svg";
import save from "./images/svg/save.svg";
import edit from "./images/svg/edit.svg";
import instagram from "./images/svg/instagram.svg";
import share from "./images/svg/share.svg";
import twitter from "./images/svg/twitter.svg";
import ncxbluelogo from "./images/svg/ncx-blue-logo.svg";
import ncxlogo from "./images/svg/ncx-logo.svg";
import footerinstagram from "./images/svg/footer-instagram.svg";
import footershare from "./images/svg/footer-share.svg";
import footertwitter from "./images/svg/footer-twitter.svg";
import blueerror from "./images/svg/blue-error.svg";
import rederror from "./images/svg/red-error.svg";
import ncxlogoicon from "./images/svg/ncx-logo-icon.svg";
import greyright from "./images/svg/grey-right.svg";
import greencheck from "./images/svg/green-check-mark.svg";
import yellowerror from "./images/svg/yellow-error.svg";
import threedots from "./images/svg/three-dots.svg";
import greycheck from "./images/svg/grey-check.svg";
import usd from "./images/svg/usd.svg";
import primaryerror from "./images/svg/primary-error.svg";
import walletscanner from "./images/svg/wallet-scanner.svg";
import copy from "./images/svg/copy.svg";
import plus from "./images/svg/plus.svg";
import settingicon from "./images/svg/setting.svg";
import greysearch from "./images/svg/search.svg";
import token1 from "./images/svg/token1.svg";
import token2 from "./images/svg/token2.svg";
import mobilemenu from "./images/svg/mobile-menu.svg";
import whitedown from "./images/svg/whitedown.svg";
import light from "./images/svg/light.svg";
import play from "./images/svg/play.svg";
import utility1 from "./images/svg/utility1.svg";
import rewards1 from "./images/svg/rewards1.svg";
import rewards2 from "./images/svg/rewards2.svg";
import rewards3 from "./images/svg/rewards3.svg";
import squre from "./images/svg/squre.svg";
import blueright from "./images/svg/blue-right.svg";
import ncxwhitelogo from "./images/svg/ncxwhitelogo.svg";
import checkround from "./images/svg/check-round.svg";
import minusround from "./images/svg/minus-round.svg";
import utility2 from "./images/svg/utility2.svg";
import utility3 from "./images/svg/utility3.svg";
import utility4 from "./images/svg/utility4.svg";
import utility5 from "./images/svg/utility5.svg";
import utility6 from "./images/svg/utility6.svg";
import utility7 from "./images/svg/utility7.svg";
import utility8 from "./images/svg/utility8.svg";
import utility9 from "./images/svg/utility9.svg";
import utility10 from "./images/svg/utility10.svg";
import utility11 from "./images/svg/utility11.svg";
import questionmarkicon from "./images/svg/questionmark.svg";
import blueleftthin from "./images/svg/blue-left-thin.svg";
import bluerightthin from "./images/svg/blue-right-thin.svg";
import yellowwatch from "./images/svg/yellow-watch.svg";
import apple from "./images/svg/apple.svg";
import playstore from "./images/svg/playstore.svg";
import footerplaystore from "./images/svg/footer-playstore.svg";
import footerapplestore from "./images/svg/footer-applestore.svg";
import whiterighterrow from "./images/svg/white-right-errow.svg";
import close from "./images/svg/close.svg";
import referrals from "./images/svg/referrals.svg";
import roadmap from "./images/svg/roadmap.svg";
import presaleround from "./images/svg/presale.svg";
import whiteclose from "./images/svg/close-white.svg";
import usericon from "./images/svg/user.svg";
import tokenarrow from "./images/svg/token-arrow.svg";
import listingprice from "./images/svg/listing-price.svg";
import ncxprimarylogoicon from "./images/svg/ncx-primary-logo-icon.svg";
import deposite from "./images/svg/deposite.svg";
import withdrawal from "./images/svg/withdrawal.svg";
import thindownarrow from "./images/svg/thin-down-arrow.svg";
import closeusermodal from "./images/svg/close-user-modal.svg";

import logo from "./images/svg/logo.svg";
import appstore from "./images/svg/app-store.svg";
import refrrelone from "./images/svg/refrrel-one.svg";
import refrreltwo from "./images/svg/refrrel-two.svg";
import refrrelthree from "./images/svg/refrrel-three.svg";
import reffrralbg from "./images/svg/reffrral-bg.svg";
import footerline from "./images/svg/footer-line.svg";
import pin from "./images/svg/pin.svg";
import call from "./images/svg/call.svg";
import facebook from "./images/svg/facebook.svg";
import linkedin from "./images/svg/linkedin.svg";
import roadrounded from "./images/svg/road-rounded.svg";
import roadmapbottom from "./images/svg/roadmap-bottom.svg";
import earning from "./images/svg/earning.svg";
import friends from "./images/svg/friends.svg";
import treadmill from "./images/svg/treadmill.svg";
import coach from "./images/svg/coach.svg";
import shield from "./images/svg/shield.svg";
import healthcare from "./images/svg/healthcare.svg";
import saving from "./images/svg/saving.svg";
import customerjourney from "./images/svg/customer-journey.svg";
import maintainweight from "./images/svg/maintain-weight.svg";
import gainweight from "./images/svg/gain-weight.svg";
import gainmuscle from "./images/svg/gain-muscle.svg";
import increaseenergy from "./images/svg/increase-energy.svg";
import fitactive from "./images/svg/fit-active.svg";
import reducestress from "./images/svg/reduce-stress.svg";
import bluefacebook from "./images/svg/bluefacebook.svg";
import blueinstagram from "./images/svg/blueinstagram.svg";
import bluelinkdin from "./images/svg/bluelinkdin.svg";
import bluetwitter from "./images/svg/bluetwitter.svg";
import google from "./images/svg/google.svg";
// import telegram from "./images/svg/telegram.svg";
// import weighingmachine from "./images/svg/weighing-machine.svg";

// import anorexia from "./images/svg/anorexia.svg";
// import track from "./images/svg/track.svg";
import certificate from "./images/svg/certificate.svg";

const Svg = {
  logo,
  roadmapbottom,
  appstore,
  refrrelone,
  refrreltwo,
  refrrelthree,
  reffrralbg,
  footerline,
  pin,
  call,
  facebook,
  linkedin,
  twitter,
  roadrounded,
  dashboard,
  notification,
  settings,
  changepassword,
  editpage,
  userlist,
  profile,
  dashicon1,
  dashicon2,
  dashicon3,
  dashicon4,
  save,
  edit,
  instagram,
  share,
  ncxbluelogo,
  ncxlogo,
  footerinstagram,
  footershare,
  footertwitter,
  blueerror,
  rederror,
  ncxlogoicon,
  greyright,
  yellowerror,
  greencheck,
  threedots,
  greycheck,
  usd,
  primaryerror,
  walletscanner,
  copy,
  plus,
  settingicon,
  greysearch,
  token1,
  token2,
  mobilemenu,
  whitedown,
  light,
  play,
  utility1,
  rewards1,
  rewards2,
  rewards3,
  squre,
  blueright,
  ncxwhitelogo,
  checkround,
  minusround,
  utility2,
  utility3,
  utility4,
  utility5,
  utility6,
  utility7,
  utility8,
  utility9,
  utility10,
  utility11,
  questionmarkicon,
  blueleftthin,
  bluerightthin,
  yellowwatch,
  playstore,
  apple,
  footerplaystore,
  footerapplestore,
  whiterighterrow,
  close,
  referrals,
  roadmap,
  presaleround,
  whiteclose,
  usericon,
  tokenarrow,
  listingprice,
  ncxprimarylogoicon,
  withdrawal,
  deposite,
  thindownarrow,
  earning,
  friends,
  treadmill,
  coach,
  shield,
  healthcare,
  saving,
  customerjourney,
  maintainweight,
  gainweight,
  gainmuscle,
  increaseenergy,
  fitactive,
  reducestress,
  bluefacebook,
  blueinstagram,
  bluelinkdin,
  bluetwitter,
  google,
  // telegram,
  // weighingmachine,
  // anorexia,
  // track,
  certificate,
  closeusermodal,
};

export default Svg;
