const API = {
  Admin: {
    ADMIN_LOGIN: "admin/login",
    FORGOT_PASSWORD: "admin/forgot-password",
    RESET_PASSWORD: "admin/reset-password",
    RESEND_OTP_RESET_PASSWORD: "admin/adminresendOtp",
    GET_USER_LIST: "admin/getuser-list",
    GET_SINGLE_USER_INFO: "admin/fetch-personal-info",
    USER_REGISTER: "user/register",
    USER_ADD_EDIT: "admin/add-edit-user",
    USER_REMOVE: "admin/remove-user",
    CHANGE_PASSWORD: "/admin/change-password",
    EDIT_PROFILE: "/admin/edit-profile",
    PROFILE_DATA: "/admin/get-adminProfile",
    // COUNTRY_LIST:"/admin/get-country-list",
    COUNTRY_LIST: " user/countries",
    ROADMAP_LIST: "/admin/roadmap-list",
    ROAD_ADD_EDIT: "admin/add-edit-roadmap",
    REMOVE_ROAD_LIST: "admin/delete-roadmap",
    GET_REFERRED_SIGNUP_USERS: "/admin/getuser-list-singedup-reffreal-code",
    ADD_EDIT_GENERAL_SETTING: "/admin/add-editGenralSetting",
    GET_GENERAL_SETTING: "/admin/get-GeneralSetting",
    GET_USER_EDIT_DATA: "admin/update-userByAdmin",
    ADD_EDIT_USER_WALLET: "admin/edit-wallet",
    GET_ALL_TRANSACTION: "admin/Transactions",
    GET_WALLET: "admin/get-wallet",
    GET_ALL_USER_COUNT: "common/get-AllUsers",
    GET_CONTACT_REQUEST: "admin/contact-list",
    ADD_WAREHOUSE: "admin/addWarehouse",
    GET_ALL_WAREHOUSE: "admin/getAllWarehouse",
    GET_SINGLE_WAREHOUSE: "admin/getSingleWarehouse",
    EDIT_WAREHOUSE: "admin/editWarehouseDetails",
    DELETE_WAREHOUSE: "admin/deleteWarehouse",
    GET_STATE_BY_COUNTRY: "admin/allStates",
    GET_CITY_BY_STATE: "admin/allCities",
    SET_ACTIVE_DEACTIVE_WAREHOUSE: "admin/activeOrDeactiveStoreWarehouse",
    GET_ALL_STORE: "admin/getAllStore",
    GET_ADMIN_NOTIFICATIONS: "admin/get-admin-notification-list",
    GET_SINGLE_STORE: "admin/getSingleStore",
    ADD_STORE: "admin/addStore",
    DELETE_STORE: "admin/deletestore",
    EDIT_STORE: "admin/editStoreDetails",
    STORE_ACTIVATE_DEACTIVATE: "admin/activeOrDeactiveStore",
    GET_ROLE_LIST: "admin/allRole",
    GET_STORE_LIST: "admin/getAllStore",
    ADD_EMPLOYEE: "admin/addEmployee",
    EDIT_EMPLOYEE: "admin/editEmployee",
    GET_SINGLE_EMPLOYEE: "admin/singleEmployees",
    GET_ALL_EMPLOYEE: "admin/allEmployees",
    GET_ALL_STAKE_LIST: "get-allStackDurationList",
    SET_ACTIVE_DEACTIVE_EMPLOYEE: "admin/activeOrDeactiveEmployee",
    DELETE_EMPLOYEE: "admin/deleteEmployee",
    DELETE_STAKE: "admin-deleteSingleStackDuration",
    ADD_ROLE: "admin/addRole",
    EDIT_ROLE: "admin/editRole",
    GET_SINGLE_ROLE: "admin/singleRole",
    SET_ACTIVE_DEACTIVE_ROLE: "admin/activeOrDeactive",
    DELETE_ROLE: "admin/deleteRole",
    ADD_CATEGORY: "admin/addCategory",
    GET_ALL_CATEGORY: "admin/getAllCategory",
    DELETE_CATEGORY: "admin/deleteCategory",
    EDIT_CATEGORY: "admin/editCategory",
    GET_SINGLE_CATEGORY: "admin/getSingleCategory",
    SET_ACTIVE_DEACTIVE_CATEGORY: "admin/activeAndDeactiveCategory",
    ADD_BRAND: "/Brand/addBrand",
    GET_ALL_BRAND: "/Brand/getAllBrand",
    DELETE_BRAND: "/Brand/deleteBrand",
    EDIT_BRAND: "/Brand/editBrand",
    GET_SINGLE_BRAND: "/Brand/getSingleBrand",
    SET_ACTIVE_DEACTIVE_BRAND: "/Brand/activeAndDeactiveBrand",
    GET_ALL_SUBCATEGORY: "admin/getAllSubCategory",
    ADD_SUB_CATEGORY: "admin/addSubCategory",
    GET_SINGLE_SUB_CATEGORY: "admin/getSingleSubCategory",
    DELETE_SUB_CATEGORY: "admin/deleteSubCategory",
    EDIT_SUB_CATEGORY: "/admin/editSubCategory",
    ACTIVE_DEACTIVE_SUB_CATEGORY: "/admin/activeAndDeactiveSubCategory",
    GET_ALL_PRODUCTS: "Product/getAllproduct",
    GET_WAREHOUSE_PRODUCTS_STOCK: "admin/get-all-warehouse-stock",
    GET_WAREHOUSE_PRODUCTS_STOCK_HISTORY: "admin/get-single-warehouse-stock",
    UPDATE_WAREHOUSE_PRODUCTS_STOCK: "/warehouse/addWarehouseHistor",
    ADD_PRODUCT: "Product/addProduct",
    ACTIVE_DEACTIVE_PRODUCT: "Product/activeAndDeactiveproduct",
    DELETE_PRODUCT: "/Product/deleteproduct",
    GET_SINGLE_PRODUCT: "Product/getSingleproduct",
    EDIT_PRODUCT: "Product/editproduct",
    GET_ALL_SUPPLIER: "supplier/getAllSupplier",
    ADD_SUPPLIER: "supplier/addSupplier",
    EDIT_SUPPLIER: "supplier/editSupplier",
    GET_SINGLE_SUPPLIER: "supplier/getSingleSupplier",
    SET_ACTIVE_DEACTIVE_SUPPLIER: "supplier/activeAndDeactiveSupplier",
    DELETE_SUPPLIER: "supplier/deleteSupplier",
    GET_ALL_CMS: "admin/cms",
    UPDATE_ABOUT_US: "admin/aboutUs",
    UPDATE_TERM_CONDITION: "admin/termsAndCondition",
    UPDATE_PRIVACY_POLICY: "admin/privacyPolicy",
    ADD_SET_GOEL: "admin/create-goals",
    ADD_NFT_Data: "admin-addSetupNft",
    ADD_EDIT_NFT: "admin-saveNftData",
    EDIT_SINGLE_NFT: "admin-singleNftSetupUpdated",
    ADD_SINGLE_NFT_STACK: "admin-addStackDuration",
    EDIT_SINGLE_NFT_STACK: "admin-singleStackDurationUpdated",
    GET_GOALS: "/admin/get-goals",
    GET_NFT_LIST: "/get-allSetupNftsList",
    GET_GOALS_ACTIVE: "/admin/goals-active-de-active",
    GET_SET_GOAL_USER: "/get-setgoalusers",
    GET_USER_POST: "/admin/get-all-feed",
    GET_POST_ACTIVE_DEACTIVE: "/admin/enable-disable-feed",
    GET_USER_ACTIVE_DEACTIVE: "/admin/active-deactive-user",
    DELETE_USER_POST: "/admin/delete-feed",
    DELETE_NFT_DATA: "/admin-deleteSingleSetupNft",
    POST_SINGAL_USER_POST: "/admin/single-user-allFeed",
    GET_ALLREPORT: "/admin/getAllReports",
    GET_USER_ALL_REPORT: "/admin/getAllReports",
    POST_ALL_REPORT_SPECIFIC_POST: "/admin/getAllReportsOfSpecificPost",
    POST_SINGLE_REPORT: "/admin/getSingleReport",
    GET_SINGLE_USER_REPORT: "/admin/singleUser-allReport",
    GET_ALL_GROUP: "/admin-getAllGroup",
    POST_SINGLE_GROUP: "/admin-get-singleGroup",
    DELETE_GROUP: "/admin-get-deleteGroup",
    ADMIN_GET_ALL_CHALLENGES: "admin-get-allChallenges",
    ADMIN_GET_TRAINER_LIST: "/admin/getuser-list",
    POST_CLIENT_LIST_DATA: "/comman-getAllClientOfSingleUser",
    GET_CENTER_LIST_DATA: "/get-health-center-by-trainer",
    POST_PROD_LIST_DATA: "/comman-getallProductOfSingleUser",
    CREATE_USER_BY_ADMIN:"/create-user-by-admin",
    GET_SINGLE_USER_DETAILS:"/admin/get-single-user-details",
    ADD_ADMIN_CHALLENGE: "user-trainer-createChallenges",
    GET_ORDER_LIST:"/admin/get-all-order-list",
    GET_SINGLE_ORDER_DETAILS:"/admin/get-single-order-details",
    GET_TICKET_LIST: "/admin/get-all-ticket-list",
    UPDATE_TICKET_STATUS:"/admin/update-ticket-status",
    GET_ALL_COUPONS: "admin/get-all-coupon",
    ADD_COUPON: "admin/add-edit-coupon",
    GET_PRODUCTS_FOR_COMBO_OFFER:"/admin/get-all-product-for-combo-offer",
    DELETE_COUPON: "admin/delete-coupon",
    ACTIVATE_DEACTIVATE_COUPON: "admin/active-inactive-coupon",
    UPDATE_ADMIN_CHALLANGE:"user-trainer-updateChallenges",
    DELETE_CHALLANGE:"user-trainer-deleteChallenge",
    GET_POST_COMMENTS:"user-trainer-getAllComments",
    GET_POST_SUB_COMMENTS:"user-trainer-subComments",
    GET_NFT_LIST_WITHOUT_PAGINATION: "get-admin-nft-without-pagination",
    GET_NFT_TRANSACTION_LIST:"admin/get-nft-transactions-list",
    // GET_SINGLE_CHALLENGE_DETAILS:"admin-get-single-challenge",
    GET_SINGLE_CHALLENGE_DETAILS:"user-trainer-singleChallenge",
    GET_ORDER_RATING_LIST:"admin/get-order-rating-list",
    GET_PRODUCT_RATING_LIST:"admin/get-product-rating-list",
    DELETE_ORDER_RATING:"admin/delete-order-rating",
    GET_APPOINTMENT_LIST:"/user/get-all-trainer-appointment-list",
    COUPON_ORDER_LIST:"/admin/get-orders-by-couponId",
    SESSION_LIST:"/trainer/get-trainer-session-list",
    APPROVE_REJECT_SESSION:"/admin/approve-reject-session",
    GET_SUB_SESSION_LIST:"/trainer/get-sub-session-list",
    APPROVE_REJECT_SUB_SESSION:"/admin/approve-reject-sub-session",
    GET_SINGLE_SESSION_DETAILS:"/trainer/get-single-session-details",
    GET_TRAINER_TIMING_DETAILS:"/trainer/get-all-times",
    GET_SUBSCRIBED_SESSION_LIST:"/admin/get-all-subscribed-session-list",
    ADD_EDIT_NUTRITION:"/admin/add-edit-receipe-plan",
    GET_ALL_NUTRITION_PLAN:"/admin/get-all-receipe-plans",
    DELETE_NUTRATION_PLAN:"/admin/delete-receipe-plan",
    CHALLENGE_STEPS_DETAILS:"/user/get-challenge-steps-details",
    ADD_SET_FAQ: "admin/add-edit-faq",
    GET_FAQ: "admin/get-all-faq",
    DELETE_FAQ: "admin/delete-faq",
    GET_APPOINTMENT:"/common/get-all-appointments-of-single-user",
    GET_NUTRITION_LIST_PLAN:"/common/get-all-nutrition-plan-of-single-user",
    GET_WORKOUT_PLAN:"/common/get-all-workout-plan-of-single-user",
    GET_NUTRITION_LIST:"/admin/get-nutrition-plan-list",
    GET_WORKOUT_LIST:"/admin/get-workout-plan-list",
    GET_ALL_ORDER_TICKET_LIST:"/admin/get-all-order-tickets",
    SEND_TICKET_REPLY:"/admin/send-order-ticket-reply",
    GET_TRAINER_CREATED_PRODUCT:"/Product/get-all-trainer-product-for-admin",
    APPROVE_REJECT_TRAINER_PRODUCT:"/Product/approve-reject-product",
    ADD_WAREHOUSE_STOCK:"/admin/add-warehouse-stock",
    TRANSFER_WAREHOUSE_STOCK:"/admin/trasnfer-warehouse-stock",
    ADD_CHALLENGE_WINNER_REWARDS:"user-trainer-add-winner-rewards-details",
    GET_SINGLE_NFT_TRANSACTIONS:"/admin/get-single-nft-transactions-list",
    REMOVE_POST_PARENT_COMMENT: "user-trainer-commentDelete",
    REMOVE_POST_CHILD_COMMENT: "user-trainer-commentDelete",
    ADD_NFT_REPAIR_DATA: "admin/add-nft-repair",
    GET_NFT_REPAIR_LIST: "admin/get-all-nft-repair",
    DELETE_NFT_REPAIR_DATA: "admin/delete-nft-repair",
    BUY_PRESALE_TOKEN:"/buy-presale-token",
    GET_ALL_PRESALE_TOKEN_LIST:"/get-presale-buy-token-list",
    STAKE_PRESALE_TOKEN:"/stake-presale-token",
    GET_STAKE_PRESALE_TOKEN:"/get-stake-presale-token-list",
    GET_LEASE_NFT_LIST:"/admin/get-all-lease-nft",
    GET_SINGLE_LEASE_NFT_HISTORY:"/admin/get-lease-nft-history-by-id",
    POST_READ_UNREAD_NOTIFICATION: "admin/read-unread-notification",
    GET_REPAIR_NFT_REQUEST_LIST: "admin/get-user-repair-nft-list",
    GET_UPDATE_WINNER_DECLARATION_REQUEST: "/update-winner-declaration-status",
    GET_WAREHOUSE_STOCK_REPORT:"/admin/get-warehouse-stock-report",
    GET_ORDER_SALES_REPORT:"/admin/get-order-sales-report",
    GET_SUB_ADMINS:"/admin/get-sub-admin-list",
    ADD_SUB_ADMIN:"/admin/add-sub-admin",
    GET_ALL_DAILY_ACTIVE_USERS: "admin/get-daily-active-users-list",
    GET_ALL_MONTHLY_ACTIVE_USERS: "admin/get-monthly-active-users-list",
    ADD_SUB_ADMIN:"/admin/add-edit-sub-admin",
    DELETE_SUB_ADMIN:"/admin/delete-sub-admin",
    ASSIGN_SUB_ADMIN:"/admin/assign-ticket-to-sub-admin",
    POST_TRAINER_REVIEW_DATA: "/admin/get-trainer-rating-list",
    GET_CENTER_TYPES: "/admin/get-all-center-type",
    ADD_CENTER_TYPE_DATA: "/admin/add-edit-center-type",
    DELETE_CENTER_TYPE_DATA: "/admin/delete-center-type",
    GET_AMENITIES_LIST: "/admin/get-all-amenities",
    ADD_EDIT_AMENITIES:"/admin/add-edit-amenities",
    DELETE_AMENITIES: "admin/delete-amenities",
    GET_EQUIPMENT_LIST: "/admin/get-all-equipment",
    ADD_EDIT_EQUIPMENT:"/admin/add-edit-equipment",
    DELETE_EQUIPMENT: "admin/delete-equipment",
    GET_SERVICE_LIST: "/admin/get-all-service",
    ADD_EDIT_SERVICE:"/admin/add-edit-service",
    DELETE_SERVICE: "admin/delete-service",
    GET_ALL_SPONSORED_POST: "/admin/get-all-sponsored-post",
    ADD_SPONSORED_POST: "admin/sponsored-post",
    GET_CENTER_REVIEWS_LIST: "/get-health-center-rating-list",
    DELETE_CENTER_COMMENT: "admin/delete-health-center-rating",
    GET_CENTER_RATING_LIST:"admin/get-all-health-center-rating-list",
    GET_TRAINER_RATING_LIST:"admin/get-all-trainer-rating-list",
    DELETE_TRAINER_COMMENT: "admin/delete-trainer-rating",
    GET_HEALTH_CHALLENGE_LIST: "/admin/get-all-health-challenge",
    DELETE_HEALTH_CHALLENGE: "admin/delete-health-challenge",
    ADD_EDIT_HEALTH_CHALLENGE:"/admin/add-edit-health-challenge",
    GET_FITNESS_CHALLENGE_LIST: "/admin/get-all-fitness-challenge",
    ADD_EDIT_FITNESS_CHALLENGE:"/admin/add-edit-fitness-challenge",
    DELETE_FITNESS_CHALLENGE: "admin/delete-fitness-challenge",
    CANCEL_ORDER_POST: "/user/cancel-order",
    POST_CHANGE_ORDER_STATUS: "/change-order-status",
  },
  User: {
    REGISTER_USER: "user/register",
    EMAIL_VERIFY: "user/email-verification",
    USER_FORGOT_PASSWORD: "user/forgot-password",
    RESET_PASSWORD: "user/reset-password",
    USER_LOGIN: "user/login",
    CONTACT_US: "/user/contactus",
    COUNTRY_LIST: "user/countries",
    MY_REFERRED_USERS: "user/getreffereduser",
    EMAIL_VERIFICATION: "user/email-verification",
    GET_USER_PROFILE: "/user/profile",
    UPDATE_USER_PROFILE: "/user/update-profile",
    CHECK_USERNAME_EXIST: "/user/check_auto_genrate_username",
    SOCIAL_LOGIN: "/user/social_login",
    FORGOT_PASSWORD: "/user/forgot-password",
    RESEND_OTP_RESET_PASSWORD: "user/resend-Otp",
    RESEND_OTP: "user/resend-Otp",
    WALLET_HISTORY: "user/all-Transactions",
  },
};
export { API };
