import React from "react";
import Index from "../../../../../../component/Index";
import PageIndex from "../../../../../PageIndex";
import TableUserPost from "../../../posts/TableUserPost";

const SocialDetails = ({ feedsDetails }) => {
  const [posts, setPosts] = PageIndex.useState(feedsDetails);
  const [totalPages, setTotalPages] = PageIndex.useState(0);
  const [page, setPage] = PageIndex.useState(1);
  const [currentRows, setCurrentRows] = PageIndex.useState([]);
  const [apiData, setApiData] = PageIndex.useState(false);
  const [searchedData, setSearchedData] = PageIndex.useState(feedsDetails);

  const requestSearch = (searched) => {
    if (searched === "") {
      setCurrentRows(posts);
      setSearchedData(posts);
      return;
    }
    let filterdata = posts.filter((item) => {
      let duration = item?.createdBy?.fullName
        ?.toString()
        .toLowerCase()
        .includes(searched.toLowerCase());
      let likesLength = item?.likes?.length
        .toString()
        .toLowerCase()
        .includes(searched.toString().toLowerCase());
      let descriptionSearch = item?.description
        ?.toLowerCase()
        .includes(searched.toLowerCase());
      let dateSearch = PageIndex.moment(item?.createdAt)
        .format("MM/DD/YYYY")
        ?.toString()
        .toLowerCase()
        .includes(searched.toLowerCase());

      return duration || likesLength || descriptionSearch || dateSearch;
    });
    setCurrentRows(filterdata);
    setSearchedData(filterdata);
  };
  const handleChange = (event, newValue) => {
    setPage(newValue);
  };

  PageIndex.useEffect(() => {
    if (feedsDetails?.length) {
      const timeOut = setTimeout(() => {
        setApiData(true);
      }, 500);
      return () => clearTimeout(timeOut);
    } else {
      const timeOut = setTimeout(() => {
        setApiData(true);
      }, 500);
      return () => clearTimeout(timeOut);
    }
  }, [feedsDetails]);

  if (!apiData) {
    return <Index.Loader />;
  }

  return (
    <Index.Box>
      <TableUserPost
        title="User Post"
        postTitle="Post Image"
        viewTitle="View"
        apiData={feedsDetails}
        optionalLikeLengthHeadClassName="likes-th"
        optionalLikeLengthDetailClassName="table-td token-tab likes-td"
        userNametabHeadClassName="table-th"
        userNametabDetailClassName="table-td"
        optionalLikeLength="Total Likes"
        optionalLikeLengthAlignCss="left"
        userNameClassName="table-td min-max-tab user-name"
        searchBoxClassName="user-search-box"
        postImageheaderClassName="table-th"
        postImageClassName="table-td"
        deleteBtnClassName="menu-option-li"
        threedotModalWidthCss="100px"
        backendPaginationBoxCss="user-post-pagination-box"
        frontEndPaginationBoxCss="optional-frontend-pagination"
        page={page}
        handleChange={handleChange}
        totalPages={totalPages}
        requestSearch={requestSearch}
        currentRows={currentRows}
        setCurrentRows={setCurrentRows}
        searchedData={searchedData}
        className="posts-data"
        viewSinglePostDetails={true}
      />
    </Index.Box>
  );
};

export default SocialDetails;
