import React, { useState } from "react";
import Index from "../../../../component/Index";
import PageIndex from "../../../PageIndex";
import PropTypes from "prop-types";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const BootstrapDialog = Index.styled(Index.Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <Index.DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <Index.IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Index.CloseIcon />
        </Index.IconButton>
      ) : null}
    </Index.DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ChallengsTable = (props) => {
  const [open, setOpen] = PageIndex.useState(false);
  const [openTicketDescription, setOpenTicketDescription] = useState(false);
  const [ticketDescription, setTicketDescription] = useState("");
  const [ticketHeading, setTicketHeading] = useState("");
  const [imageUrl, setImageUrl] = PageIndex.useState(null);

  const {
    currentRows,
    setCurrentRows,
    searchedData,
    open1,
    anchorEl,
    handleActionClose,
    ITEM_HEIGHT,
    apiData,
    viewSingleChallengeDetails,
    handleActionOpen,
    singleChallangeData,
    setSingleChallangeData,
    navigate,
    onOpenModal,
    hasActionPermission,
    role,
    permissions
  } = props;

  console.log(props, "props props", singleChallangeData);

  const handleClickOpen = (url) => {
    setImageUrl(url);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setImageUrl("");
  };

  const handleClickOpenDescription = (heading, description) => {
    setOpenTicketDescription(true);
    setTicketHeading(heading);
    setTicketDescription(description);
  };

  const handleCloseDescription = () => {
    setOpenTicketDescription(false);
    setTicketHeading("");
    setTicketDescription("");
  };

  if (!apiData) {
    return <Index.Loader />;
  }

  return (
    <Index.Box className="admin-dashboard-list-row admin-setup-goal-row">
      <Index.Box sx={{ width: 1 }} className="grid-main">
        <Index.Box
          display="grid"
          className="display-row"
          gridTemplateColumns="repeat(12, 1fr)"
          gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
        >
          <Index.Box
            gridColumn={{
              xs: "span 12",
              sm: "span 12",
              md: "span 12",
              lg: "span 12",
            }}
            className="grid-column"
          >
            <Index.Box className="admin-dash-box">
              <Index.Box className="contactrequest-table-main page-table-main">
                <Index.TableContainer
                  component={Index.Paper}
                  className="table-container table-head-sticky"
                  sx={{ maxHeight: 405 }}
                >
                  <Index.Table
                    aria-label="simple table"
                    className="table-user-maindata"
                  >
                    <Index.TableHead className="table-head">
                      <Index.TableRow className="table-row">
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                        >
                          No.
                        </Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                        >
                          Image
                        </Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                        >
                          Type
                        </Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                        >
                          Challenge <br /> Name
                        </Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                        >
                          Challenge <br /> Type
                        </Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                        >
                          Rules
                        </Index.TableCell>

                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                        >
                          Number Of <br /> Participants
                        </Index.TableCell>

                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                        >
                          Duration
                        </Index.TableCell>

                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                        >
                          Steps/Distance
                        </Index.TableCell>

                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                        >
                          Start Date
                        </Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                        >
                          Start Time
                        </Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                        >
                          End Date
                        </Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                        >
                          End Time
                        </Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                        >
                          Created By
                        </Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                        >
                          Minimum <br /> Participant
                        </Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                        >
                          Maximum <br /> Participant
                        </Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                        >
                          Join User
                        </Index.TableCell>
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                        >
                          Fees
                        </Index.TableCell>
                        {/* {!viewSingleChallengeDetails && ( */}
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                        >
                          Action
                        </Index.TableCell>
                        {/* )} */}
                      </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody className="table-body">
                      {currentRows?.length && apiData ? (
                        currentRows?.map((row, index) => {
                          const {
                            countsequence,
                            image,
                            Rules,
                            StartTime,
                            startDate,
                            challengeTitle,
                            challengeType,
                            joinUsers,
                            endDate,
                            endTime,
                            max,
                            min,
                            type,
                            _id,
                            fees,
                            minimumParticipant,
                            duration,
                            target
                          } = row;
                          return (
                            <Index.TableRow key={_id}>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                                align="center"
                              >
                                <Index.Box className="anchor-link">
                                  {viewSingleChallengeDetails
                                    ? index + 1
                                    : countsequence}
                                </Index.Box>
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                                style={{cursor:"pointer"}}
                                onClick={() =>
                                  handleClickOpen(
                                    image ? image : Index.Png.herobg
                                  )
                                }
                              >
                                <img
                                  src={
                                    image
                                      ? image instanceof Blob
                                        ? URL.createObjectURL(image)
                                        : `${PageIndex.ImageURL}${image}`
                                      : Index.Png.herobg
                                  }
                                  alt="loading..."
                                  className="user-post-user-img"
                                />
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                                style={{cursor:"pointer"}}
                                onClick={() => {
                                  navigate(
                                    `/admin/challenges/view-challenge-details/${_id}`
                                  );
                                }}
                              >
                                {type
                                  ? type.slice(0, 16).length > 15
                                    ? type.slice(0, 16) + "..."
                                    : type.slice(0, 16)
                                  : "-"}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                                align="left"
                                style={{cursor:"pointer"}}
                                onClick={() => {
                                  navigate(
                                    `/admin/challenges/view-challenge-details/${_id}`
                                  );
                                }}
                              >
                                {challengeTitle}
                              </Index.TableCell>

                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                                align="left"
                              >
                                {challengeType}
                              </Index.TableCell>

                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                                style={{cursor:"pointer"}}
                                onClick={() =>
                                  handleClickOpenDescription(
                                    "Rules",
                                    Rules ? Rules : "-"
                                  )
                                }
                              >
                                {Rules
                                  ? Rules.slice(0, 16).length > 15
                                    ? Rules.slice(0, 16) + "..."
                                    : Rules.slice(0, 16)
                                  : "-"}
                              </Index.TableCell>

                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                                align="left"
                              >
                                {joinUsers.length ? joinUsers.length : 0}
                              </Index.TableCell>

                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td token-tab"
                              >
                                {duration}
                              </Index.TableCell>

                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td token-tab"
                              >
                                {target}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td token-tab"
                              >
                                {startDate
                                  ? PageIndex.moment(startDate).format(
                                      "MM/DD/YYYY"
                                    )
                                  : "-"}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td token-tab"
                              >
                                {StartTime}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td token-tab"
                              >
                                {endDate
                                  ? PageIndex.moment(endDate).format(
                                      "MM/DD/YYYY"
                                    )
                                  : "-"}
                              </Index.TableCell>

                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td token-tab"
                              >
                                {endTime}
                              </Index.TableCell>

                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td token-tab"
                              >
                                {row?.createdBy?.fullName
                                  ? row?.createdBy?.fullName
                                  : "Admin"}
                              </Index.TableCell>

                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td token-tab"
                              >
                                {minimumParticipant
                                  ? minimumParticipant
                                  : "-"}
                              </Index.TableCell>

                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td token-tab"
                              >
                                {row?.maximumParticipant
                                  ? row?.maximumParticipant
                                  : "-"}
                              </Index.TableCell>

                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td token-tab"
                                align="center"
                              >
                                {row?.joinUsers?.length}
                              </Index.TableCell>

                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                                align="center"
                              >
                                {fees}
                              </Index.TableCell>

                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td action-tab"
                              >
                                <Index.IconButton
                                  aria-label="more"
                                  id="long-button"
                                  aria-haspopup="true"
                                  onClick={(event) => {
                                    handleActionOpen(event);
                                    setSingleChallangeData(
                                      currentRows?.[index]
                                    );
                                  }}
                                >
                                  <Index.MoreVertIcon />
                                </Index.IconButton>
                              </Index.TableCell>
                              {/* )} */}
                            </Index.TableRow>
                          );
                        })
                      ) : (
                        <Index.TableRow>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="no-data-in-list"
                            colSpan={13}
                            align="center"
                          >
                            {!apiData ? <PageIndex.Loader /> : "No Data Found"}
                          </Index.TableCell>
                        </Index.TableRow>
                      )}
                    </Index.TableBody>
                  </Index.Table>
                </Index.TableContainer>
                <Index.Box className="admin-menu-box">
                  <Index.Menu
                    className="admin-menu-main"
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorEl}
                    open={open1}
                    onClose={handleActionClose}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: "100px",
                        boxShadow: "none",
                        border: "1px solid rgba(224, 224, 224, 1)",
                        marginTop: "6px",
                      },
                    }}
                  >
                    <Index.MenuItem
                      key={`view_`}
                      onClick={handleActionClose}
                      className="menu-option-li"
                    >
                      <Index.Button
                        variant="contained"
                        onClick={() => {
                          navigate(
                            `/admin/challenges/view-challenge-details/${singleChallangeData?._id}`
                          );
                        }}
                      >
                        View
                      </Index.Button>
                    </Index.MenuItem>

                    {!viewSingleChallengeDetails && (
                      <>
                        {/* <Index.MenuItem
                          key={`edit_`}
                          onClick={handleActionClose}
                          className="menu-option-li"
                        >
                          <Index.Button
                            variant="contained"
                            onClick={() => {
                              navigate(
                                `/admin/challenges/edit-challenge/${singleChallangeData?._id}`,
                                {
                                  state: { singleChallangeData },
                                }
                              );
                            }}
                          >
                            Edit
                          </Index.Button>
                        </Index.MenuItem> */}

                        {hasActionPermission(role, permissions, "challengesList", "Delete") && ( // Check Delete permission
                          <Index.MenuItem
                            key={`delete_`}
                            onClick={handleActionClose}
                            className="menu-option-li"
                          >
                            <Index.Button
                              variant="contained"
                              onClick={onOpenModal}
                            >
                              Delete
                            </Index.Button>
                          </Index.MenuItem>
                        )}
                      </>
                    )}
                  </Index.Menu>
                </Index.Box>
              </Index.Box>
              <Index.Pagination
                setCurrentRows={setCurrentRows}
                allData={searchedData}
                pageData={currentRows}
              />
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      {/* Show Single Image in popup Dialog */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Image
        </BootstrapDialogTitle>
        <Index.DialogContent dividers>
          <Index.Typography gutterBottom>
            <img
              style={{ width: "100%" }}
              src={PageIndex.ImageURL + imageUrl}
              alt=""
            />
          </Index.Typography>
        </Index.DialogContent>
      </BootstrapDialog>
      {/* Dialog */}

      {/* Rule description show modal start */}
      <Index.Modal
        open={openTicketDescription}
        onClose={handleCloseDescription}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="common-modal"
      >
        <Index.Box
          sx={style}
          className="common-modal-inner add-edit-goal-inner"
        >
          <Index.Box className="comman-modal-body fix-height-modal">
            <Index.Box className="input-box add-user-input ">
              <Index.Box className="form-group custom-group">
                <Index.FormHelperText className="form-lable">
                  {ticketHeading}
                </Index.FormHelperText>
                <Index.Box className="drop-main-comman">
                  <Index.FormHelperText>
                    {ticketDescription}
                  </Index.FormHelperText>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="comman-modal-footer comman-btn-main">
            <button onClick={handleCloseDescription} className="primary-btn">
              Cancel
            </button>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
      {/* Rule description modal end */}
    </Index.Box>
  );
};

export default ChallengsTable;
