import React, { useState } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Index from "../../../Index";
import moment from "moment";

export default function DataPicker() {
  const [dateValue, setDateValue] = useState("");

  return (
    <>
      <Index.Box className="datepicker-wrap">
        <Index.LocalizationProvider
          dateAdapter={AdapterMoment}
          className="datepicker-wrap-inner"
        >
          <Index.DatePicker
            value={dateValue}
            onChange={(val) => {
              setDateValue(moment(val._d));
            }}
            renderInput={(props) => (
              <Index.TextField
                {...props}
                size="small"
                helperText={null}
                className="date-text-list"
              />
            )}
            className="mob-date-picker date-picker-details"
          />
        </Index.LocalizationProvider>
      </Index.Box>
    </>
  );
}
