import React from "react";
import Index from "../../container/Index";

const StatusModal = (props) => {
  const style = {
    position: "absolute",
    // pending:50,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
  };
  return (
    <Index.Modal
      open={props.modalOpen}
      onClose={props.onCloseActiveModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    //   className="modal-delete modal"
    >
      <Index.Box sx={style} className="delete-modal-inner-main modal-inner">
        <Index.Typography
          className="delete-modal-title"
          component="h2"
          variant="h2"
          style={{ fontFamily: "Poppins-Medium", fontSize: 17, padding: 20 }}
        >
          {props.title}
        </Index.Typography>
        <Index.Box className="update-status-modal-btn-flex">
          <Index.Button
            className="modal-cancel-btn"
            style={{
              color: "white",
              backgroundColor: "#00235f",
              fontFamily: "system-ui",
              fontSize: 14,
              lineHeight: "18px",
              borderRadius: "40px",
              minWidth: "95px",
              minHeight: "36px",
              textTransform: "capitalize",
              cursor: "pointer",
            }}
            onClick={props.onOpenModal}
          >
            Yes
          </Index.Button>
          <Index.Button
            className="modal-delete-btn"
            style={{
              color: "white",
              backgroundColor: "red",
              fontFamily: "system-ui",
              fontSize: 14,
              lineHeight: "18px",
              borderRadius: "40px",
              minWidth: "95px",
              minHeight: "36px",
              textTransform: "capitalize",
              cursor: "pointer",
            }}
            onClick={props.onCloseModal}
          >
            No
          </Index.Button>
        </Index.Box>
      </Index.Box>
    </Index.Modal>
  );
};

export default StatusModal;
