import React from "react";
import Index from "../../../../component/Index";
import PageIndex from "../../../PageIndex";

const ClientComponent = (props) => {
  const { requestSearch, currentRows, setCurrentRows, searchedData, apiData } =
    props;
  return (
    <div>
      <Index.Box className="add-user-data-main">
        <Index.Box sx={{ width: "100%" }} className="add-user-height">
          <Index.Box className="dashboard-content">
            <Index.Box className="user-list-flex">
              <Index.Typography
                className="admin-page-title user-list-page-title"
                // component="h2"
                // variant="h2"
              >
                {props.headingData}
              </Index.Typography>
              <Index.Box className="userlist-btn-flex">
                <Index.Box className="user-search-main">
                  <Index.Box className="user-search-box">
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        onChange={(e) => requestSearch(e.target.value)}
                        placeholder="Search"
                      />

                      <img
                        src={Index.Svg.greysearch}
                        className="search-grey-img"
                        alt="search grey img"
                      ></img>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="admin-dashboard-list-row">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dash-box">
                      <Index.Box
                        className={props.userListReffelerTableClassName}
                      >
                        <Index.TableContainer
                          component={Index.Paper}
                          className="table-container"
                        >
                          <Index.Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                            className="table-user-maindata"
                          >
                            <Index.TableHead className="table-head">
                              <Index.TableRow className="table-row">
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Sr. no
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Client Image
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Client Name
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Client User Name
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Number
                                </Index.TableCell>
                              </Index.TableRow>
                            </Index.TableHead>
                            <Index.TableBody className="table-body">
                              {currentRows?.length && apiData ? (
                                currentRows?.map((row, index) => (
                                  <Index.TableRow
                                    key={row?._id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      <Index.Box className="userlist-data anchor-link">
                                        {row.count}
                                      </Index.Box>
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      <img
                                        src={
                                          row?.clientId?.profileImg
                                            ? row?.clientId
                                                ?.profileImg instanceof Blob
                                              ? URL.createObjectURL(
                                                  row?.clientId?.profileImg
                                                )
                                              : `${PageIndex.ImageURL}${row?.clientId?.profileImg}`
                                            : Index?.Png?.herobg
                                        }
                                        alt="loading..."
                                        className="user-post-user-img"
                                      />
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row.clientId.fullName}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row.clientId.userName}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row.clientId.phoneNumber}
                                    </Index.TableCell>
                                  </Index.TableRow>
                                ))
                              ) : (
                                <Index.TableRow>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="no-data-in-list"
                                    colSpan={6}
                                    align="center"
                                  >
                                    {!apiData ? (
                                      <PageIndex.Loader />
                                    ) : (
                                      "No Data Found"
                                    )}
                                  </Index.TableCell>
                                </Index.TableRow>
                              )}
                            </Index.TableBody>
                          </Index.Table>
                        </Index.TableContainer>
                      </Index.Box>
                      <Index.Box className="pagination-main">
                        <Index.Pagination
                          setCurrentRows={setCurrentRows}
                          allData={searchedData}
                          pageData={currentRows}
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default ClientComponent;
