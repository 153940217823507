import React, { useState } from "react";
import Index from "../../Index";

import { useNavigate } from "react-router-dom";
import Scrollspy from "react-scrollspy";
import { Link } from "@mui/material";

export default function Header() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  return (
    <>
      <Index.Box
        onClick={() => {
          document.body.classList[!open ? "add" : "remove"]("header-override");
          setOpen((e) => !e);
        }}
        class={`overlay-section mobile-show ${
          open ? "overlay-mobile-active" : ""
        }`}
        id="overlays"
      >
        {" "}
      </Index.Box>

      <Index.Box className="header-main-details">
        <Index.Box className="container">
          <Index.Box className="header-main-pd">
            <Index.Box className="comman-flex-justify header-flex-content">
              <Index.Box className="logo-main-left">
                <Index.Link to="/" className="redirect-home-link">
                  <img
                    src={Index.Svg.logo}
                    className="logo-header"
                    alt="logo-header"
                  ></img>
                </Index.Link>
              </Index.Box>
              <Index.Box className="center-list-header">
                <Scrollspy
                  items={[
                    "section-home",
                    "joyful-investment",
                    "section-roadmap",
                    "refferal-program",
                  ]}
                  currentClassName="is-current"
                >
                  <Index.List
                    className={`header-ul-list ${
                      open ? "header-left-active" : ""
                    }`}
                  >
                    <Index.ListItem className="header-listitem-li">
                      <Link
                        className="header-redirect-link"
                        href="#section-home"
                      >
                        <Index.Typography component="p" variant="p">
                          Home
                        </Index.Typography>
                      </Link>
                    </Index.ListItem>
                    <Index.ListItem className="header-listitem-li">
                      <Link
                        className="header-redirect-link"
                        href="#joyful-investment"
                      >
                        <Index.Typography component="p" variant="p">
                          About us
                        </Index.Typography>
                      </Link>
                    </Index.ListItem>
                    <Index.ListItem className="header-listitem-li">
                      <Link
                        className="header-redirect-link"
                        href="#section-roadmap"
                      >
                        <Index.Typography component="p" variant="p">
                          Roadmap
                        </Index.Typography>
                      </Link>
                    </Index.ListItem>
                    <Index.ListItem className="header-listitem-li">
                      <Link
                        className="header-redirect-link"
                        href="#refferal-program"
                      >
                        <Index.Typography component="p" variant="p">
                          Referral Program
                        </Index.Typography>
                      </Link>
                    </Index.ListItem>
                    <Index.ListItem className="header-listitem-li">
                      <a
                        href={Index.Pdf.whitePaperPdf}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="remove-underline"
                      >
                        <Link className="header-redirect-link">
                          <Index.Typography component="p" variant="p">
                            Whitepaper
                          </Index.Typography>
                        </Link>
                      </a>
                    </Index.ListItem>
                  </Index.List>
                </Scrollspy>
              </Index.Box>
              <Index.Box className="right-btn-list flex-btn">
                <Index.Box className="btn-login-details">
                  {/* <Index.Link to={"/user/login"} className="signin-btn-main primary-btn-main-link-user"> */}
                  <Index.PrimaryButton
                    btnLabel="Login"
                    className="comman-primary-btn"
                    onClick={() => navigate("/user/login")}
                  />

                  {/* </Index.Link> */}
                </Index.Box>
                <Index.Box className="burgur-icon-main">
                  <Index.OutlineButton
                    onClick={() => {
                      document.body.classList[!open ? "add" : "remove"](
                        "header-override"
                      );
                      setOpen((e) => !e);
                    }}
                    imgSrc={Index.Png.menuburger}
                    className="outline-btn-list"
                  ></Index.OutlineButton>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
