import React from "react";
import Index from "../../../../component/Index";
import PageIndex from "../../../PageIndex";
import AdminTableHeading from "../../../../component/common/AdminTableHeading";
import {
  approveRejectSession,
  getAllTrainerSessionList,
} from "../../../../redux/features/slices/Admin/AdminService";

const SessionTabList = ({ trainerId, type }) => {
  const [currentRows, setCurrentRows] = PageIndex.useState([]);
  const [searchedData, setSearchedData] = PageIndex.useState([]);
  const [sessionData, setSessionData] = PageIndex.useState([]);
  const [apiData, setApiData] = PageIndex.useState(false);
  const [sessionId, setSessionId] = PageIndex.useState({
    session_id: "",
    status: "",
  });
  const [anchorEl, setAnchorEl] = PageIndex.useState(null);
  const open = Boolean(anchorEl);
  const dispatch = PageIndex.useDispatch();
  const navigate = PageIndex.useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getSessionList = async () => {
    const urlEncodedData = new URLSearchParams();
    urlEncodedData.append("trainerId", trainerId);
    urlEncodedData.append("type", type);
    dispatch(getAllTrainerSessionList(urlEncodedData)).then((res) => {
      const { status, data } = res?.payload || {};
      if (status === 200) {
        const dataWithCount = data?.map((item, index) => ({
          ...item,
          count: index + 1,
          key: index,
        }));

        setSessionData(dataWithCount);
        setSearchedData(dataWithCount);
        setCurrentRows(dataWithCount);
        setApiData(true);
      } else {
        setApiData(true);
      }
    });
  };

  const updateSessionStatus = (status) => {
    const data = {
      id: sessionId?.session_id,
      status,
    };

    dispatch(approveRejectSession(data)).then((res) => {
      const { status, data } = res?.payload || {};
      if (status === 200) {
        getSessionList();
      }
    });
  };

  PageIndex.useEffect(() => {
    getSessionList();
  }, []);

  const requestSearch = (searched) => {
    if (!searched)
      return setSearchedData(
        sessionData?.map((item, index) => {
          return {
            ...item,
            count: index + 1,
            key: index,
          };
        })
      );

    let filterData = sessionData?.filter(
      (item) =>
        item?.name?.toLowerCase().includes(searched.toLowerCase()) ||
        item?.description
          ?.toString()
          .toLowerCase()
          .includes(searched.toLowerCase()) ||
        item?.type?.toString().toLowerCase().includes(searched.toLowerCase()) ||
        item?.createdBy?.fullName
          ?.toString()
          .toLowerCase()
          .includes(searched.toLowerCase()) ||
        item?.isApproved
          ?.toString()
          .toLowerCase()
          .includes(searched.toLowerCase()) ||
        item?.amount?.toString().toLowerCase().includes(searched.toLowerCase())
    );

    const datawithCount = filterData?.map((item, index) => {
      return {
        ...item,
        count: index + 1,
        key: index,
      };
    });
    setSearchedData(datawithCount);
  };

  if (!apiData) {
    return <Index.Loader />;
  }

  return (
    <>
      <PageIndex.DynamicTitle title="Fitrizer - Session List" />
      <Index.Box className="dashboard-content add-user-containt">
        <Index.Box className="add-user-data-main goal-setup-css list-cont">
          <Index.Box sx={{ width: "100%" }} className="add-user-height">
            <Index.Box className="dashboard-content">
              <Index.Box className="admin-dashboard-list-row admin-setup-goal-row">
                <Index.Box sx={{ width: 1 }} className="grid-main">
                  <Index.Box
                    display="grid"
                    className="display-row"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-dash-box">
                        <Index.Box className="nft-setup-table page-table-main">
                          <Index.TableContainer
                            component={Index.Paper}
                            className="table-container table-head-sticky"
                            sx={{ maxHeight: 405 }}
                          >
                            <Index.Table
                              aria-label="simple table"
                              className="table"
                            >
                              <Index.TableHead className="table-head">
                                <Index.TableRow className="table-row">
                                  <Index.TableCell
                                    component="th"
                                    variant="th"
                                    className="table-th"
                                  >
                                    No
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="th"
                                    variant="th"
                                    className="table-th"
                                  >
                                    Name
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="th"
                                    variant="th"
                                    className="table-th"
                                  >
                                    Image
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="th"
                                    variant="th"
                                    className="table-th"
                                  >
                                    Type
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="th"
                                    variant="th"
                                    className="table-th"
                                  >
                                    Description
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="th"
                                    variant="th"
                                    className="table-th"
                                  >
                                    Created By
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="th"
                                    variant="th"
                                    className="table-th"
                                  >
                                    Amount
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="th"
                                    variant="th"
                                    className="table-th"
                                  >
                                    Status
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="th"
                                    variant="th"
                                    className="table-th"
                                  >
                                    Action
                                  </Index.TableCell>

                                  {/* <Index.TableCell
                                    component="th"
                                    variant="th"
                                    className="table-th"
                                  >
                                    Action
                                  </Index.TableCell> */}
                                </Index.TableRow>
                              </Index.TableHead>
                              <Index.TableBody className="table-body">
                                {currentRows?.length && apiData ? (
                                  currentRows?.map((row) => {
                                    const {
                                      count,
                                      name,
                                      image,
                                      createdBy,
                                      description,
                                      isApproved,
                                      type,
                                      amount,
                                      _id,
                                    } = row;
                                    return (
                                      <Index.TableRow key={_id}>
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          scope="row"
                                          className="table-td"
                                        >
                                          {count || "-"}
                                        </Index.TableCell>
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          scope="row"
                                          className="table-td"
                                        >
                                          {name || "-"}
                                        </Index.TableCell>
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          className="table-td"
                                        >
                                          <img
                                            src={
                                              image
                                                ? `${PageIndex.ImageURL}${row?.image}`
                                                : Index.Png.herobg
                                            }
                                            alt="loading..."
                                            className="user-post-user-img"
                                          />
                                        </Index.TableCell>
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          className="table-td"
                                        >
                                          {type ? type : "-"}
                                        </Index.TableCell>
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          className="table-td"
                                          align="left"
                                        >
                                          {description ? description : "-"}
                                        </Index.TableCell>
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          className="table-td"
                                        >
                                          {createdBy?.fullName
                                            ? createdBy?.fullName
                                            : "-"}
                                        </Index.TableCell>

                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          className="table-td"
                                        >
                                          {amount ? amount : "-"}
                                        </Index.TableCell>
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          className="table-td token-tab"
                                        >
                                          <Index.Typography
                                            variant="p"
                                            color={
                                              isApproved === "Pending"
                                                ? "Highlight"
                                                : isApproved === "Approved"
                                                  ? "green"
                                                  : "red"
                                            }
                                          >
                                            {isApproved ? isApproved : "-"}
                                          </Index.Typography>
                                        </Index.TableCell>
                                        <Index.TableCell component="td" variant="td" className="table-td">
                                          <Index.Box className="action-btn-icon" sx={{ display: "flex" }}>
                                            <Index.Tooltip title="View details">
                                              <Index.Button
                                                className="admin-menu-btn"
                                                variant="contained"
                                                onClick={() => {
                                                  navigate(
                                                    `/admin/session-details/${row?._id}`
                                                  );
                                                }}
                                              >
                                                <Index.Visibility />
                                              </Index.Button>
                                            </Index.Tooltip>
                                          </Index.Box>
                                        </Index.TableCell>
                                      </Index.TableRow>
                                    );
                                  })
                                ) : (
                                  <Index.TableRow>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="no-data-in-list"
                                      colSpan={6}
                                      align="center"
                                    >
                                      No data found
                                    </Index.TableCell>
                                  </Index.TableRow>
                                )}
                              </Index.TableBody>
                            </Index.Table>
                          </Index.TableContainer>
                        </Index.Box>

                        {/* <Index.Box className="admin-menu-box">
                          <Index.Menu
                            className="admin-menu-main"
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            {sessionId?.status !== "Approved" ? (
                              <Index.MenuItem
                                onClick={handleClose}
                                className="menu-option-li"
                              >
                                <Index.Button
                                  className="admin-menu-btn"
                                  variant="contained"
                                  onClick={() => {
                                    updateSessionStatus("Approved");
                                  }}
                                >
                                  Approve
                                </Index.Button>
                              </Index.MenuItem>
                            ) : null}

                            {sessionId?.status !== "Rejected" ? (
                              <Index.MenuItem
                                onClick={handleClose}
                                className="menu-option-li"
                              >
                                <Index.Button
                                  className="admin-menu-btn"
                                  variant="contained"
                                  onClick={() => {
                                    updateSessionStatus("Rejected");
                                  }}
                                >
                                  Reject
                                </Index.Button>
                              </Index.MenuItem>
                            ) : null}

                            <Index.MenuItem
                              key={`edit_`}
                              onClick={handleClose}
                              className="menu-option-li"
                            >
                              <Index.Button
                                className="admin-menu-btn"
                                variant="contained"
                                onClick={() => {
                                  navigate(
                                    `/admin/session-details/${sessionId?.session_id}`
                                  );
                                }}
                              >
                                View Details
                              </Index.Button>
                            </Index.MenuItem>
                            <Index.MenuItem
                              onClick={handleClose}
                              className="menu-option-li"
                            >
                              <Index.Button
                                className="admin-menu-btn"
                                variant="contained"
                                onClick={() => {
                                  navigate(
                                    `/admin/sub-session-list/${sessionId?.session_id}`
                                  );
                                }}
                              >
                                View Sub Sessions
                              </Index.Button>
                            </Index.MenuItem>
                          </Index.Menu>
                        </Index.Box> */}

                        <Index.Pagination
                          setCurrentRows={setCurrentRows}
                          allData={searchedData}
                          pageData={currentRows}
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default SessionTabList;
