import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  getAdminData,
  getAdminToken,
  getAdminType,
} from "../../../../redux/features/slices/Admin/AdminSlice";
import Index from "../../../Index";
import { getUserProfileData } from "../../../../redux/features/slices/User/UserService";

export default function FitrizerCard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState();

  const getSettingData = async () => {
    dispatch(getUserProfileData())
      .then((response) => {
        setProfileData(response?.payload?.data);
      })
      .catch((error) => {
        if (error.response.data.message === "Token not authorized") {
          dispatch(getAdminToken(""));
          dispatch(getAdminType(""));
          dispatch(getAdminData({}));
          navigate("/");
        }
      });
  };
  useEffect(() => {
    getSettingData();
  }, [getSettingData]);

  const wallethistorybtn = () => {
    navigate(`/user/wallet-history`);
  };

  return (
    <>
      <Index.Box className="card-dashbord-main">
        <Index.Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
          className="card-dash-grid"
          sx={{ margin: 0, alignItems: "center" }}
        >
          <Index.Box
            gridColumn={{
              xs: "span 12",
              sm: "span 12",
              md: "span 12",
              lg: "span 12",
            }}
            className="grid-column card-dash-column"
          >
            <Index.Box className="card-dash-main">
              <Index.Box className="card-dash-body">
                <Index.Box className="flex-details-card">
                  <Index.Box className="logo-bg-card">
                    <img
                      className="small-logo"
                      src={Index.Png.smalllogo}
                      alt=""
                    ></img>
                  </Index.Box>
                  <Index.Box className="details-card-main">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="card-dash-title"
                    >
                      Total Balance
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="card-dash-detail"
                    >
                      {profileData?.TotalTokens
                        ? profileData?.TotalTokens
                        : 0.0}{" "}
                      FTZR
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>

                <Index.Box className="flex-price-lock">
                  <Index.Box className="locked-card-main"></Index.Box>

                  <Index.Box className="wallet-btn-main">
                    <Index.OutlineButton
                      btnLabel="Wallet"
                      className="outline-btn-list"
                      onClick={wallethistorybtn}
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
