import React from "react";
import Index from "../../../../component/Index";
import PageIndex from "../../../PageIndex";
// import { getNutritionTabPlans } from "../../../../redux/features/slices/Admin/AdminService";

const ViewWorkoutPlan = () => {
  //   const [nutritionDetails, setnutritionDetails] = PageIndex.useState({});
  //   const [apiData, setApiData] = PageIndex.useState(false);
  // const { trainerId } = PageIndex.useParams();
  //   const dispatch = PageIndex.useDispatch();
  const navigate = PageIndex.useNavigate();
  const { state } = PageIndex.useLocation();
  const { trainerId, equipments, image, video } = state;
  console.log(equipments, "equip img video", image, video);

  //   const getSingleNutritionDetails = () => {
  //     const urlEncodedData = new URLSearchParams();
  //     urlEncodedData.append("id", planId);
  //     dispatch(getNutritionTabPlans(urlEncodedData)).then((res) => {
  //       const { status, data } = res?.payload || {};
  //       if (status === 200) {
  //         setnutritionDetails(data[0]);
  //         setApiData(true);
  //       } else {
  //         setApiData(true);
  //       }
  //     });
  //   };

  const goBack = () => {
    navigate(-1);
  };

  //   PageIndex.useEffect(() => {
  //     getSingleNutritionDetails();
  //   }, [planId]);

  if (!state) {
    return <Index.Loader />;
  }

  return (
    <Index.Box className="dashboard-containt-main">
      <Index.Box className="dashboard-content add-user-containt">
        <Index.Box className="title-list-search">
          <Index.Box className="user-head-title user-title-row">
            <Index.Typography
              className="admin-page-title admin-sub-titles"
              component="h2"
              variant="h2"
            >
              Workout Plan Details
            </Index.Typography>
          </Index.Box>

          <Index.Box className="save-btn-main singleNFt-back-btn-box">
            <Index.Button
              onClick={() => goBack()}
              className="save-user-btn primary-btn goal-btn"
              variant="contained"
            >
              <Index.KeyboardBackspaceIcon className="singleNFT-back-btn-icon" />
              Back
            </Index.Button>
          </Index.Box>
        </Index.Box>

        <Index.Box className="tabpanel-main">
          <Index.Box className="add-user-data-main goal-setup-css">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box className="single-group-container">
                <Index.Box className="siggle-group-flex">
                  <Index.Box className="single-group-leftbox">
                    <Index.Box className="signgle-data-main">
                      <Index.Box className="singlegroup-leftbox-groupdetail">
                        <Index.Grid container spacing={3}>
                          <Index.Grid item xs={12} md={12} lg={12} xl={12}>
                            <Index.Box className="contdetail-box">
                              <Index.Box className="contdetail-title">
                                <Index.Typography
                                  className="admin-page-title admin-sub-titles"
                                  component="h6"
                                  variant="h6"
                                >
                                  User Information
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="cont-detail cont-detail-video-img">
                                <ul className="cont-detail-list">
                                  <li>
                                    <h5 className="cont-detail-tag-name">Profile Image</h5>
                                    <p>:</p>
                                    <Index.Box className="cont-detail-profile">
                                      <img
                                        src={
                                          trainerId?.profileImg
                                            ? `${PageIndex.ImageURL}${trainerId?.profileImg}`
                                            : Index.Png.herobg
                                        }
                                        alt="loading..."
                                        className="user-post-user-img"
                                      />
                                    </Index.Box>
                                  </li>

                                  <li>
                                    <h5 className="cont-detail-tag-name">Username</h5>
                                    <p>:</p>
                                    <p className="cont-detail-tag-name-detail">
                                      {trainerId?.userName || "-"}
                                    </p>
                                  </li>

                                  <li>
                                    <h5 className="cont-detail-tag-name">Name</h5>
                                    <p>:</p>
                                    <p className="cont-detail-tag-name-detail">
                                      {trainerId?.fullName || "-"}
                                    </p>
                                  </li>

                                  <li>
                                    <h5 className="cont-detail-tag-name">Request Reason</h5>
                                    <p>:</p>
                                    <p className="cont-detail-tag-name-detail">
                                      {state?.requestReason || "-"}
                                    </p>
                                  </li>

                                  <li>
                                    <h5 className="cont-detail-tag-name">Equipments</h5>
                                    <p>:</p>
                                    <Index.Box>
                                      {equipments.map((item) => (
                                        <p className="cont-detail-tag-name-detail">
                                          {item || "-"}
                                        </p>
                                      ))}
                                    </Index.Box>
                                  </li>

                                  <li>
                                    <h5 className="cont-detail-tag-name">Amount</h5>
                                    <p>:</p>
                                    <p className="cont-detail-tag-name-detail">
                                      {state?.amount || "-"}
                                    </p>
                                  </li>

                                  <li>
                                    <h5 className="cont-detail-tag-name">Plan Name</h5>
                                    <p>:</p>
                                    <p className="cont-detail-tag-name-detail">
                                      {state?.planName}
                                    </p>
                                  </li>

                                  <li>
                                    <h5 className="cont-detail-tag-name">Category</h5>
                                    <p>:</p>
                                    <p className="cont-detail-tag-name-detail">
                                      {state?.category || "-"}
                                    </p>
                                  </li>

                                  <li>
                                    <h5 className="cont-detail-tag-name">Created Date</h5>
                                    <p>:</p>
                                    <p className="cont-detail-tag-name-detail">
                                      {state?.createdAt ? PageIndex.moment(state?.createdAt).format("MM/DD/YYYY") : "-"}
                                    </p>
                                  </li>

                                  <li>
                                    <h5 className="cont-detail-tag-name">Description</h5>
                                    <p>:</p>
                                    <p className="cont-detail-tag-name-detail">
                                      {state?.description || "-"}
                                    </p>
                                  </li>

                                  <li>
                                    <h5 className="cont-detail-tag-name">Duration</h5>
                                    <p>:</p>
                                    <p className="cont-detail-tag-name-detail">
                                      {state?.duration || "-"}
                                    </p>
                                  </li>

                                  <li>
                                    <h5 className="cont-detail-tag-name">Fitness Level</h5>
                                    <p>:</p>
                                    <p className="cont-detail-tag-name-detail">
                                      {state?.fitnessLevel || "-"}
                                    </p>
                                  </li>

                                  <li>
                                    <h5 className="cont-detail-tag-name">Frequency</h5>
                                    <p>:</p>
                                    <p className="cont-detail-tag-name-detail">
                                      {state?.frequency || "-"}
                                    </p>
                                  </li>

                                  <li>
                                    <h5 className="cont-detail-tag-name">Price</h5>
                                    <p>:</p>
                                    <p className="cont-detail-tag-name-detail">
                                      {state?.price || "-"}
                                    </p>
                                  </li>

                                  <li>
                                    <h5 className="cont-detail-tag-name">Workout Per Week</h5>
                                    <p>:</p>
                                    <p className="cont-detail-tag-name-detail">
                                      {state?.workoutPerWeek || "-"}
                                    </p>
                                  </li>

                                  <li>
                                    <h5 className="cont-detail-tag-name">Payment Status</h5>
                                    <p>:</p>
                                    <p className="cont-detail-tag-name-detail">
                                      {state?.paymentStatus || "-"}
                                    </p>
                                  </li>

                                </ul>
                                <ul className="cont-detail-list">
                                  <li>
                                    <h5 className="cont-detail-tag-name">Image</h5>
                                    <p>:</p>
                                    <Index.Box className="flex-box">
                                      {image.map((item) => (
                                        <Index.Box className="cont-multiple-img">
                                          <img
                                            src={
                                              state?.image
                                                ? `${PageIndex.ImageURL}${state?.image}`
                                                : Index.Png.herobg
                                            }
                                            alt="loading..."
                                            className="user-post-user-img"
                                          />
                                        </Index.Box>
                                      ))}
                                    </Index.Box>
                                  </li>

                                  <li>
                                    <h5 className="cont-detail-tag-name">Video</h5>
                                    <p>:</p>
                                    <Index.Box className="flex-box">
                                      {image.map((item) => (
                                        <Index.Box className="cont-multiple-video">
                                          <video width="100%" height="100" controls>
                                            <source src={PageIndex.ImageURL + video?.video} type="video/mp4" />
                                          </video>
                                        </Index.Box>
                                      ))}

                                    </Index.Box>
                                  </li>

                                </ul>
                              </Index.Box>
                            </Index.Box>
                          </Index.Grid>
                        </Index.Grid>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>

                {/* <Index.Box className="siggle-group-flex">
                  <video width="100%" height="360" controls>
                    <source src={PageIndex.ImageURL + nutritionDetails?.video} type="video/mp4" />
                  </video>
                </Index.Box> */}
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
};

export default ViewWorkoutPlan;
